import React from "react";
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { extractDomain } from "./helpers";

export default function NotFoundPage() {
	const { isLoaded: isUserLoaded, isSignedIn, user } = useUser();
	const navigate = useNavigate();

	if (!isUserLoaded) {
		return (
			<div className="flex text-center items-center justify-center">
				<div className="loading-spinner"></div>
			</div>
		);
	}

	return (
		<>
			<main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
				<div className="text-center">
					<p className="text-base font-semibold text-indigo-600">404</p>
					<h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
					<p className="mt-10 text-base leading-7 text-gray-600">Sorry, we couldn't find the page you're looking for.</p>
					{isSignedIn ? (
						<div className="mt-1">
							<p className="text-md font-semibold text-gray-900">
								<br/> You don't have access to this page. <br/><br/> Would you like to go back to your{" "}
								<button onClick = {() => navigate(`/?website=${extractDomain(user.primaryEmailAddress.emailAddress)}`)} className="text-indigo-600 hover:underline hover:text-indigo-500">
									report?
								</button>
							</p>
						</div>
					) : (
						<div className="my-2">
							<p className="text-md font-semibold">
								You may be required to{" "}
								<a href="/sign-in" className="text-indigo-600 hover:underline hover:text-indigo-500">
									sign-in
								</a>{" "}
								or{" "}
								<a href="/sign-up" className="text-indigo-600 hover:underline hover:text-indigo-500">
									sign-up
								</a>{" "}
								to access this page.
							</p>
						</div>
					)}
				</div>
			</main>
		</>
	);
}
