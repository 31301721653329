import React, { useState } from 'react';

const LinkedInExportModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded shadow-lg w-11/12 md:w-2/3 lg:w-1/2">
        <h2 className="text-2xl font-bold mb-4">Export Your LinkedIn Connections</h2>
        <ol className="list-decimal list-inside space-y-2">
          <li><strong>Log in to LinkedIn:</strong>
            <ul className="list-disc list-inside ml-5">
              <li>Open LinkedIn and log in to your account.</li>
            </ul>
          </li>
          <li><strong>Go to Settings & Privacy:</strong>
            <ul className="list-disc list-inside ml-5">
              <li>Click on your profile picture in the top right corner of the LinkedIn homepage.</li>
              <li>Select “Settings & Privacy” from the dropdown menu.</li>
            </ul>
          </li>
          <li><strong>Access Data Privacy:</strong>
            <ul className="list-disc list-inside ml-5">
              <li>In the “Settings & Privacy” page, click on the “Data Privacy” tab.</li>
            </ul>
          </li>
          <li><strong>Get a Copy of Your Data:</strong>
            <ul className="list-disc list-inside ml-5">
              <li>Scroll down to the section titled “How LinkedIn uses your data.”</li>
              <li>Click on “Get a copy of your data.”</li>
            </ul>
          </li>
          <li><strong>Select Connections:</strong>
            <ul className="list-disc list-inside ml-5">
              <li>On the “Get a copy of your data” page, select “Connections.”</li>
              <li>You can choose to export other types of data as well, but for connections, make sure the “Connections” box is checked.</li>
            </ul>
          </li>
          <li><strong>Request Archive:</strong>
            <ul className="list-disc list-inside ml-5">
              <li>Click the “Request archive” button.</li>
              <li>You may be prompted to enter your LinkedIn password to verify your identity.</li>
            </ul>
          </li>
          <li><strong>Wait for Email Notification:</strong>
            <ul className="list-disc list-inside ml-5">
              <li>LinkedIn will prepare your data, which may take a few minutes to a few hours.</li>
              <li>You will receive an email notification once your data is ready for download.</li>
            </ul>
          </li>
          <li><strong>Download Your Data:</strong>
            <ul className="list-disc list-inside ml-5">
              <li>Follow the link in the email or go back to the “Settings & Privacy” page to download your data.</li>
              <li>The downloaded file will be in CSV format, which can be opened with spreadsheet software like Microsoft Excel or Google Sheets.</li>
            </ul>
          </li>
        </ol>
        <div className="mt-4 flex justify-end">
          <button
            onClick={onClose}
            className="bg-gray-900 text-white px-4 py-2 rounded hover:bg-gray-800 shadow-lg"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default LinkedInExportModal;
