import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import { extractDomain } from "./helpers";

function Reroute() {
	const navigate = useNavigate();
	const { isSignedIn, user, isLoaded: isUserLoaded } = useUser();
	
	useEffect(() => {
		if (!isUserLoaded) return;

		if (isSignedIn) {
			const userDomain = extractDomain(user.primaryEmailAddress.emailAddress);
			const reportURL = `/?website=${userDomain}`;
			navigate(reportURL);
		} else {
			navigate("/sign-up");
		}
        
	}, [isSignedIn, user, isUserLoaded]);

	return (
		<div className="grid grid-rows-7 grid-cols-9 grid-flow-col h-screen w-auto">
			<div className="col-start-5 row-start-4 col-span-1 row-span-1">
				<div className="loading-spinner"></div>
			</div>
		</div>
	);
}

export default Reroute;
