import { React, Fragment, useState, useEffect, useRef, useContext, Listbox, Label, ListboxButton, ListboxOption, ListboxOptions } from "react";
import { Menu } from "@headlessui/react";
import { Switch } from '@headlessui/react'
import { FunnelIcon, TrashIcon, XMarkIcon, ChevronUpDownIcon, CheckIcon, ChevronDownIcon, ChatBubbleOvalLeftIcon, PhoneArrowUpRightIcon, LinkIcon, CloudArrowUpIcon } from "@heroicons/react/20/solid";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon, ClipboardDocumentListIcon, InformationCircleIcon, PencilIcon } from "@heroicons/react/24/outline";
import { currencyFormat, classNames, extractDomain } from "./helpers.js";
import Airtable from "airtable";
import Cookies from "js-cookie";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Select from 'react-select'
import DataUploader from "./DataUploader.js";
import LinkedInExportModal from './LinkedInExportModal';
import DataImportModal from './DataImportModal';
import ClipboardModal from './ClipboardModal';
import { setShowNotif, setContents, shortenText } from "./helpers";
import { CopyClipboard } from "flowbite";
import Notification from "./Notification.js";
import { AppContext } from "./AppContext.js";
import ReferredModal from "./ReferredModal.js";
import { ClipLoader } from 'react-spinners';
import { set } from "lodash";
import heroImage from './assets/upgrade-your-network-newsroom-hero-1120x630.jpg';
import {
    DocumentDuplicateIcon,
    ArrowDownTrayIcon,
    ArrowUpTrayIcon,
    GiftIcon,
    EnvelopeIcon,
} from "@heroicons/react/24/outline";
import {
    FacebookIcon,
    LinkedInIcon,
    TwitterIcon,
    InstagramIcon,
    WhatsAppIcon,
} from "@heroicons/react/24/solid";
import {
    FaFacebook,
    FaLinkedin,
    FaTwitter,
    FaInstagram,
    FaWhatsapp
} from "react-icons/fa";
import StatusComponent from "./components/StatusComponent.js";
import './InviteForm.css';
import { isInaccessible } from "@testing-library/react";
import { useUser } from '@clerk/clerk-react';
import InviteModal from "./components/InviteModal.js";
import { subset } from "mathjs";
import { Subject } from "@mui/icons-material";
import InviteReferralPreview from "./assets/invite-referral-preview.jpg";
import { log } from "logrocket";

const companiesTableId = process.env.REACT_APP_AIR_COMPANIES;
const GROWSURF_BASE_URL = 'https://api.growsurf.com/v2';
const GROWSURF_CAMPAIGN_ID = process.env.REACT_APP_GROWSURF_CAMPAIGN_ID;
const GROWSURF_API_KEY = process.env.REACT_APP_GROWSURF_APIKEY;

const people1 = [
    {
        id: 13,
        value: 'Daniel Schloss',
        label: 'Daniel Schloss',
        avatar:
            'https://media.licdn.com/dms/image/D4E03AQEPy6SB_StC4Q/profile-displayphoto-shrink_800_800/0/1674154777990?e=1723075200&v=beta&t=s1W7kWKqZMIwPvutn8moZ_hDEEv-tEKzbxKQ-_8xDsA',
    },
    {
        id: 14,
        value: 'Haris Raheel',
        label: 'Haris Raheel',
        avatar:
            'https://i.imgur.com/AvV2FSX.jpeg',
    },
    {
        id: 15,
        value: 'Alex Feldman',
        label: 'Alex Feldman',
        avatar:
            'https://media.licdn.com/dms/image/D4E03AQGLaxwC2j2UQQ/profile-displayphoto-shrink_800_800/0/1715391185719?e=1723075200&v=beta&t=2wNH5DSV99_xx8KMLgKvTUNVyQODx7n-uCESBPJUyjg',
    },
    {
        id: 2,
        value: 'Shaq Rahman',
        label: 'Shaq Rahman',
        avatar:
            'https://media.licdn.com/dms/image/D5603AQHsdwMw9zuotw/profile-displayphoto-shrink_200_200/0/1682904544984?e=1723680000&v=beta&t=06TiRqY6tB0w4ZOq2AAeB1OUcPzdESEkcftl30tbPTc',
    },
    {
        id: 2,
        value: 'Yu Hei Chan',
        label: 'Yu Hei Chan',
        avatar:
            'https://media.licdn.com/dms/image/D4D03AQFSJktyOvK6ow/profile-displayphoto-shrink_200_200/0/1683179647984?e=1723680000&v=beta&t=ewI__-s4tUMIQMVsPxcHqLKXFjD2WRjAigl99zBmY0g',
    },
    {
        id: 1,
        value: 'Sarah Schumacher',
        label: 'Sarah Schumacher',
        avatar:
            'https://media.licdn.com/dms/image/D5603AQG02L5jUZc7mg/profile-displayphoto-shrink_800_800/0/1689863043738?e=1723075200&v=beta&t=4dzUqxI93pOYbmhjc02KfN7bpomj4EOwRsTqOTuz6JU',
    },
    {
        id: 2,
        value: 'Kerem Tirmandi',
        label: 'Kerem Tirmandi',
        avatar:
            'https://media.licdn.com/dms/image/D5603AQGWcnTn0TvsJg/profile-displayphoto-shrink_400_400/0/1692826073695?e=1723075200&v=beta&t=4NDGLb-bieo6O1Xvyz5INzkpQYuOQz0-RaqVxIhfLvU',
    },
    {
        id: 3,
        value: 'Romain Pecard',
        label: 'Romain Pecard',
        avatar:
            'https://media.licdn.com/dms/image/C5603AQE7GYURJ6YFCA/profile-displayphoto-shrink_800_800/0/1654653657454?e=1723075200&v=beta&t=Ur6-JRG848ctUmT4gEMYrocjiGZDxHfh-wbc-4Yc_5Y',
    },
    {
        id: 4,
        value: 'Michael Williams',
        label: 'Michael Williams',
        avatar:
            'https://media.licdn.com/dms/image/C5603AQGI0z2ea28WiQ/profile-displayphoto-shrink_400_400/0/1516506635589?e=1723075200&v=beta&t=rafO3KSlP8VlnP2k6cMFewhxq1FgTEeY3QEdvELYGCc',
    },
    {
        id: 5,
        value: 'Kyle Williamson-Fox',
        label: 'Kyle Williamson-Fox',
        avatar:
            'https://i.imgur.com/UaXCcqm.jpeg',
    },
    {
        id: 6,
        value: 'Vedran Rasidajic',
        label: 'Vedran Rasidajic',
        avatar:
            'https://media.licdn.com/dms/image/C5603AQH0L-pP0m5O2Q/profile-displayphoto-shrink_800_800/0/1630359744660?e=1723075200&v=beta&t=tOdz9QQBprt8A-IFV5PE80SdhQcK92LxtzRS2j88eHg',
    },
    {
        id: 7,
        value: 'Derek Campbell',
        label: 'Derek Campbell',
        avatar:
            'https://i.imgur.com/9KZxKAc.jpeg',
    },
    {
        id: 8,
        value: 'Shimona Slomowitz',
        label: 'Shimona Slomowitz',
        avatar:
            'https://media.licdn.com/dms/image/C5603AQFT-5EeSk_qOw/profile-displayphoto-shrink_800_800/0/1631632351260?e=1723075200&v=beta&t=pazF0JiQZ4J6t-huDijyNpcZrFacTQZfC3lZrfoJ3Sg',
    },
    {
        id: 9,
        value: 'Colby Richardson',
        label: 'Colby Richardson',
        avatar:
            'https://media.licdn.com/dms/image/D4D03AQH0rhR8iSyfLg/profile-displayphoto-shrink_800_800/0/1683094782091?e=1723075200&v=beta&t=cCzZyodn4QZosHeXYkZ--FUOsZTyqIxwV57I8-Zv93Q',
    },
    {
        id: 10,
        value: 'Houtan Sanandaji',
        label: 'Houtan Sanandaji',
        avatar:
            'https://media.licdn.com/dms/image/D5603AQF7mGytCxjdQQ/profile-displayphoto-shrink_800_800/0/1696808259613?e=1723075200&v=beta&t=nn9JLvM1WJFVPBOqjCUn_Z7K8BMK85Jl6GGwRHs45xg',
    },
    {
        id: 11,
        value: 'Jameson Zimmer',
        label: 'Jameson Zimmer',
        avatar:
            'https://media.licdn.com/dms/image/C5603AQHcYGmOefJ-wg/profile-displayphoto-shrink_800_800/0/1582682922429?e=1723075200&v=beta&t=yFIMFb4l8UOkQ_Gt_x8nntYEmS8y46uVJfLhJcd-eiY',
    },
    {
        id: 12,
        value: 'Matthew Parson',
        label: 'Matthew Parson',
        avatar:
            'https://i.imgur.com/B0d16ts.jpeg',
    }
]

function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
        </svg>
    );
}

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const clayApiKey = "80bf797298733a979683";
const base = new Airtable({ apiKey }).base(baseId);
const peopleTable = "tbl3evf4TogmErSt6"

export default function MyNetwork({ }) {
    const { isLoaded, isSignedIn, user } = useUser();
    const {
        companies,
        setCompanies,
        grantLoading,
        setGrantLoading,
        userRecord,
        setUserRecord
    } = useContext(AppContext);

    const [notifications, setNotifications] = useState([]);
    const [copyClipboardNotif, setCopyClipboardNotif] = useState({
        showNotif: false,
        contents: { type: "", heading: "", message: "" },
    });
    const [referralInfo, setReferralInfo] = useState({
        referred: 0,
        referrable: 0,
        unreferrable: 0
    });
    const [referralList, setReferralList] = useState([]);
    const [displayedPeople, setDisplayedPeople] = useState([]);
    const [allPeople, setAllPeople] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const ITEMS_PER_PAGE = 20;
    const loginEmail = user?.primaryEmailAddress?.emailAddress || 'will@getupgraded.ca';
    const [userData, setUserData] = useState({});

    const [selected, setSelected] = useState(people1[3])
    const [people, setPeople] = useState([]);
    const cancelButtonRef = useRef(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [numberValue, setNumberValue] = useState(231);
    const [enabled, setEnabled] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isClipModalOpen, setIsClipModalOpen] = useState(false);
    const [isReferredModalOpen, setIsReferredModalOpen] = useState(false);
    const [isDataModalOpen, setIsDataModalOpen] = useState(false);
    const [copySuccess, setCopySuccess] = useState('');
    const [newPerson, setnewPerson] = useState('');
    const [referralCount, setReferralCount] = useState(0);
    const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);
    const [referralInput, setLinkedInUrl] = useState('');
    const [urlError, setUrlError] = useState('');
    const [isInvitingAll, setIsInvitingAll] = useState(false);
    const observerTarget = useRef(null);
    const [invitePermissions, setInvitePermissions] = useState({});
    const [isInviteVisible, setIsInviteVisible] = useState(false);
    const [isInviteAllVisible, setIsInviteAllVisible] = useState(false);
    const [isInviteOneVisible, setIsInviteOneVisible] = useState(false);
    const [inviteOneContact, setInviteOneContact] = useState();
    const [isEditInviteVisible, setIsEditInviteVisible] = useState(false);
    const [emailSubject, setEmailSubject] = useState();
    const [emailBody, setEmailBody] = useState();
    const [tempEmailSubject, setTempEmailSubject] = useState("");
    const [tempEmailBody, setTempEmailBody] = useState("");
    const [editInviteError, setEditInviteError] = useState("");
    const [personId, setPersonId] = useState("");
    const [showInfoBox, setShowInfoBox] = useState(false);
    const [showReferralGuideBox, setShowReferralGuideBox] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editingPerson, setEditingPerson] = useState(null);
    const [prevEditingPersonEmail, setPrevEditingPersonEmail] = useState("");
    const [isInvited, setIsInvited] = useState(false);
    const [selectedPerson, setSelectedPerson] = useState(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [isPlainText, setIsPlainText] = useState(true);
    const [copiedLink, setCopiedLink] = useState(false);
    const [useAlternateIdentity, setUseAlternateIdentity] = useState(false);
    const [refreshReferrals, setRefreshReferrals] = useState(false);
    // Add this state for tooltip visibility
    const [tooltipVisible, setTooltipVisible] = useState({});
    // Add this state to store reasons why invites are disabled
    const [inviteDisabledReasons, setInviteDisabledReasons] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    // Add state for template management
    const [currentTemplateIndex, setCurrentTemplateIndex] = useState(0);
    const [templates, setTemplates] = useState([
        {
            name: "Insightful",
            subject: "What are you doing for grants and government programs this year?",
            body: "Hi {{FirstName}},\n\nWhat are you doing for grants and government programs this year?\n\nI came across a platform that helps Canadian companies maximize their grant funding on >1,500 Canadian programs most might not know about, beyond the usual programs like IRAP, SR&ED, CanExport.\n\nThey build out personalized grants & savings reports to help you identify the best opportunities https://app.getupgraded.ca/?website={{domain}}"
        },
        {
            name: "Casual",
            subject: "Save thousands on your business with this grant platform",
            body: "Hey {{FirstName}},\n\nI wanted to share something that's been really valuable for my business. Upgraded is a platform that connects Canadian companies with government grants and funding programs - they have access to over 1,500 programs!\n\nThey helped me identify several programs I was eligible for that I had no idea existed. If you sign up through my link, we both get benefits: {{ReferralLink}}\n\nLet me know if you check it out!\n\nBest,\n{{YourName}}"
        },
        {
            name: "Thoughtful",
            subject: "Found a way to reduce our business costs - thought you'd be interested",
            body: "Hi {{FirstName}},\n\nHope you're doing well! I recently discovered Upgraded, a platform that helps Canadian businesses find and apply for government grants and tax credits.\n\nThey have a database of over 1,500 programs and create personalized reports showing exactly which ones you qualify for. It's been eye-opening to see how much funding is available beyond the well-known programs.\n\nI thought this might be relevant for {{Company}} too: {{ReferralLink}}\n\nWorth checking out!\n\nCheers,\n{{YourName}}"
        },
        {
            name: "Results-Driven",
            subject: "This tool saved us $57K in grant funding - might help you too",
            body: "{{FirstName}},\n\nQuick note to share something that's been a game-changer for our business finances.\n\nUpgraded.ca helped us identify and secure over $57,000 in government funding that we were eligible for but didn't know about. Their platform scans through 1,500+ Canadian grant and tax credit programs to find matches for your specific business.\n\nThey offer a guaranteed minimum return for their managed service clients, and if you use my link, we both benefit: {{ReferralLink}}\n\nThought this might be valuable for you too.\n\nRegards,\n{{YourName}}"
        },
        {
            name: "Friendly",
            subject: "Join Upgraded and we'll both get $1,000 :)",
            body: "Hey {{FirstName}}! Hope you're off to a good week, curious, but what are you doing in terms of grants and tax credits outside SR&ED and IRAP? I came across this interesting platform to create personalized grant reports for my network that helps you see which of the over 1,500 programs in Canada you qualify for - app.getupgraded.ca\n\nUse my invite link to sign up and get 5% off their plans. If we both sign up for the managed service with a $57,000/yr guarantee, we'll each get $1,000!\n\nSign up here: {{ReferralLink}}\n\nBest,\n{{YourName}}"
        }
    ]);

    // Add function to cycle through templates
    const cycleTemplate = (direction) => {
        let newIndex;
        if (direction === 'next') {
            newIndex = (currentTemplateIndex + 1) % templates.length;
        } else {
            newIndex = (currentTemplateIndex - 1 + templates.length) % templates.length;
        }
        setCurrentTemplateIndex(newIndex);
        setTempEmailSubject(templates[newIndex].subject);
        setTempEmailBody(templates[newIndex].body);
    };

    // Add function to save current template
    const saveCurrentTemplate = () => {
        const updatedTemplates = [...templates];
        updatedTemplates[currentTemplateIndex] = {
            subject: tempEmailSubject,
            body: tempEmailBody
        };
        setTemplates(updatedTemplates);
        // Show a success message or toast notification
        alert("Template saved successfully!");
    };

    const handleSave = () => {
        if (tempEmailBody.trim() === "" && tempEmailSubject.trim() === "") {
            setEditInviteError('Please enter an email subject and message.');
            return;
        } else if (tempEmailSubject.trim() === "") {
            setEditInviteError('Please enter an email subject.');
            return;
        } else if (tempEmailBody.trim() === "") {
            setEditInviteError('Please enter an email message.');
            return;
        } else if (tempEmailBody.length > 600) {
            setEditInviteError('Your message is too long.');
            return;
        }

        setEditInviteError('');

        setEmailSubject(tempEmailSubject);
        setEmailBody(tempEmailBody);
        console.log("Email Subject:", emailSubject);
        console.log("Email Body:", emailBody);
        console.log("is plain text", isPlainText);
        updateInviteMessage()
        setIsEditInviteVisible(false); // Close the modal after saving
    };

    let growsurfEmail = '';
    let addThroughImportNetwork = false;

    function updateInviteMessage() {
        fetch(`https://api.airtable.com/v0/${baseId}/users?filterByFormula=${encodeURIComponent(`{Email}="${loginEmail}"`)}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${apiKey}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(findData => {
                // Check if a record was found
                if (!findData.records || findData.records.length === 0) {
                    throw new Error("No matching user found.");
                }

                // Get the first matching record's ID
                const recordId = findData.records[0].id;

                // Step 2: Update the record
                return fetch(`https://api.airtable.com/v0/${baseId}/users/${recordId}`, {
                    method: 'PATCH',
                    headers: {
                        'Authorization': `Bearer ${apiKey}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        fields: {
                            EmailInviteSubject: tempEmailSubject,
                            EmailInviteMessage: tempEmailBody,
                            plainOrHtml:  (isPlainText ? "true" : null),
                        }
                    })
                });
            })
            .then(updateResponse => updateResponse.json())
            .then(updateData => {
                setShowNotif(true, setCopyClipboardNotif);
                setContents({
                    type: "success",
                    heading: "Success",
                    message: "Invite message saved!"
                }, setCopyClipboardNotif);

                console.log("Record updated:", updateData);
                setTempEmailSubject("");
                setTempEmailBody("");
            })
            .catch(error => {
                setShowNotif(true, setCopyClipboardNotif);
                setContents({
                    type: "error",
                    heading: "Error",
                    message: "Error while saving invite message."
                }, setCopyClipboardNotif);

                console.error("Error:", error);
                setTempEmailSubject("");
                setTempEmailBody("");
            });
    }

    useEffect(() => {
        // Only fetch data if user is authenticated
        if (!isLoaded || !isSignedIn || !user) return;

        if (loginEmail) {
            // Fetch referrals data
            base("Referrals")
                .select({
                    filterByFormula: `{UploadedBy} = '${loginEmail}'`,
                    maxRecords: 500,
                    sort: [{ field: "Last Modified", direction: "desc" }]
                })
                .all()
                .then((records) => {
                    setAllPeople(records);
                    setPeople(records); // Set the people state with the fetched records
                    setNumberValue(records.length);
                    setDisplayedPeople(records.slice(0, ITEMS_PER_PAGE));
                    setHasMore(records.length > ITEMS_PER_PAGE);

                    // Calculate referral info once we have the records
                    if (companies.length > 0) {
                        const referrableArray = [];
                        const updatedReferralInfo = records.reduce((acc, person) => {
                            const hasInfo = !!person.fields.Website && !!person.fields.Email && !!person.fields.Company;
                            const isReferred = companies.some(company =>
                                company.fields.Domain === person.fields.Website &&
                                company.fields.reportingEmails
                            );

                            if (isReferred) {
                                acc.referred += 1;
                            } else if (hasInfo) {
                                acc.referrable += 1;
                                referrableArray.push({
                                    domain: person.fields.Website,
                                    email: person.fields.Email,
                                    company: person.fields.Company
                                });
                            } else {
                                acc.unreferrable += 1;
                            }
                            return acc;
                        }, {
                            referred: 0,
                            referrable: 0,
                            unreferrable: 0
                        });

                        setReferralInfo(updatedReferralInfo);
                        setReferralList(referrableArray);
                    }

                    // Log the data to verify it's being fetched correctly
                    console.log("Fetched records:", records);
                })
                .catch(error => {
                    console.error("Error loading records: ", error);
                    setShowNotif(true, setCopyClipboardNotif);
                    setContents({
                        type: "error",
                        heading: "Error",
                        message: "Failed to load your network data. Please try again."
                    }, setCopyClipboardNotif);
                });

            // Fetch user data
            base('users')
                .select({
                    filterByFormula: `{Email} = '${loginEmail}'`
                })
                .firstPage()
                .then(records => {
                    if (records.length > 0) {
                        setUserData(records[0].fields);
                        setUserRecord(records[0]);
                    }
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                });

            // Fetch companies if not already loaded
            if (!companies.length) {
                setGrantLoading(true);
                base('Companies')
                    .select({
                        maxRecords: 1000,
                        view: "Grid view"
                    })
                    .all()
                    .then(records => {
                        setCompanies(records);
                        setGrantLoading(false);
                    })
                    .catch(error => {
                        console.error('Error fetching companies:', error);
                        setGrantLoading(false);
                    });
            }
        }
    }, [isLoaded, isSignedIn, user, loginEmail, companies, refreshReferrals]);

    // Add infinite scroll effect
    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting && hasMore) {
                    const nextItems = allPeople.slice(
                        displayedPeople.length,
                        displayedPeople.length + ITEMS_PER_PAGE
                    );
                    setDisplayedPeople(prev => [...prev, ...nextItems]);
                    setHasMore(displayedPeople.length + nextItems.length < allPeople.length);
                }
            },
            { threshold: 1.0 }
        );

        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }

        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current);
            }
        };
    }, [displayedPeople, hasMore, allPeople]);

    const handleCopy = (person) => {
        console.log(person, "person")

        const textToCopy = `Hi ${person.fields.FirstName}, I'd like to share a way to find money you didn't know about before, it's called Upgraded. They work entirely on a contingency basis. They've done over $9.4m in grants, vendor savings and automations at an ~84% success rate and your report shows you have over $105,231/yr in savings or $8,769/mo. Kindly see this link to your report below, would love https://app.getupgraded.ca/?website=${person.fields.Website}`;
        navigator.clipboard.writeText(textToCopy).then(
            () => {
                setCopySuccess('Copied to clipboard!');
            },
            (err) => {
                setCopySuccess('Failed to copy text.');
            }
        );

        // console.log(setCopyClipboardNotif)
        setShowNotif(true, setCopyClipboardNotif);
        const contents = { type: "success", heading: "Copied to clipboard!", message: "You're armed with the right thing to say, spread the love." }
        console.log(contents)
        setContents(contents, setCopyClipboardNotif);
        // console.log(copyClipboardNotif, "2")
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleOpenDataModal = () => {
        console.log("true")
        setIsDataModalOpen(true);
    };

    const handleCloseDataModal = () => {
        setIsDataModalOpen(false);
    };

    const handleOpenClipModal = () => {
        setIsAddContactModalOpen(true);
    };

    const handleCloseClipModal = () => {
        setIsClipModalOpen(false);
    };

    const handleOpenReferredModal = () => {
        console.log("true")
        setIsReferredModalOpen(true);
    };

    const handleCloseReferredModal = () => {
        setIsReferredModalOpen(false);
    };

    async function canInvite(person, userData) {
        // First check if email exists
        if (!person.fields.Email) {
            return { canInvite: false, reason: "Email address is required to send an invite" };
        }

        try {
            const response = await fetch(
                `https://mycorsproxy-tuto-35940f676099.herokuapp.com/${GROWSURF_BASE_URL}/campaign/${GROWSURF_CAMPAIGN_ID}/participant/${person.fields.Email}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${GROWSURF_API_KEY}`,
                        'Content-Type': 'application/json',
                    }
                }
            );

            const data = await response.json();

            if (data?.referrer?.email && data?.referrer?.email !== userData?.Email) {
                return { canInvite: undefined, reason: "Can't invite - they are already a part of another referral program" };
            } else if (data?.referralStatus === 'CREDIT_PENDING' ||
                data?.referralStatus === 'CREDIT_AWARDED' ||
                data?.referralStatus === 'CREDIT_EXPIRED') {
                return { canInvite: false, reason: "Already invited - click to resend invite" };
            } else {
                return { canInvite: true, reason: "" };
            }
        } catch (error) {
            console.log("ERROR", error);
            // Default to allowing invites if there's an error checking
            return { canInvite: true, reason: "" };
        }
    }

    const statuses = { Completed: 'text-green-400 bg-green-400/10', Error: 'text-rose-400 bg-rose-400/10' }

    useEffect(() => {
        // Only run the effect if userEmail is loaded
        if (userData.Email) {
            base("Referrals")
                .select({
                    filterByFormula: `{UploadedBy} = '${userData.Email}'`,
                    maxRecords: 500,
                    sort: [{ field: "Last Modified", direction: "desc" }]
                })
                .all()
                .then((records) => {
                    setAllPeople(records);
                    setNumberValue(records.length);
                    // Initially load only first page
                    setDisplayedPeople(records.slice(0, ITEMS_PER_PAGE));
                    setHasMore(records.length > ITEMS_PER_PAGE);
                })
                .catch(error => {
                    console.error("Error loading records: ", error);
                });
        }
    }, [userData.Email]);

    useEffect(() => {
        if(!userData.ReplyToEmail) {
            setUseAlternateIdentity(true);
        } else {
            setUseAlternateIdentity(false);
        }
    }, [userData.ReplyToEmail]);

    useEffect(() => {
        try {
            fetch(`https://api.airtable.com/v0/${baseId}/users?filterByFormula=${encodeURIComponent(`{Email}="${loginEmail}"`)}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${apiKey}`,
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(findData => {
                // Check if a record was found
                if (!findData.records || findData.records.length === 0) {
                    throw new Error("No matching user found.");
                }

                // Get the first matching record's ID
                const recordId = findData.records[0].id;

                // Step 2: Update the record
                return fetch(`https://api.airtable.com/v0/${baseId}/users/${recordId}`, {
                    method: 'PATCH',
                    headers: {
                        'Authorization': `Bearer ${apiKey}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        fields: {
                            isAnonInviteMode: useAlternateIdentity
                        }
                    })
                });
            });
        } catch (error) {
            console.error("Error: ", error);
        }
    }, [useAlternateIdentity]);

    // Add intersection observer for infinite scroll
    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting && hasMore) {
                    // Load more items when bottom is reached
                    const nextItems = allPeople.slice(
                        displayedPeople.length,
                        displayedPeople.length + ITEMS_PER_PAGE
                    );
                    setDisplayedPeople(prev => [...prev, ...nextItems]);
                    setHasMore(displayedPeople.length + nextItems.length < allPeople.length);
                }
            },
            { threshold: 1.0 }
        );

        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }

        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current);
            }
        };
    }, [displayedPeople, hasMore, allPeople]);

    const extractDomainFromEmail = (emailText) => {
        if (!emailText) {
            return "no email";
        }
        const emailRegex = /@([^\s@]+)/;
        const match = emailText.match(emailRegex);
        return match ? match[1] : "no email";
    };

    const handleSelectChange = (selected) => {
        console.log("selected", selected)
        setSelectedOption(selected);
        if (selected) {
            setNumberValue(Math.random() * 300 + 100);
        } else {
            setNumberValue(231); // Default value if no option is selected
        }
    };

    const handleReferralProcess = () => {
        // Take the people object (all referrals) and filter out 2 that haven't been enriched yet
        let unEnrichedPeople = [];
        // Enriched does not equal "Yes"
        unEnrichedPeople = people.filter((person) => person.fields.Enriched != "Yes").slice(0, 20);

        unEnrichedPeople.forEach((person) => {
            console.log(person)
            enrichConnection(person);
        })

        // TODO: ADD THE REFERAL PARAM TO EACH COMPANY FROM THE USER OBJECT
        // 1. Get the user object
        // 2. Get the referral param
        // 3. Add the referral param to each company below
        // For each, run enrich connection for each person

        referralList.forEach((referral) => {
            // Creating a new empty record for JIT companies.
            // This record contains the Domain of the Company, as well as some default values for different fields.
            fetch('https://api.airtable.com/v0/' + baseId + '/' + companiesTableId, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${apiKey}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    fields: {
                        Name: referral.company,
                        Domain: referral.domain,
                        Industry: 'Technology',
                        Technologies: 'N/A',
                        RequestEmail: referral.email,
                        ProvinceOrState: 'ON',
                        Address: 'Toronto, Ontario',
                        Employees: 10,
                        Country: 'CA',
                        Founded: 2000,
                        AnnualRevenue: 1000000,
                        AvgDealSize: 10000,
                        ConversionRate: 2,
                        Debt: 100000,
                        AnnualTraffic: 10000,
                        OperationalCosts: 500000,
                        ListSize: 10000,
                        InterestedIn:
                            'CanExport SME,DS4Y,Student Work Placement Program,MITACS Business Strategy Internship,Canada Job Grant (CJG),Canada Small Business Financing Program (CSBFP)',
                        Activity: JSON.stringify({
                            Grants: [],
                            'Tech Discounts': [],
                            Automations: {
                                excludePeople: [],
                                personMap: {},
                            },
                            LatestActivity: 'n/a',
                        }),
                        'subscribed?': 'false',
                        blurMe: 'true',
                        reportingEmails: 'true',
                        sendFrequency: 7,
                        // bccEmails: 'will@getupgraded.ca',
                        fromName: 'Will Richman',
                        People1Name: 'there',
                        lastSent: '04/25/2024',
                        // ReferralParam: "?grsf=shane-yk88ra"
                        ReferralParam: userData.ReferralParam
                    },
                }),
            })
                .then((response) => response.json())
                .then((data) => console.log("Created 'Empty' Record in database", data))
                .catch((error) => console.error('Error:', error));


            try {
                fetch(
                    `https://mycorsproxy-tuto-35940f676099.herokuapp.com/https://api.clay.com/v3/sources/webhook/${process.env.REACT_APP_CLAY_ENRICH}`,
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${clayApiKey}`,
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Headers':
                                'Origin, X-Requested-With, Content-Type, Accept',
                        },
                        body: JSON.stringify({
                            email: 'williamrichman@gmail.com',
                            domain: referral.domain,
                        }),
                    }
                )
                    .then((response) => {
                        console.log('api call successful');
                    })
                    .catch((err) => {
                        console.log('this was the problem', err);
                    });
                // console.log(`❗Finished fetching for ${website} ❗`);
            } catch (error) {
                console.error('Error in Fetching JIT: ', error);
            }

            // Update referral count using functional setState
            setReferralCount((prevCount) => prevCount + 1);
        })

    };

    // function that adds linkedin and other info to person object
    const enrichConnection = async () => {
        // Add null check for userData and ReferralParam
        const firstName = user?.firstName || 'Unknown';
            const lastName = user?.lastName || 'User';
            const fullName = `${firstName} ${lastName}`.trim();

            fetch("https://api.airtable.com/v0/" + baseId + "/" + peopleTable, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${apiKey}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    fields: {
                        PersonalLinkedIn: referralInput,
                        UploadedBy: loginEmail,
                        UploadedByFirstName: firstName,
                        UploadedByLastName: lastName,
                        UploadedByFullName: fullName,
                        ReferralSentDate: new Date().toISOString().split('T')[0],
                        ReferralCode: userData.ReferralParam
                    },
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    const newPersonId = data.id;
                    setRefreshReferrals(prev => !prev);
                    setnewPerson(newPersonId);
                    setShowNotif(true, setCopyClipboardNotif);
                    const contents = {
                        type: "success",
                        heading: "Added to Network!",
                        message: `You've added ${referralInput} to your Network. You will be able to see their info and start inviting them in < 2 mins`
                    }
                    setContents(contents, setCopyClipboardNotif);

                    fetch(
                        `https://mycorsproxy-tuto-35940f676099.herokuapp.com/https://api.clay.com/v3/sources/webhook/pull-in-data-from-a-webhook-7e45f723-9ec9-4222-94e1-d3d4689e5a1b`,
                        {
                            method: "POST",
                            headers: {
                                Authorization: `Bearer ${clayApiKey}`,
                                "Content-Type": "application/json",
                                "Access-Control-Allow-Origin": "*",
                                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                            },
                            body: JSON.stringify({
                                PersonalLinkedIn: referralInput,  // Using state variable here as well
                                Id: newPersonId
                            }),
                        }
                    );

                    return fetch(
                        `https://mycorsproxy-tuto-35940f676099.herokuapp.com/https://api.clay.com/v3/sources/webhook/pull-in-data-from-a-webhook-c5fb1e53-27a2-4efb-b33e-197df7577ea9`,
                        {
                            method: "POST",
                            headers: {
                                Authorization: `Bearer ${clayApiKey}`,
                                "Content-Type": "application/json",
                                "Access-Control-Allow-Origin": "*",
                                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                            },
                            body: JSON.stringify({
                                Email: referralInput,  // Using state variable here as well
                                Id: newPersonId
                            }),
                        }
                    );
                })
                .then(response => {
                    if (response) {
                        console.log("response - it worked!", response);
                        console.log("new person id worked!", newPerson);
                    }
                })
                .catch((error) => console.error("Error:", error));

            setIsInvited(false);
            setLinkedInUrl('');
            setUrlError('');
    };

    const handleDelete = async (removedPerson) => {
        try {
            // Delete from Airtable
            await fetch(`https://api.airtable.com/v0/${baseId}/Referrals/${removedPerson.id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${apiKey}`,
                    'Content-Type': 'application/json'
                }
            });

            // Update frontend state
            setPeople(prevPeople => prevPeople.filter(person => person.id !== removedPerson.id));
            setRefreshReferrals(prev => !prev);

            // Show success notification
            setShowNotif(true, setCopyClipboardNotif);
            setContents({
                type: "success",
                heading: "Contact Deleted",
                message: "Contact has been removed from your network"
            }, setCopyClipboardNotif);

        } catch (error) {
            console.error('Error deleting record:', error);

            // Show error notification
            setShowNotif(true, setCopyClipboardNotif);
            setContents({
                type: "error",
                heading: "Error",
                message: "Failed to delete contact. Please try again."
            }, setCopyClipboardNotif);
        }
    };

    const isParticipant = async (input) => {
        if (input.includes("@")) { // if email input
            const response = await fetch(`${GROWSURF_BASE_URL}/campaign/${GROWSURF_CAMPAIGN_ID}/participant/${input}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${GROWSURF_API_KEY}`,

                    'Content-Type': 'application/json',
                }
            });
            const data = await response.json();
            growsurfEmail = input;
            return data.status === undefined;
        } else if (input.includes("http")) { // if linkedin input
            const url = `https://api.airtable.com/v0/${baseId}/Referrals?filterByFormula={PersonalLinkedIn}='${encodeURIComponent(input)}'`;
            const headers = {
                Authorization: `Bearer ${apiKey}`,
                'Content-Type': 'application/json'
            };
            const response = await fetch(url, { headers });
            const data = await response.json();
            if (data.records.length > 0) { // check if the linkden input has an email with it
                const matchingRecord = data.records.find(record => record.fields.Email);
                if (matchingRecord) {
                    growsurfEmail = matchingRecord.fields.Email;
                    return true;
                } else {
                    growsurfEmail = "";
                    return false;
                }
            } else {
                growsurfEmail = "";
                return false;
            }
        }
    };

    function replacePlaceholders(text, person) {
        const placeholders = {
            "FirstName": person?.fields?.FirstName || "",
            "LastName": person?.fields?.LastName || "",
            "Company": person?.fields?.Company || "",
            "City": person?.fields?.City || "",
            "Email": person?.fields?.Email || "",
            "PersonalLinkedIn": person?.fields?.PersonalLinkedIn || "",
            "ProfilePic": person?.fields?.ProfilePic || "",
            "Status": person?.fields?.Status || "",
            "Created": person?.fields?.Created || "",
            "UploadedBy": person?.fields?.UploadedBy || "",
            "Position": person?.fields?.Position || "",
            "Website": person?.fields?.Website || "",
            "Country": person?.fields?.Country || "",
            "Headcount": person?.fields?.Headcount || "",
            "Enriched": person?.fields?.Enriched || "",
            "Last Modified": person?.fields?.["Last Modified"] || "",
            "UploadedByFirstName": person?.fields?.UploadedByFirstName || "",
            "UploadedByLastName": person?.fields?.UploadedByLastName || "",
            "UploadedByFullName": person?.fields?.UploadedByFullName || "",
            "ReferralSentDate": person?.fields?.ReferralSentDate || "",
            "ReferralLink":  ("https://www.getupgraded.ca/"+ userData.ReferralParam) || "",
            "ReferralCode": person?.fields?.ReferralCode || "",
            "YourName": (useAlternateIdentity ? "Amy" : userData["First Name"] && userData["Last Name"] ? userData["First Name"] + " " + userData["Last Name"] : userData["First Name"] || userData["Last Name"] || ""),
        };

        console.log("the userdata values", Object.keys(userData));
    
        if (!text || typeof text !== "string") return text; // Ensure text is valid
        
        Object.entries(placeholders).forEach(([key, value]) => {
            console.log(`Replacing {{${key}}} with ${value}`);
            const regex = new RegExp(`\\{\\{${key}\\}\\}`, "gi"); // Strictly match {{Key}}
            text = text.replace(regex, value);
        });
        
        console.log("Updated Text:", text);
        return text;
    }

    function insertVars(message, subject, person) {
        // Create personalized versions of subject and message
        let personalizedSubject = replacePlaceholders(subject, person);
        let personalizedMessage = replacePlaceholders(message, person);

        return [personalizedMessage, personalizedSubject];
    }

    async function updateTempInviteMessage(message, subject, person) {
        console.log("person is", person);
        const emailContent = insertVars(message, subject, person);

        const fetchUrl = `https://api.airtable.com/v0/${baseId}/users?filterByFormula=${encodeURIComponent(`{Email}='${userData.Email}'`)}`;
        const headers = {
            Authorization: `Bearer ${apiKey}`,
            'Content-Type': 'application/json'
        };
    
        try {
            // Step 1: Fetch the record
            const fetchResponse = await fetch(fetchUrl, { headers });
            const fetchData = await fetchResponse.json();
    
            if (!fetchData.records.length) {
                console.error("No matching record found in Airtable.");
                return;
            }
    
            // Extract Record ID
            const recordId = fetchData.records[0].id;
    
            // Step 2: Update the record
            const updatePayload = {
                records: [{
                    id: recordId,
                    fields: {
                        TempEditInviteMessage: emailContent[0],
                        TempEditInviteSubject: emailContent[1],
                        plainOrHtml: (isPlainText ? "true" : null),
                    }
                }]
            };
    
            const updateUrl = `https://api.airtable.com/v0/${baseId}/users`;
            const updateResponse = await fetch(updateUrl, {
                method: 'PATCH',
                headers,
                body: JSON.stringify(updatePayload)
            });
    
            if (!updateResponse.ok) {
                throw new Error(`Update failed: ${updateResponse.statusText}`);
            }
    
            setRefreshReferrals(prev => !prev);
            console.log("Record updated successfully.");
        } catch (error) {
            console.error("Error updating temp message in Airtable:", error);
        }
    }    

    async function updatePersonEmail(recordId, email) {
        const updateUrl = `https://api.airtable.com/v0/${baseId}/Referrals/${recordId}`;
        const headers = {
            Authorization: `Bearer ${apiKey}`,
            'Content-Type': 'application/json'
        };

        try {
            // Prepare the update payload
            const updatePayload = {
                fields: {
                    Email: email, // Update the Email field
                    UploadedBy: loginEmail
                }
            };

            // Send the PATCH request to update the record
            const updateResponse = await fetch(updateUrl, {
                method: 'PATCH',
                headers,
                body: JSON.stringify(updatePayload)
            });

            if (updateResponse.ok) {
                console.log("Email updated successfully for record ID:", recordId, "New Email:", email);
            } else {
                console.error("Failed to update email:", await updateResponse.json());
            }
        } catch (error) {
            console.error("Error updating email:", error);
        }
    }

    async function addToGrowsurf(input) {
        try {
            const firstName = user?.firstName || 'Unknown';
            const lastName = user?.lastName || 'User';
            const fullName = `${firstName} ${lastName}`.trim();
            let isExistingParticipant = false;
            if(growsurfEmail) {
                isExistingParticipant = await isParticipant(growsurfEmail);
            } else if(input?.fields?.Email) {
                isExistingParticipant = await isParticipant(input.fields.Email);
            }
            // If there's an email to work with, rate limit
            if (growsurfEmail.includes("@")) {
                const rateLimit = await checkRateLimit(userData.Email, growsurfEmail.split("@")[1]);
                if (!rateLimit) {
                    setShowNotif(true, setCopyClipboardNotif);
                    const contents = {
                        type: "error",
                        heading: "Failed to invite",
                        message: `You've reached the rate limit for referring people from the domain ${growsurfEmail.split("@")[1]}. Try again in an hour.`
                    }
                    setContents(contents, setCopyClipboardNotif);
                    setIsInvited(false);
                    return;
                }
            }

            if (isExistingParticipant) {
                await fetch(
                    `${GROWSURF_BASE_URL}/campaign/${GROWSURF_CAMPAIGN_ID}/participant/${growsurfEmail}`,
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${GROWSURF_API_KEY}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ referredBy: userData.Email }),
                    }
                );

                setShowNotif(true, setCopyClipboardNotif);
                const contents = {
                    type: "success",
                    heading: "Invited to referral program!",
                    message: `You've invited ${growsurfEmail} to your referral program! Your invite email has been sent.`
                }

                /*if(addThroughImportNetwork) {
                    sendEmailInvite(growsurfEmail);
                    addThroughImportNetwork = false;
                }*/

                setContents(contents, setCopyClipboardNotif);

                // set isInviting in airtable to true
                await fetch(`https://api.airtable.com/v0/${baseId}/Referrals/${input.id}`, {
                    method: "PATCH",
                    headers: {
                        Authorization: `Bearer ${apiKey}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        fields: {
                            IsInviting: true,
                            UploadedByFirstName: firstName,
                            UploadedByLastName: lastName,
                            UploadedByFullName: fullName,
                            ReferralSentDate: new Date().toISOString().split('T')[0],
                            ReferralCode: userData.ReferralParam
                        }
                    })
                });
            } else if (growsurfEmail.includes("@")) {
                // Add new participant to GrowSurf
                await fetch(
                    `${GROWSURF_BASE_URL}/campaign/${GROWSURF_CAMPAIGN_ID}/participant`,
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${GROWSURF_API_KEY}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ email: growsurfEmail, referredBy: userData.Email, referralStatus: 'CREDIT_PENDING' }),
                    }
                ).catch((error) => console.error("Error adding participant:", error));

                // Have another notification appear after the enrich notif
                setShowNotif(true, setCopyClipboardNotif);
                const contents = {
                    type: "success",
                    heading: "Invited to referral program!",
                    message: `You've invited ${growsurfEmail} to your referral program! Your invite email has been sent.`
                }

                /*if(addThroughImportNetwork) {
                    sendEmailInvite(growsurfEmail);
                    addThroughImportNetwork = false;
                }*/

                setContents(contents, setCopyClipboardNotif);

                // set isInviting in airtable to true
                await fetch(`https://api.airtable.com/v0/${baseId}/Referrals/${input.id}`, {
                    method: "PATCH",
                    headers: {
                        Authorization: `Bearer ${apiKey}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        fields: {
                            IsInviting: true
                        }
                    })
                });
            }
            setIsInvited(true);
            setInviteOneContact(null);
        } catch (error) {
            console.error("Error checking participant status:", error);
            setShowNotif(true, setCopyClipboardNotif);
            setContents({
                type: "error",
                heading: "Error",
                message: "Failed to process referral. Please try again."
            }, setCopyClipboardNotif);
        }
        growsurfEmail = "";
    }

    async function addNewPeople(person) {
        addToGrowsurf(person);
        
        setIsInviteOneVisible(false);
        setEditInviteError("");
        setSelectedPerson(null);
    }

    // dummy data
    const deals = [
        {
            "name": "AWS",
            "title": "Amazon's cloud services platform",
            "imageUrl": "https://cdn.prod.website-files.com/619c5a34100fe677d0c61e04/61a9a2f67ce25b0154a2cd81_AWS-Logo.png"
        },
        {
            "name": "Notion",
            "title": "Organize teamwork and increase productivity",
            "imageUrl": "https://res.cloudinary.com/secretsaas/image/upload/c_fit,f_auto,h_80,q_auto,w_80/v1677062676/logo/Notion.png"
        },
        {
            "name": "Airtable",
            "title": "Easily store and organize your data",
            "imageUrl": "https://res.cloudinary.com/secretsaas/image/upload/c_fit,f_auto,h_80,q_auto,w_80/v1681200667/logo/Airtable.png"
        },
        {
            "name": "Typeform",
            "title": "Create beautiful forms and surveys that actually get responses",
            "imageUrl": "https://res.cloudinary.com/secretsaas/image/upload/c_fit,f_auto,h_80,q_auto,w_80/v1651502917/logo/Typeform.jpg"
        },
        {
            "name": "Google Cloud",
            "title": "Cloud services by Google",
            "imageUrl": "https://res.cloudinary.com/secretsaas/image/upload/c_fit,f_auto,h_80,q_auto,w_80/v1638533805/logo/Google%20Cloud.png"
        },
        {
            "name": "Segment",
            "title": "Collect, clean, and control customer data",
            "imageUrl": "https://img.logo.dev/segment.com?token=live_6a1a28fd-6420-4492-aeb0-b297461d9de2&size=200&format=jpg&greyscale=false"
        },
        {
            "name": "Stripe",
            "title": "Online payment processing for internet businesses",
            "imageUrl": "https://img.logo.dev/stripe.com?token=live_6a1a28fd-6420-4492-aeb0-b297461d9de2&size=200&format=jpg&greyscale=false"
        },
        {
            "name": "Intercom",
            "title": "Customer messaging platform for sales, marketing, and support",
            "imageUrl": "https://img.logo.dev/intercom.com?token=live_6a1a28fd-6420-4492-aeb0-b297461d9de2&size=200&format=jpg&greyscale=false"
        },
        {
            "name": "HubSpot",
            "title": "Marketing, sales, and service software that helps your business grow",
            "imageUrl": "https://img.logo.dev/hubspot.com?token=live_6a1a28fd-6420-4492-aeb0-b297461d9de2&size=200&format=jpg&greyscale=false"
        },
    ]

    // assign value to state after import
    const handleUploadComplete = async (uploadedRecords) => {
        const newRecords = await base('Referrals').select({
            pageSize: uploadedRecords.length,
            sort: [{ field: 'Created', direction: 'desc' }],
        }).firstPage();
        setPeople((prevRecords) => [...newRecords, ...prevRecords]);
        setRefreshReferrals(prev => !prev);
    };

    // dummy data
    const steps = [
        { id: '01', name: 'Export Network', description: 'Vitae sed mi luctus laoreet.', href: '#', status: 'complete' },
        { id: '02', name: 'Import Network', description: 'Vitae sed mi luctus laoreet.', href: '#', status: 'current' },
        { id: '03', name: 'Refer & Earn', description: 'Vitae sed mi luctus laoreet.', href: '#', status: 'upcoming' },
    ]

    const getInitials = (firstName, lastName) => {
        if (!firstName && !lastName) return '';
        return `${(firstName || '').charAt(0)}${(lastName || '').charAt(0)}`.toUpperCase();
    };

    const AvatarFallback = ({ firstName, lastName }) => (
        <div className="h-12 w-12 rounded-full bg-gray-200 flex items-center justify-center ring-2 ring-gray-100 shadow-md">
            <span className="text-base font-medium text-gray-600">
                {getInitials(firstName, lastName)}
            </span>
        </div>
    );

    console.log("user", user)

    useEffect(() => {
        fetch(`https://api.airtable.com/v0/${baseId}/users?filterByFormula=${encodeURIComponent(`{Email}="${loginEmail}"`)}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${apiKey}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(findData => {
                // Check if a record was found
                if (!findData.records || findData.records.length === 0) {
                    throw new Error("No matching user found.");
                }

                // Extract the field values from the first matching record
                const record = findData.records[0].fields;
                setEmailSubject(record.EmailInviteSubject && record.EmailInviteSubject.trim() !== ""
                    ? record.EmailInviteSubject
                    : "Join Upgraded and we'll both get $1,000 :)");

                setEmailBody(record.EmailInviteMessage && record.EmailInviteMessage.trim() !== ""
                    ? record.EmailInviteMessage
                    : `Hey! Hope you're off to a good week, curious, but what are you doing in terms of grants and tax credits outside SR&ED and IRAP? I came across this interesting platform to create personalized grant reports for my network that helps you see which of the over 1,500 programs in Canada you qualify for - app.getupgraded.ca\n\nUse my invite link to sign up and get 5% off their plans. If we both sign up for the managed service with a $57,000/yr guarantee, we'll each get $1,000!\n\nSign up here: https://www.getupgraded.ca/${record.ReferralParam}`);

                console.log("Retrieved Email Subject:", emailSubject);
                console.log("Retrieved Email Message:", emailBody);
            })
            .catch(error => {
                console.error("Error:", error);
            });

        const fetchUser = async () => {

            const tableName = 'users'
            const url = `https://api.airtable.com/v0/${baseId}/${tableName}?filterByFormula={Email}='${encodeURIComponent(loginEmail)}'`;

            const headers = {
                Authorization: `Bearer ${apiKey}`,
                'Content-Type': 'application/json'
            };

            try {
                const response = await fetch(url, { headers });
                if (!response.ok) throw new Error('Network response was not ok');

                const data = await response.json();
                // Assuming there is only one user with this email, otherwise you might need additional handling
                const userData = data.records.length > 0 ? data.records[0].fields : null;
                console.log("user data", userData);
                setUserData(userData);
                console.log("User data fetched:", userData);
            } catch (error) {
                console.error('Error:', error);
                console.error("Error fetching user data:", error);
            }
        };

        fetchUser();
    }, [loginEmail]);

    // Add these helper functions
    const validateLinkedInUrl = (url) => {
        const linkedInRegex = /^https:\/\/(www\.)?linkedin\.com\/in\/[\w-]+\/?$/;
        return linkedInRegex.test(url);
    };

    const invalidServiceProviders = [
        // "gmail.com",
        "outlook.com",
        "outlook.live.com",
        "hotmail.com",
        "yahoo.com",
        "yahoo.co.ca",
        "yahoo.ca",
        "myyahoo.com",
    ];

    const validateEmail = (email) => {
        if (invalidServiceProviders.includes(extractDomain(email).toLowerCase())) {
            return false;
        }
        return true;
    };

    /*const sendEmailInvite = async (email) => {
        // Prepare the data to send to Zapier
        const zapierWebhookUrl = "https://mycorsproxy-tuto-35940f676099.herokuapp.com/" + zapierUrl;
        const zapierPayload = {
            firstname: userData["First Name"],
            lastname: userData["Last Name"],
            replytoemail: userData.ReplyToEmail,
            email: email,
            subject: emailSubject,
            message: emailBody
        };

        try {
            // Send the data to Zapier
            const zapierResponse = await fetch(zapierWebhookUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(zapierPayload)
            });

            if (zapierResponse.ok) {
                console.log("Invite sent to Zapier successfully");
            } else {
                console.error("Failed to send invite to Zapier:", await zapierResponse.json());
            }
        } catch (error) {
            console.error("Error sending invite to Zapier:", error);
        }
    };*/

    const handleAddContact = (e) => {
        e.preventDefault();


        if (referralInput.includes("http") && !validateLinkedInUrl(referralInput)) {
            setUrlError('Please enter a valid LinkedIn profile URL (e.g., https://linkedin.com/in/username)');
            return;
        } else if (referralInput.includes("@") && !validateEmail(referralInput)) {
            setUrlError('Please enter a valid corporate email address (e.g., username@company.com)');
            return;
        } else if (!referralInput.includes("http") && !referralInput.includes("@")) {
            setUrlError('Please enter a valid email address or LinkedIn URL');
            return;
        }

        // if linkedin input and user isn't on upgrade, ask for email input instead
        (async () => {
            /*const isExistingParticipant = await isParticipant(referralInput);
            if (referralInput.includes("http") && !isExistingParticipant) {
                setUrlError('This user is not on Upgraded. Please enter their email address instead to invite them.');
                return;
            }*/

            // If validation passes, proceed with adding contact
            addThroughImportNetwork = true;
            enrichConnection();
            setIsAddContactModalOpen(false);
        })();
    };

    // Add this new function
    const inviteAllContacts = async (tempEmailBody, tempEmailSubject) => {
        if (tempEmailBody.trim() === "" && tempEmailSubject.trim() === "") {
            setEditInviteError('Please enter an email subject and message.');
            return;
        } else if (tempEmailSubject.trim() === "") {
            setEditInviteError('Please enter an email subject.');
            return;
        } else if (tempEmailBody.trim() === "") {
            setEditInviteError('Please enter an email message.');
            return;
        } else if (tempEmailBody.length > 600) {
            setEditInviteError('Your message is too long.');
            return;
        }

        setIsInvitingAll(true);
        setIsInviteAllVisible(false);
        let currentIndex = 0;

        const processNextContact = async () => {
            const filteredPeople = people.filter(person =>
                (person.fields.UploadedBy === loginEmail ||
                person.fields.UploadedBy === "team@getupgraded.ca") && invitePermissions[person.id]
            );

            if (currentIndex < filteredPeople.length && currentIndex < 10) {
                const person = filteredPeople[currentIndex];
                await updateTempInviteMessage(tempEmailBody, tempEmailSubject, person).then(() => {
                    addNewPeople(person);

                    // Wait for 2 seconds before processing next contact to avoid rate limiting
                    setTimeout(() => {
                        currentIndex++;
                        processNextContact();
                    }, 2000);
                });
            } else {
                setIsInviteAllVisible(false);

                // Extract invited contacts list
                const invitedContacts = filteredPeople.map(person =>
                    person.fields.FirstName && person.fields.LastName
                        ? `${person.fields.FirstName} ${person.fields.LastName}`
                        : person.fields.Email
                ).join(", ");

                const message = currentIndex > 0 
                    ? `Successfully invited ${currentIndex} contact(s): ${invitedContacts}. Please wait 1 minute to bulk invite again.`
                    : `Successfully invited ${currentIndex} contact(s). Please wait 1 minute to bulk invite again.`;

                setShowNotif(true, setCopyClipboardNotif);
                setContents({
                    type: "success",
                    heading: "Bulk Invite Complete!",
                    message
                }, setCopyClipboardNotif);

                // Generate a random wait time between 20s and 60s (20000ms to 60000ms)
                const randomWaitTime = Math.floor(Math.random() * (60000 - 20000 + 1)) + 20000;

                // Set a timeout before allowing another bulk invite
                setTimeout(() => {
                    setIsInvitingAll(false); // Re-enable bulk invite after the wait
                }, randomWaitTime);
            }
        };

        processNextContact();
    };

    useEffect(() => {
        const determineInvitePermissions = async () => {
            const permissions = {};
            const reasons = {};

            for (const person of people) {
                try {
                    // Check if the person can be invited
                    const canInviteResult = await canInvite(person, userData);

                    // Store the permission status
                    permissions[person.id] = canInviteResult.canInvite;

                    // Store detailed reason why invite is disabled
                    if (!canInviteResult.canInvite) {
                        reasons[person.id] = canInviteResult.reason;
                    }
                } catch (error) {
                    console.error(`Error determining invite permission for ${person.id}:`, error);
                    permissions[person.id] = false;
                    reasons[person.id] = 'Error checking invite permissions';
                }
            }

            // Set both states at once to reduce re-renders
            setInvitePermissions(permissions);
            setInviteDisabledReasons(reasons);
        };

        // Only run this when people or userData changes
        if (people.length > 0 && userData) {
            determineInvitePermissions();
        }
    }, [people, userData]);

    // Then update your button rendering with tooltip:

    // Add this debug useEffect
    useEffect(() => {
        if (isInviteVisible && window.growsurf) {
            window.growsurf.init(); // Reinitialize GrowSurf
        }
    }, [isInviteVisible]);

    window.addEventListener('grsfReady', () => {
        window.growsurf.addParticipant({
            email: userData.Email,
            firstName: userData.FirstName,
            lastName: userData.LastName
        });
    });

    const handleSaveEdit = async () => {
        if (prevEditingPersonEmail !== editingPerson.fields.Email && !invitePermissions[editingPerson.id]) {
            const isConfirmed = window.confirm("This contact's email is linked to a referral. Changing it may remove them from your referral program. Are you sure you want to proceed?");
            if (!isConfirmed) return; // Exit early if the user cancels
        }

        try {
            // Update the person's details in Airtable
            const updatedFields = {
                FirstName: editingPerson.fields.FirstName,
                LastName: editingPerson.fields.LastName,
                Company: editingPerson.fields.Company,
                Headcount: editingPerson.fields.Headcount,
                Position: editingPerson.fields.Position,
                City: editingPerson.fields.City,
                Website: editingPerson.fields.Website,
                Email: editingPerson.fields.Email,
            };

            await fetch(`https://api.airtable.com/v0/${baseId}/Referrals/${editingPerson.id}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${apiKey}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fields: updatedFields
                })
            });

            // Update the local state to reflect changes in UI
            setPeople(currentPeople => 
                currentPeople.map(person => 
                    person.id === editingPerson.id 
                        ? { ...person, fields: { ...person.fields, ...updatedFields } }
                        : person
                )
            );
            setRefreshReferrals(prev => !prev);

            // Close the modal
            setEditModalOpen(false);
            setEditingPerson(null);

            // Use your existing notification system
            setShowNotif(true, setCopyClipboardNotif);
            setContents({
                type: "success",
                heading: "Updated contact",
                message: "Contact updated successfully!"
            }, setCopyClipboardNotif);
        } catch (error) {
            console.error("Error updating contact:", error);
            // Use your existing notification system
            setShowNotif(true, setCopyClipboardNotif);
            setContents({
                type: "error",
                heading: "Error",
                message: "Failed to update contact. Please try again."
            },setCopyClipboardNotif);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancelEdit = () => {
        setEditModalOpen(false);
    };

    const handleEdit = (person) => {
        setEditingPerson(person);
        setPrevEditingPersonEmail(person.fields.Email);
        setEditModalOpen(true);
    };

    const RATE_LIMIT = 10;
    const HOUR_IN_MS = 60 * 60 * 1000;

    const checkRateLimit = async (userEmail, domain) => {
        try {
            const now = new Date();
            const oneHourAgo = new Date(now - HOUR_IN_MS);

            const formattedOneHourAgo = oneHourAgo.toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
            });

            // Fetch invites sent by the user to the domain within the last hour
            const response = await fetch(
                `https://api.airtable.com/v0/${baseId}/Referrals?filterByFormula=${encodeURIComponent(
                    `AND(
                        {UploadedBy} = '${userEmail}',
                        OR(
                            FIND('@${domain}', {Email}) > 0,
                            FIND('@${domain}', {PersonalLinkedIn}) > 0
                        ),
                        {Created} != BLANK(),
                        IS_AFTER({Created}, '${formattedOneHourAgo}')
                    )`
                )}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${apiKey}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            const data = await response.json();
            const records = data.records;
            const uniqueRecords = [];
            const seenEmails = new Set();

            records.forEach(record => {
                const email = record.fields.Email || record.fields.PersonalLinkedIn;
                if (!seenEmails.has(email)) {
                    seenEmails.add(email);
                    uniqueRecords.push(record);
                }
            });

            if (uniqueRecords.length >= RATE_LIMIT) {
                return false; // Rate limit exceeded
            } else {
                return true; // Rate limit not exceeded
            }
        } catch (error) {
            console.error("Error checking rate limit:", error);
            return false; // Assume rate limit is exceeded in case of an error
        }
    };

    const handleOpenInviteModal = (person) => {
        // Don't toggle the identity automatically
        // Instead, keep the current value or set it based on some user preference
        
        // If you want to let the user choose, don't set it here at all
        // If you want a default, use this:
        // setUseAlternateIdentity(false); // Always default to "as Will"
        // OR
        // setUseAlternateIdentity(true); // Always default to "as Amy"
        
        console.log("Opening invite modal for person:", person);
        setSelectedPerson(person);
        setPersonId(person.id);
        setIsInviteVisible(true);
    };

    const handleShare = (e, platform) => {
        e.preventDefault();

        const referralUrl = `https://getupgraded.ca${userData?.ReferralParam}`;
        const userName = userData?.FirstName || "I";

        // Platform-specific default messages
        const messages = {
            default: {
                title: "Check out Upgraded",
                text: `${userName} thought you might be interested in Upgraded. They've helped me boost my business revenue significantly. Check them out using my referral link:`
            },
            facebook: {
                title: "Upgraded has transformed my business",
                text: `${userName}'ve been using Upgraded to streamline my business operations and increase revenue. If you're looking to grow your business, I highly recommend checking them out!`
            },
            linkedin: {
                title: "A business tool that's actually worth it",
                text: `${userName}'ve been using Upgraded to help scale my business operations. They offer excellent ROI and their team is incredibly supportive. Connect with them through my referral link:`
            },
            twitter: {
                title: "Upgraded my business (literally)",
                text: `${userName} just wanted to share @GetUpgraded - they've helped me increase revenue by streamlining operations. Use my referral link to get started:`
            },
            whatsapp: {
                title: "Check out Upgraded",
                text: `Hey! ${userName} thought you might be interested in Upgraded. They've helped me boost my business significantly. Here's my referral link:`
            },
            email: {
                title: "A business tool I think you'll love",
                text: `Hi there,\n\nI've been using Upgraded for my business and thought you might be interested too. They've helped me with streamlining operations and increasing revenue.\n\nYou can check them out using my referral link below:\n\n`
            },
            instagram: {
                title: "Check out Upgraded",
                text: `${userName}'ve been using @getupgraded to help grow my business. They're amazing! Check out my link in bio or use this referral link:`
            }
        };

        // Get the appropriate message for the platform or use default
        const shareTitle = messages[platform]?.title || messages.default.title;
        const shareText = messages[platform]?.text || messages.default.text;

        // Try Web Share API first (works on mobile and some modern browsers)
        if (navigator.share && (platform === 'native' || platform === 'whatsapp' || platform === 'instagram')) {
            navigator.share({
                title: shareTitle,
                text: shareText,
                url: referralUrl,
            })
                .catch(error => console.log('Error sharing:', error));
            return;
        }

        // Platform-specific sharing URLs
        let shareUrl;
        switch (platform) {
            case 'facebook':
                shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(referralUrl)}&quote=${encodeURIComponent(shareText)}`;
                break;
            case 'linkedin':
                shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(referralUrl)}&title=${encodeURIComponent(shareTitle)}&summary=${encodeURIComponent(shareText)}`;
                break;
            case 'twitter':
                shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(referralUrl)}`;
                break;
            case 'whatsapp':
                shareUrl = `https://wa.me/?text=${encodeURIComponent(shareText + ' ' + referralUrl)}`;
                break;
            case 'email':
                shareUrl = `mailto:?subject=${encodeURIComponent(shareTitle)}&body=${encodeURIComponent(shareText + '\n\n' + referralUrl)}`;
                break;
            case 'instagram':
                // Instagram doesn't support direct link sharing - copy to clipboard instead
                navigator.clipboard.writeText(shareText + ' ' + referralUrl)
                    .then(() => {
                        setCopiedLink(true);
                        setTimeout(() => setCopiedLink(false), 2000);
                        alert('Message and referral link copied! Share it on Instagram.');
                    })
                    .catch(err => console.error('Failed to copy: ', err));
                return;
            default:
                return;
        }

        // Open share URL in new window
        window.open(shareUrl, '_blank', 'width=600,height=400');
    };

    // jsx
    return (
        <>
            <div className="bg-white">
                <div className="relative">
                    <div className="mx-auto max-w-7xl px-6 py-2 sm:px-8">
                        <div className="flex gap-x-2">
                            {/* Share your referral link card - Left Column */}
                            <div className="w-1/2 rounded-xl bg-white shadow-lg p-6">
                                <div className="mb-4">
                                    <h1 className="text-2xl font-bold text-gray-900 mb-3 flex items-center gap-2">
                                        Refer & earn (anonymously) <span className="text-2xl">✌️</span>
                                    </h1>
                                    <ul className="space-y-3 text-base text-gray-600 list-disc pl-5">
                                        <li>Earn <span className="font-semibold text-gray-900">$31,440 on average per 10 referrals</span></li>
                                        <li>Get <span className="font-semibold text-gray-900">15% of revenue for first 12 months</span></li>
                                        <li className="flex items-center list-item justify-between w-full">
                                            <div className="flex items-center">
                                                Sending as{" "}
                                                <span className="font-semibold text-gray-900 flex items-center ml-1">
                                                    {(!useAlternateIdentity && userData?.ReplyToEmail) ? (
                                                        <>
                                                            {
                                                                <img
                                                                    src={userData?.ProfileImage || 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHJlY3Qgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiBmaWxsPSIjRTVFN0VCIiAvPjwvc3ZnPg=='}
                                                                    alt="Profile"
                                                                    className="h-6 w-6 rounded-full object-cover ring-2 ring-gray-100 shadow-sm mr-1"
                                                                    onError={(e) => e.target.src = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHJlY3Qgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiBmaWxsPSIjRTVFN0VCIiAvPjwvc3ZnPg=='}
                                                                />
                                                            }
                                                            {`${userData?.["First Name"] && userData?.["Last Name"]
                                                                ? `${userData["First Name"]} ${userData["Last Name"]}`
                                                                : userData?.["First Name"]
                                                                ? userData["First Name"]
                                                                : userData?.["Last Name"]
                                                                ? userData["Last Name"]
                                                                : ""} (${(userData?.ReplyToEmail)?.split("@")[0] + "@getupgraded.ca"})`
                                                            }
                                                        </>
                                                    ) : (
                                                        <>
                                                            <img
                                                                src="/images/default-avatar.png"
                                                                alt="Default Profile"
                                                                className="h-6 w-6 rounded-full object-cover ring-2 ring-gray-100 shadow-sm mr-1"
                                                            />
                                                            Amy Johanneson (amy@getupgraded.ca)
                                                        </>
                                                    )}
                                                </span>
                                                <button
                                                    type="button"
                                                    className={`${useAlternateIdentity ? 'bg-indigo-600' : 'bg-gray-200'} 
                                                relative ml-auto inline-flex h-4 w-8 flex-shrink-0 cursor-pointer rounded-full 
                                                border-2 border-transparent transition-colors duration-200 ease-in-out 
                                                focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                                                    role="switch"
                                                    aria-checked={useAlternateIdentity}
                                                    onClick={() => {
                                                        if (useAlternateIdentity && userData?.ReplyToEmail || userData?.ReplyToEmail) {
                                                            setUseAlternateIdentity(!useAlternateIdentity);
                                                        } else if (useAlternateIdentity && !userData?.ReplyToEmail) {
                                                            setShowNotif(true, setCopyClipboardNotif);
                                                            setContents({
                                                                type: "error",
                                                                heading: "Can't Send as Yourself",
                                                                message: "Please go to Settings > Personal Information and add your reply-to email to send invites as yourself."
                                                            }, setCopyClipboardNotif);
                                                        }
                                                    }}
                                                >
                                                    <span
                                                        aria-hidden="true"
                                                        className={`${useAlternateIdentity ? 'translate-x-4' : 'translate-x-0'}
                                                    pointer-events-none inline-block h-3 w-3 transform rounded-full 
                                                    bg-white shadow ring-0 transition duration-200 ease-in-out`}
                                                    />
                                                </button>
                                            </div>
                                        </li>
                                        {/* Debug output for userData */}
                                        {/* <li className="mt-2 p-2 bg-gray-100 rounded text-xs overflow-auto max-h-40">
                                            <div className="font-bold mb-1">Debug - User Data:</div>
                                            <pre>{JSON.stringify(userData, null, 2)}</pre>
                                            <div className="font-bold mt-2 mb-1">Loading State:</div>
                                            <div>useAlternateIdentity: {useAlternateIdentity ? 'true' : 'false'}</div>
                                            <div className="font-bold mt-2 mb-1">Toggle Debug:</div>
                                            <div>Toggle Click Handler: {typeof setUseAlternateIdentity === 'function' ? 'Function exists' : 'Missing function'}</div>
                                        </li> */}
                                        <li>Watch <a href="https://www.getupgraded.ca/customer-stories" target="_blank" rel="noopener noreferrer" className="font-semibold text-gray-900 hover:text-gray-700 underline">client testimonial videos</a>
                                            {' '} or {' '}
                                            <button
                                                className="font-semibold text-gray-900 hover:text-gray-700 underline focus:outline-none text-m mb-2"
                                                onClick={() => window.open("https://www.loom.com/share/3a1e44655eab4a718c7be47fdcbbf82c?sid=6b5373cf-d828-448e-b90e-e1e75d06fc2b", "_blank")}
                                            >
                                                adding referrals guide
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="space-y-4">
                                    <div className="flex gap-x-6 pt-2">
                                        <h3 className="text-sm font-medium text-gray-900">Share your unique referral link</h3>
                                        {/* Social media buttons */}
                                        <a href="#" onClick={(e) => handleShare(e, 'facebook')} className="text-[#1877F2] hover:opacity-80 transition-opacity"><span className="sr-only">Facebook</span><FaFacebook className="h-4 w-4" /></a>
                                        <a href="#" onClick={(e) => handleShare(e, 'linkedin')} className="text-[#0077B5] hover:opacity-80 transition-opacity"><span className="sr-only">LinkedIn</span><FaLinkedin className="h-4 w-4" /></a>
                                        <a href="#" onClick={(e) => handleShare(e, 'twitter')} className="text-[#1DA1F2] hover:opacity-80 transition-opacity"><span className="sr-only">Twitter</span><FaTwitter className="h-4 w-4" /></a>
                                        <a href="#" onClick={(e) => handleShare(e, 'instagram')} className="text-[#E4405F] hover:opacity-80 transition-opacity"><span className="sr-only">Instagram</span><FaInstagram className="h-4 w-4" /></a>
                                        <a href="#" onClick={(e) => handleShare(e, 'whatsapp')} className="text-[#25D366] hover:opacity-80 transition-opacity"><span className="sr-only">WhatsApp</span><FaWhatsapp className="h-4 w-4" /></a>
                                        <a href="#" onClick={(e) => handleShare(e, 'email')} className="text-gray-600 hover:opacity-80 transition-opacity"><span className="sr-only">Email</span><EnvelopeIcon className="h-4 w-4 stroke-2" /></a>
                                    </div>
                                    <div className="flex items-center gap-x-3">
                                        <input
                                            type="text"
                                            value={`https://getupgraded.ca${userData?.ReferralParam}`}
                                            readOnly
                                            className="flex-1 rounded-lg border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                        <button
                                            type="button"
                                            onClick={() => {
                                                const referralUrl = `https://getupgraded.ca${userData?.ReferralParam}`;
                                                navigator.clipboard.writeText(referralUrl)
                                                    .then(() => {
                                                        // Show a temporary success message
                                                        setCopiedLink(true);
                                                        setTimeout(() => setCopiedLink(false), 2000);
                                                    })
                                                    .catch(err => console.error('Failed to copy: ', err));
                                            }}
                                            className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        >
                                            {copiedLink ? 'Copied!' : 'Copy'}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* Steps - Right Column */}
                            <div className="w-1/2 rounded-xl bg-white shadow-lg p-6">
                                <nav className="flex-1 space-y-4" aria-label="Progress">
                                    <ol role="list" className="space-y-4">
                                        <li className="overflow-hidden rounded-xl border border-gray-200 bg-white p-4 shadow hover:shadow-md transition-shadow">
                                            <div className="flex items-center gap-x-5">
                                                <div className="flex h-12 w-12 items-center justify-center rounded-full bg-indigo-50">
                                                    <ArrowDownTrayIcon className="h-6 w-6 text-indigo-600" />
                                                </div>
                                                <div className="flex min-w-0 flex-1 flex-col">
                                                    <span className="text-base font-semibold text-gray-900">1. Import People</span>
                                                    <div className="flex gap-x-2 mt-2">
                                                        <button
                                                            className="rounded-full px-3 py-1.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                            onClick={handleOpenDataModal}
                                                        >
                                                            Bulk Upload
                                                        </button>
                                                        <span className="inline-flex items-center text-sm text-gray-500">or</span>
                                                        <button
                                                            className="rounded-full px-3 py-1.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                            onClick={handleOpenClipModal}
                                                        >
                                                            + Single Referral
                                                        </button>
                                                        <span className="inline-flex items-center text-sm text-gray-500">or</span>
                                                        <button
                                                            className="rounded-full px-3 py-1.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                            target="_blank"
                                                            onClick={() => window.open("https://www.linkedin.com/mypreferences/d/download-my-data", "_blank")}
                                                        >
                                                            Import Network
                                                        </button>
                                                        {/* <button className="text-blue-500 hover:text-blue-600" onClick={handleOpenModal}>How To Export</button> or <a className="text-blue-500 hover:text-blue-600" target="_blank" href="https://www.linkedin.com/mypreferences/d/download-my-data">Export Your Data</a> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="overflow-hidden rounded-xl border border-gray-200 bg-white p-4 shadow hover:shadow-md transition-shadow">
                                            <div className="flex items-center gap-x-5">
                                                <div className="flex h-12 w-12 items-center justify-center rounded-full bg-indigo-50">
                                                    <PencilIcon className="h-6 w-6 text-indigo-600" />
                                                </div>
                                                <div className="flex min-w-0 flex-1 flex-col">
                                                    <span className="text-base font-semibold text-gray-900">2. Review & Edit</span>
                                                    <span className="mt-1 text-sm text-gray-500">Use our proven invite messages</span>
                                                </div>
                                                <div className="flex items-center">
                                                    <button
                                                        onClick={() => { setIsEditInviteVisible(true); setTempEmailSubject(emailSubject); setTempEmailBody(emailBody); }}
                                                        className={`inline-flex items-center gap-x-1.5 rounded-md px-3.5 py-2 text-sm font-semibold text-white shadow-md ${isInvitingAll
                                                            ? 'bg-gray-400 cursor-not-allowed'
                                                            : 'bg-emerald-500 hover:bg-emerald-600'
                                                            }`}
                                                    >
                                                        Edit Default Invite Message
                                                    </button>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="rounded-xl border border-gray-200 bg-white p-4 shadow hover:shadow-md transition-shadow">
                                            <div className="flex items-center gap-x-5">
                                                <div className="flex h-12 w-12 items-center justify-center rounded-full bg-indigo-50">
                                                    <GiftIcon className="h-6 w-6 text-indigo-600" />
                                                </div>
                                                <div className="flex min-w-0 flex-1 flex-col">
                                                    <span className="text-base font-semibold text-gray-900">3. Refer & Earn</span>
                                                    <span className="mt-1 text-sm text-gray-500">Invite one or invite all</span>
                                                </div>
                                                <div className="flex items-center">
                                                    <div className="relative"
                                                        onMouseEnter={() => setTooltipVisible(prev => ({ ...prev, [0]: true }))}
                                                        onMouseLeave={() => setTooltipVisible(prev => ({ ...prev, [0]: false }))}
                                                    >
                                                        <button
                                                            onClick={() => { setIsInviteAllVisible(true); setTempEmailSubject(emailSubject); setTempEmailBody(emailBody); }}
                                                            className={`mr-1 inline-flex items-center gap-x-1.5 rounded-md px-3.5 py-2 text-sm font-semibold text-white shadow-md ${isInvitingAll
                                                                ? 'bg-gray-400 cursor-not-allowed'
                                                                : 'bg-emerald-500 hover:bg-emerald-600'
                                                                }`}
                                                        >
                                                            Invite All
                                                        </button>
                                                        {/* Tooltip */}
                                                        {tooltipVisible[0] && !isInvitingAll && (
                                                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-1.5 bg-gray-800 text-white text-xs rounded shadow-lg z-10 whitespace-nowrap">
                                                                Bulk Invite up to 10 uninvited contacts at once
                                                                {/* Tooltip arrow */}
                                                                <div className="absolute top-full left-1/2 transform -translate-x-1/2 border-4 border-transparent border-t-gray-800"></div>
                                                            </div>
                                                        )}
                                                    </div>

                                                    <span className="inline-flex items-center text-sm text-gray-500">or</span>
                                                    <button
                                                        onClick={() => { setIsInviteOneVisible(true); setTempEmailSubject(emailSubject); setTempEmailBody(emailBody); }}
                                                        className={`ml-1 inline-flex items-center gap-x-1.5 rounded-md px-3.5 py-2 text-sm font-semibold text-white shadow-md ${isInvitingAll
                                                            ? 'bg-gray-400 cursor-not-allowed'
                                                            : 'bg-emerald-500 hover:bg-emerald-600'
                                                            }`}
                                                    >
                                                        Invite One
                                                    </button>
                                                </div>
                                            </div>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="mt-4 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="max-w-[200px] py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                Person
                                            </th>
                                            <th scope="col" className="max-w-[200px] px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Company
                                            </th>
                                            <th scope="col" className="w-[150px] px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Est $ Earned
                                            </th>
                                            <th scope="col" className="w-[300px] relative py-3.5 pl-3 pr-4 sm:pr-6 text-sm font-semibold text-gray-900">
                                                Refer & Earn
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {grantLoading ? (
                                            <tr>
                                                <td colSpan={4} className="text-center py-4">
                                                    <ClipLoader />
                                                </td>
                                            </tr>
                                        ) : (
                                            displayedPeople
                                                .filter(person => person.fields.UploadedBy === loginEmail || person.fields.UploadedBy === "team@getupgraded.ca")
                                                .sort((a, b) => {
                                                    // Normalize country values to handle both "Canada" and "CA"
                                                    const countryA = (a.fields.Country || '').toLowerCase();
                                                    const countryB = (b.fields.Country || '').toLowerCase();

                                                    // Check if either country is Canada (including "ca")
                                                    const isCanadaA = countryA === 'canada' || countryA === 'ca';
                                                    const isCanadaB = countryB === 'canada' || countryB === 'ca';

                                                    if (isCanadaA && !isCanadaB) return -1;
                                                    if (!isCanadaA && isCanadaB) return 1;

                                                    // If neither or both are Canada, sort alphabetically
                                                    return countryA.localeCompare(countryB);
                                                })
                                                .map((person) => {
                                                    return (
                                                        <tr key={person.id} className="hover:bg-gray-50">
                                                            <td className="max-w-[200px] whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6">

                                                                <div className="flex items-center">
                                                                    {person.fields.ProfilePic ? (
                                                                        <img
                                                                            className="h-12 w-12 rounded-full object-cover ring-2 ring-gray-100 shadow-md"
                                                                            src={person.fields.ProfilePic}
                                                                            alt=""
                                                                            onError={(e) => {
                                                                                e.target.outerHTML = `<div class="h-12 w-12 rounded-full bg-gray-200 flex items-center justify-center ring-2 ring-gray-100 shadow-md">
                                                                                <span class="text-base font-medium text-gray-600">
                                                                                    ${getInitials(person.fields.FirstName, person.fields.LastName)}
                                                                                </span>
                                                                            </div>`;
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <AvatarFallback
                                                                            firstName={person.fields.FirstName}
                                                                            lastName={person.fields.LastName}
                                                                        />
                                                                    )}
                                                                    <div className="ml-4">
                                                                        <div className="font-medium text-gray-900">
                                                                            {person.fields.FirstName} {person.fields.LastName}
                                                                        </div>
                                                                        <div className="text-gray-500 text-sm">
                                                                            {person.fields.Email}
                                                                        </div>
                                                                        <div className="text-gray-500 text-sm">
                                                                            <StatusComponent person={person} userData={userData} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="max-w-[200px] whitespace-nowrap px-3 py-4">
                                                                <div>
                                                                    <div className="text-gray-900">{person.fields.Company} {person.fields.Headcount && ` • ${person.fields.Headcount}`}</div>
                                                                    <div className="text-gray-500 text-sm">
                                                                        {String(person.fields.Position).length > 45 ? person.fields.Position.slice(0, 45) + '...' : person.fields.Position}
                                                                        {person.fields.City && <div>{person.fields.City}</div>}
                                                                        {person.fields.Website && (
                                                                            <a
                                                                                href={`https://${person.fields.Website}`}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                className="inline-flex items-center gap-1 text-gray-400 hover:text-gray-600"
                                                                            >
                                                                                <LinkIcon className="h-3 w-3" />
                                                                                <span className="truncate max-w-[150px] text-xs">{person.fields.Website}</span>
                                                                            </a>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="w-[150px] whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                <div className="text-gray-900 font-medium">$3,144/yr</div>
                                                                <div className="text-gray-500">est. commission</div>
                                                            </td>
                                                            <td className="w-[300px] relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                                <div className="flex justify-end gap-2 items-center">
                                                                    {/* View Report Button */}
                                                                    <button
                                                                        className={classNames(
                                                                            "rounded-full px-2.5 py-1 text-xs font-semibold shadow-md ring-inset",
                                                                            person.fields.Website
                                                                                ? "bg-white text-gray-900 ring-gray-300 hover:bg-gray-50"
                                                                                : "bg-gray-50 text-gray-400 ring-gray-200 cursor-not-allowed"
                                                                        )}
                                                                        onClick={() => person.fields.Website && window.open(`https://app.getupgraded.ca/?website=${person.fields.Website}`, '_blank')}
                                                                        disabled={!person.fields.Website}
                                                                    >
                                                                        View Report
                                                                    </button>

                                                                    {/* LinkedIn Button - Updated styling */}
                                                                    <button
                                                                        className={classNames(
                                                                            "rounded-full px-2 py-1 text-xs font-semibold shadow-md ring-inset",
                                                                            person.fields.PersonalLinkedIn
                                                                                ? "bg-[#0A66C2] text-white hover:bg-[#004182]"
                                                                                : "bg-gray-50 text-gray-400 ring-1 ring-inset ring-gray-200 cursor-not-allowed"
                                                                        )}
                                                                        onClick={() => person.fields.PersonalLinkedIn && window.open(person.fields.PersonalLinkedIn, '_blank')}
                                                                        disabled={!person.fields.PersonalLinkedIn}
                                                                        title={person.fields.PersonalLinkedIn ? "View LinkedIn profile" : "No LinkedIn profile available"}
                                                                    >
                                                                        <FaLinkedin className="h-4 w-4" />
                                                                    </button>

                                                                    {/* Text Button - Updated */}
                                                                    <button
                                                                        className={`rounded-full px-2 py-1 text-xs font-semibold shadow-md ring-inset ${person.fields.MobileNumber
                                                                            ? "bg-green-500 text-white hover:bg-green-600"
                                                                            : "bg-gray-100 text-gray-400 cursor-not-allowed"
                                                                            }`}
                                                                        onClick={() => person.fields.MobileNumber && window.open(`sms:${person.fields.MobileNumber}`)}
                                                                        disabled={!person.fields.MobileNumber}
                                                                        title={person.fields.MobileNumber ? "Send text message" : "No mobile number available"}
                                                                    >
                                                                        <ChatBubbleOvalLeftIcon className="h-4 w-4" />
                                                                    </button>

                                                                    {/* Call Button - Updated */}
                                                                    <button
                                                                        className={`rounded-full px-1 py-1 text-xs font-semibold shadow-md ring-inset ${person.fields.MobileNumber
                                                                            ? "bg-blue-500 text-white hover:bg-blue-600"
                                                                            : "bg-gray-100 text-gray-400 cursor-not-allowed"
                                                                            }`}
                                                                        onClick={() => person.fields.MobileNumber && window.open(`tel:${person.fields.MobileNumber}`)}
                                                                        disabled={!person.fields.MobileNumber}
                                                                        title={person.fields.MobileNumber ? "Call contact" : "No mobile number available"}
                                                                    >
                                                                        <PhoneArrowUpRightIcon className="h-4 w-4" />
                                                                    </button>

                                                                    {/* Copy Button */}
                                                                    <button
                                                                        className="rounded-full bg-gray-900 px-2 py-1 text-xs font-semibold text-white shadow-md hover:bg-gray-800 ring-inset"
                                                                        onClick={() => handleCopy(person)}
                                                                        title="Copy referral message"
                                                                    >
                                                                        <DocumentDuplicateIcon className="h-4 w-4" />
                                                                    </button>

                                                                    {/* Invite Button with Tooltip */}
                                                                    <div className="relative"
                                                                        onMouseEnter={() => !invitePermissions[person.id] && setTooltipVisible(prev => ({ ...prev, [person.id]: true }))}
                                                                        onMouseLeave={() => setTooltipVisible(prev => ({ ...prev, [person.id]: false }))}>
                                                                        <div className="relative">
                                                                            <button
                                                                                className="rounded-full px-2.5 py-1 text-xs font-semibold text-white shadow-md ring-inset bg-emerald-500 hover:bg-emerald-600 disabled:bg-gray-400 disabled:hover:bg-gray-400"
                                                                                onClick={() => handleOpenInviteModal(person)}
                                                                                disabled={invitePermissions[person.id] === undefined}
                                                                            >
                                                                                {invitePermissions[person.id] ? "Invite" : inviteDisabledReasons[person.id]?.split(' ')[0] || "Status"}
                                                                            </button>
                                                                        </div>
                                                                        {/* Tooltip */}
                                                                        {!invitePermissions[person.id] && tooltipVisible[person.id] && (
                                                                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-1.5 bg-gray-800 text-white text-xs rounded shadow-lg z-10 whitespace-nowrap">
                                                                                {inviteDisabledReasons[person.id] || "Cannot invite at this time"}
                                                                                {/* Tooltip arrow */}
                                                                                <div className="absolute top-full left-1/2 transform -translate-x-1/2 border-4 border-transparent border-t-gray-800"></div>
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                    { /* Edit Button */}
                                                                    <button
                                                                        className="text-gray-400 hover:text-gray-500"
                                                                        onClick={() => handleEdit(person)} // Open edit modal
                                                                    >
                                                                        <PencilIcon className="h-5 w-5" aria-hidden="true" /> {/* Use a pencil icon */}
                                                                    </button>

                                                                    {/* Delete Button */}
                                                                    <button
                                                                        className="text-gray-400 hover:text-gray-500"
                                                                        onClick={() => handleDelete(person)}
                                                                    >
                                                                        <TrashIcon className="h-5 w-5" aria-hidden="true" />
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                        )}
                                    </tbody>

                                </table>
                                {/* Add loading indicator at bottom */}
                                {hasMore && (
                                    <div
                                        ref={observerTarget}
                                        className="w-full text-center py-4"
                                    >
                                        <ClipLoader size={20} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br />
            <br />
            <DataImportModal
                isOpen={isDataModalOpen}
                onClose={handleCloseDataModal}
                onUploadComplete={handleUploadComplete}
                setNotifications={setNotifications}
                setCopyClipboardNotif={setCopyClipboardNotif}
                handleUploadComplete={handleUploadComplete}
                user={userData}
            />
            <Notification
                showNotif={copyClipboardNotif.showNotif}
                setShowNotif={(val) => setCopyClipboardNotif(val, setCopyClipboardNotif)}
                contents={copyClipboardNotif.contents}
            />

            {/* Add this JSX near your other modals */}
            <Transition.Root show={isAddContactModalOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setIsAddContactModalOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div>
                                        <div className="text-left">
                                            <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                                                Add a Referral
                                            </Dialog.Title>
                                            <div className="mt-3">
                                                <p className="text-sm text-gray-600 leading-relaxed">
                                                    Help friends discover hidden savings opportunities. Your invite will be sent automatically if we find their email.
                                                </p>
                                                
                                                <p className="mt-2 text-base font-medium">
                                                    You will earn an average of <span className="text-indigo-600 font-bold">$3,144/yr</span> on each successful referral.
                                                </p>
                                            </div>
                                            <div className="mt-4">
                                                <form onSubmit={handleAddContact}>
                                                    <div>
                                                        <label htmlFor="personLinkedInUrl" className="block text-sm font-medium text-gray-700">
                                                            LinkedIn or Work Email
                                                        </label>
                                                        <div className="mt-1">
                                                            <input
                                                                type="text"
                                                                name="personLinkedInUrl"
                                                                id="personLinkedInUrl"
                                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                placeholder="https://linkedin.com/in/username or username@company.com"
                                                                value={referralInput}
                                                                onChange={(e) => {
                                                                    setLinkedInUrl(e.target.value);
                                                                    setUrlError('');
                                                                }}
                                                            />
                                                        </div>
                                                        {urlError && (
                                                            <p className="mt-2 text-sm text-red-600">{urlError}</p>
                                                        )}
                                                    </div>
                                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                                        <button
                                                            type="submit"
                                                            className="inline-flex w-full justify-center rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900 sm:col-start-2"
                                                        >
                                                            Add
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                                            onClick={() => {
                                                                setIsAddContactModalOpen(false);
                                                                setLinkedInUrl('');
                                                                setUrlError('');
                                                            }}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Modal */}
            {isInviteVisible && (
                <div className="modal-overlay">
                    <InviteModal
                        initSubject={emailSubject}
                        initMessage={emailBody}
                        onClose={() => {setIsInviteVisible(false); setSelectedPerson(null);}}
                        onSend={async (email, subject, message) => {
                            setEditInviteError('');
                            growsurfEmail = email;
                            await updateTempInviteMessage(message, subject, selectedPerson).then(() =>{
                                updatePersonEmail(personId, email);
                                addNewPeople(selectedPerson);
                            });
                            setIsInviteVisible(false);
                        }}
                        onChangePlainText={(isPlainText) => setIsPlainText(isPlainText)}
                        firstName={userData["First Name"]}
                        lastName={userData["Last Name"]}
                        replyTo={userData.ReplyToEmail}
                        person={selectedPerson} // Pass the entire person object
                        useAlternateIdentity={useAlternateIdentity}
                        setUseAlternateIdentity={setUseAlternateIdentity}  // Add this
                        userData={userData}
                    />
                </div>
            )}

            {/* Edit default invite message */}
            {(isEditInviteVisible || isInviteAllVisible || isInviteOneVisible) && (
                <div className="modal-overlay">
                    <div className="modal-content" style={{
                        width: '900px',
                        maxWidth: '90vw',
                        display: 'flex',
                        minHeight: '600px',
                        height: '630px',
                    }}>
                        {/* Available Variables Panel */}
                        <div style={{
                            backgroundColor: 'white',
                            padding: '15px',
                            marginRight: '15px',
                            border: '1px solid #e0e0e0',
                            borderRadius: '8px',
                            fontSize: '13px',
                            whiteSpace: 'pre-wrap',
                            overflowY: 'auto',
                            width: '300px',
                            maxHeight: '730px',
                            height: 'auto',
                            flexShrink: 0,
                            alignSelf: 'stretch',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                        }}>
                            <h3 className="font-bold text-lg border-b-1 border-gray-200 pb-2 mb-1">
                                Available Variables
                            </h3>
                            <p style={{ fontSize: '12px', color: '#666', marginBottom: '10px' }}>
                                Use these variables in your message by typing the variable name with double curly braces. For example: <code style={{ backgroundColor: '#f8f8f8', padding: '2px 4px', borderRadius: '3px' }}>{'{{FirstName}}'}</code>
                            </p>

                            {selectedPerson && (
                                <div>
                                    {Object.entries(selectedPerson.fields).map(([key, value]) => (
                                        <div key={key} style={{ marginBottom: '8px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <span style={{
                                                    fontWeight: 'bold',
                                                    color: '#333',
                                                }}>
                                                    {key}:
                                                </span>
                                                <span style={{
                                                    color: '#6b7280',
                                                    fontSize: '11px',
                                                    fontStyle: 'italic',
                                                    marginLeft: '8px'
                                                }}>
                                                    <code style={{ backgroundColor: '#f8f8f8', padding: '1px 3px', borderRadius: '3px' }}>{'{{'}{key}{'}}'}</code>
                                                </span> 
                                            </div>
                                            <span style={{
                                                color: '#4b5563',
                                                wordBreak: 'break-word',
                                                display: 'block',
                                                paddingLeft: '8px'
                                            }}>
                                                {String(value)}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {(!selectedPerson) && (
                                <div style={{ color: '#666', fontStyle: 'italic', marginTop: '10px' }}>
                                    <p>No referral data available to show example variables.</p>
                                    <p style={{ marginTop: '10px' }}>Common variables include:</p>
                                    <ul style={{ marginLeft: '15px', marginTop: '5px', listStyleType: 'disc' }}>
                                        <li>FirstName</li>
                                        <li>LastName</li>
                                        <li>Email</li>
                                        <li>Company</li>
                                        <li>ReferralLink</li>
                                    </ul>
                                </div>
                            )}

                            {/* User Variables Section */}
                            <div style={{ marginTop: '20px', borderTop: '1px solid #e0e0e0', paddingTop: '15px' }}>
                                <h4 style={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '10px' }}>Your Information</h4>
                                
                                {(useAlternateIdentity || userData["First Name"] || userData["Last Name"]) && <div style={{ marginBottom: '8px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <span style={{
                                            fontWeight: 'bold',
                                            color: '#333',
                                        }}>
                                            Your Name:
                                        </span>
                                        <span style={{
                                            color: '#6b7280',
                                            fontSize: '11px',
                                            fontStyle: 'italic',
                                            marginLeft: '8px'
                                        }}>
                                            <code style={{ backgroundColor: '#f8f8f8', padding: '1px 3px', borderRadius: '3px' }}>{'{{YourName}}'}</code>
                                        </span>
                                    </div>
                                    <span style={{
                                        color: '#4b5563',
                                        wordBreak: 'break-word',
                                        display: 'block',
                                        paddingLeft: '8px'
                                    }}>
                                        {useAlternateIdentity ? "Amy" : userData["First Name"] && userData["Last Name"] ? userData["First Name"] + " " + userData["Last Name"] : userData["First Name"] || userData["Last Name"] || ""}
                                    </span>
                                </div>}

                                <div style={{ marginBottom: '8px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <span style={{
                                            fontWeight: 'bold',
                                            color: '#333',
                                        }}>
                                            Referral Link:
                                        </span>
                                        <span style={{
                                            color: '#6b7280',
                                            fontSize: '11px',
                                            fontStyle: 'italic',
                                            marginLeft: '8px'
                                        }}>
                                            <code style={{ backgroundColor: '#f8f8f8', padding: '1px 3px', borderRadius: '3px' }}>{'{{ReferralLink}}'}</code>
                                        </span>
                                    </div>
                                    <span style={{
                                        color: '#4b5563',
                                        wordBreak: 'break-word',
                                        display: 'block',
                                        paddingLeft: '8px'
                                    }}>
                                        {`https://www.getupgraded.ca/${userData.ReferralParam}` || ""}
                                    </span>
                                </div>
                            </div>
                        </div>
                        
                        {/* Main content */}
                        <div style={{ width: '100%', maxWidth: '500px', margin: '0 auto' }}>
                            {/* Close button */}
                            <button 
                                className="close-modal-button" 
                                onClick={() => { 
                            setIsEditInviteVisible(false); 
                            setIsInviteAllVisible(false); 
                            setIsInviteOneVisible(false); 
                            setTempEmailSubject(""); 
                            setTempEmailBody(""); 
                            setEditInviteError(""); 
                            setSelectedPerson(null);
                        }}
                                style={{ position: 'absolute', top: '10px', right: '10px' }}
                            >
                                &times; {/* This is the "X" symbol */}
                            </button>

                        <h2 className="modal-heading font-bold text-xl border-b-1 border-gray-200 pb-1 mb-3">{isEditInviteVisible && ("Edit Invite Message") || isInviteAllVisible && ("Invite All Contacts") || isInviteOneVisible && ("Invite Contact")}</h2>

                        {/* Dropdown for selecting a person when inviting one */}
                        {isInviteOneVisible && (
                            <div className="form-group">
                                <label htmlFor="inviteOneContact">Select a Contact</label>
                                <select
                                    id="inviteOneContact"
                                    className="form-input"
                                    style={{ border: '1px solid #ddd', width: '100%' }}
                                    onChange={(e) => {
                                        const selectedPerson = displayedPeople.find(person => person.id === e.target.value);
                                        setSelectedPerson(selectedPerson);
                                        setInviteOneContact(selectedPerson); // Store the full object in state
                                    }}
                                >
                                    <option value="">-- Select a Contact --</option>
                                    {displayedPeople
                                        .filter(person => 
                                            (person.fields.UploadedBy === loginEmail || 
                                            person.fields.UploadedBy === "team@getupgraded.ca") &&
                                            invitePermissions[person.id] !== undefined
                                        )
                                        .sort((a, b) => {
                                            const countryA = (a.fields.Country || '').toLowerCase();
                                            const countryB = (b.fields.Country || '').toLowerCase();
                                            const isCanadaA = countryA === 'canada' || countryA === 'ca';
                                            const isCanadaB = countryB === 'canada' || countryB === 'ca';

                                            if (isCanadaA && !isCanadaB) return -1;
                                            if (!isCanadaA && isCanadaB) return 1;

                                            return countryA.localeCompare(countryB);
                                        })
                                        .map(person => (
                                            <option key={person.id} value={person.id}>
                                                {(
                                                    (person.fields.FirstName ? person.fields.FirstName : "") + 
                                                    " " + 
                                                    (person.fields.LastName ? person.fields.LastName : "")
                                                ).trim() || person.fields.Email || "Unnamed Contact"}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        )}
                            
                            {/* Only show templates in edit invite message */}
                            {isEditInviteVisible && (
                                <>
                                    <h3 style={{
                                        display: 'block',
                                        fontWeight: 'bold',
                                        color: '#555',
                                        fontSize: '14px'}}
                                    >
                                        Choose a Message Template:
                                    </h3>
                                    {/* Template Selection Boxes */}
                                    <div className="flex gap-2 mt-2 mb-4">
                                        {templates.map((template, index) => (
                                            <button
                                                key={index}
                                                onClick={() => {
                                                    setTempEmailSubject(template.subject);
                                                    setTempEmailBody(template.body);
                                                    setCurrentTemplateIndex(index);
                                                }}
                                                className={`px-2 py-1 border rounded-md text-xs font-medium transition ${
                                                    (currentTemplateIndex === index && templates[currentTemplateIndex].body === tempEmailBody && templates[currentTemplateIndex].subject === tempEmailSubject) 
                                                        ? 'border-indigo-600 text-indigo-600 font-semibold' 
                                                        : 'border-gray-300 text-gray-700 hover:border-gray-400'
                                                }`}
                                            >
                                                {template.name || `Template ${index + 1}`}
                                            </button>
                                        ))}
                                    </div>
                                </>
                            )}

                            <div className="form-group">
                                <label htmlFor="emailSubject">Email Subject</label>
                                <input
                                    type="text"
                                    id="emailSubject"
                                    value={tempEmailSubject}
                                    onChange={(e) => setTempEmailSubject(e.target.value)}
                                    className="form-input"
                                    style={{ 
                                        border: '1px solid #e0e0e0',
                                        borderRadius: '4px',
                                        padding: '8px 12px',
                                        width: '100%' 
                                    }}
                                />
                            </div>
                            
                            <div className="form-group">
                                <label htmlFor="emailBody">Email Body</label>
                                <textarea
                                    id="emailBody"
                                    value={tempEmailBody}
                                    onChange={(e) => setTempEmailBody(e.target.value)}
                                    className="form-textarea"
                                    rows={16}
                                    style={{ 
                                        border: '1px solid #e0e0e0',
                                        borderRadius: '4px',
                                        padding: '8px 12px',
                                        maxHeight: isEditInviteVisible ? '210px' : (isInviteOneVisible ? '195px' : '285px'),
                                        width: '100%',
                                        resize: 'none',
                                    }}
                                />
                                {/* Character Counter */}
                                <div 
                                    style={{
                                        marginTop: '4px',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        color: tempEmailBody.length > 600 ? 'red' : '#6b7280'
                                    }}
                                >
                                    {tempEmailBody.length} / 600
                                </div>
                            </div>
                            
                            {/* Template Navigation and Controls - All on one line */}
                            <div style={{ 
                                display: 'flex', 
                                justifyContent: 'space-between', 
                                alignItems: 'center', 
                                marginTop: '15px',
                                gap: '10px',
                                marginBottom: '10px'
                            }}>
                                {/* Plain Text Mode Toggle */}
                                <div className="flex items-center gap-2">
                                    <span className="relative group">
                                        <InformationCircleIcon className="w-4 h-4 text-gray-500 group-hover:text-gray-700 cursor-pointer" />
                                        {/* Tooltip */}
                                        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-1.5 bg-gray-800 text-white text-xs rounded shadow-lg z-10 max-w-[200px] w-max hidden group-hover:block break-words whitespace-normal">
                                            Send email as plain text. If toggled off, it will send as HTML.
                                            {/* Tooltip arrow */}
                                            <div className="absolute top-full left-1/2 transform -translate-x-1/2 border-4 border-transparent border-t-gray-800"></div>
                                        </div>
                                    </span>
                                    <label className="text-sm font-medium text-gray-900">Plain Text Mode</label>
                                    <button
                                        type="button"
                                        className={`${isPlainText ? 'bg-indigo-600' : 'bg-gray-200'} 
                                            relative ml-auto inline-flex h-4 w-8 flex-shrink-0 cursor-pointer rounded-full 
                                            border-2 border-transparent transition-colors duration-200 ease-in-out 
                                            focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                                        role="switch"
                                        onClick={() => setIsPlainText(prev => (prev ? null : "true"))}
                                    >
                                        <span
                                            className={`${isPlainText ? 'translate-x-4' : 'translate-x-0'}
                                            pointer-events-none inline-block h-3 w-3 transform rounded-full 
                                            bg-white shadow ring-0 transition duration-200 ease-in-out`}
                                        />
                                    </button>
                                </div>
                            </div>
                            
                            {/* Display error message if there is one */}
                            {editInviteError && <div className="error-message" style={{ color: 'red', paddingBottom: '10px' }}>{editInviteError}</div>}
                            
                            {isEditInviteVisible && (
                                <button className="save-button" onClick={handleSave}>
                                    Save Changes
                                </button>
                            )}

                            {isInviteAllVisible && (
                                <button className="save-button" onClick={async () => {
                                    inviteAllContacts(tempEmailBody, tempEmailSubject);
                                }}>
                                    Invite All {`(as ${
                                        useAlternateIdentity
                                        ? "Amy"
                                        : (userData?.ReplyToEmail ? (userData?.["First Name"] || userData?.["Last Name"] || (userData?.ReplyToEmail.split("@")[0] + "@getupgraded.ca")) : "Amy")
                                    })`}
                                </button>
                            )}

                            {isInviteOneVisible && (
                                <button className="save-button" onClick={async () => {
                                    if (tempEmailBody.trim() === "" && tempEmailSubject.trim() === "") {
                                        setEditInviteError('Please enter an email subject and message.');
                                        return;
                                    } else if (tempEmailSubject.trim() === "") {
                                        setEditInviteError('Please enter an email subject.');
                                        return;
                                    } else if (tempEmailBody.trim() === "") {
                                        setEditInviteError('Please enter an email message.');
                                        return;
                                    } else if (tempEmailBody.length > 600) {
                                        setEditInviteError('Your message is too long.');
                                        return;
                                    } else if (!inviteOneContact) {
                                        setEditInviteError('Please select a contact to invite.');
                                        return;
                                    } else if (inviteOneContact && !(selectedPerson?.fields?.Email)) {
                                        setEditInviteError('No email is associated with this user. Use their "Email" button in your network table to invite them.');
                                        return;
                                    }
                                    await updateTempInviteMessage(tempEmailBody, tempEmailSubject, inviteOneContact).then(() => {
                                        addNewPeople(inviteOneContact);
                                    });
                                }}>
                                    Send Invite {`(as ${
                                        useAlternateIdentity
                                        ? "Amy"
                                        : (userData?.ReplyToEmail ? (userData?.["First Name"] || userData?.["Last Name"] || (userData?.ReplyToEmail.split("@")[0] + "@getupgraded.ca")) : "Amy")
                                    })`}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}


            {/* Anon tooltip */}
            {showInfoBox && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2 className="text-2xl font-bold mb-4">How to Refer Anonymously</h2>
                        <ol className="list-decimal list-inside space-y-2">
                            <li>
                                <strong>Edit Personal Information:</strong>
                                <ul className="list-disc list-inside ml-5">
                                    <li>
                                        Navigate to{" "}
                                        <a
                                            href="https://app.getupgraded.ca/settings"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-500 hover:underline"
                                        >
                                            Settings
                                        </a>{" "}
                                        and scroll to the "Personal Information" section.
                                    </li>
                                    <li>
                                        Ensure the "First name", "Last name", and{" "}
                                        "Reply To Email" fields are empty.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Send Anonymous Referrals:</strong>
                                <ul className="list-disc list-inside ml-5">
                                    <li>
                                        Once these fields are cleared, your invite message will be sent to your referrals
                                        without displaying your name or email address.
                                    </li>
                                </ul>
                            </li>
                        </ol>
                        <div className="mt-4 flex justify-between">
                            <button
                                onClick={() => {
                                    setShowInfoBox(false); // Close Anon Guide
                                    setShowReferralGuideBox(true); // Open Referral Guide
                                }}
                                className="bg-gray-900 text-white px-4 py-2 rounded hover:bg-gray-800 shadow-lg"
                            >
                                Back to Referral Guide
                            </button>
                            <button
                                onClick={() => setShowInfoBox(false)}
                                className="bg-gray-900 text-white px-4 py-2 rounded hover:bg-gray-800 shadow-lg"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <LinkedInExportModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
            />

            {/* Edit Modal */}
            {editModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <button
                            onClick={handleCancelEdit}
                            className="absolute top-3 right-3 text-gray-400 hover:text-gray-500"
                            aria-label="Close"
                        >
                            <XMarkIcon className="h-5 w-5" />
                        </button>

                        <h2 className="text-l font-semibold text-gray-900 mb-3">Edit Contact</h2>

                        <div className="space-y-2">
                            <div>
                                <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    id="firstName"
                                    value={editingPerson?.fields.FirstName || ''}
                                    onChange={(e) => setEditingPerson(prev => ({
                                        ...prev,
                                        fields: { ...prev.fields, FirstName: e.target.value }
                                    }))}
                                    className="w-full px-3 py-1.5 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500"
                                />
                            </div>

                            <div>
                                <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    id="lastName"
                                    value={editingPerson?.fields.LastName || ''}
                                    onChange={(e) => setEditingPerson(prev => ({
                                        ...prev,
                                        fields: { ...prev.fields, LastName: e.target.value }
                                    }))}
                                    className="w-full px-3 py-1.5 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500"
                                />
                            </div>

                            <div>
                                <label htmlFor="company" className="block text-sm font-medium text-gray-700 mb-1">
                                    Company Name
                                </label>
                                <input
                                    type="text"
                                    id="company"
                                    value={editingPerson?.fields.Company || ''}
                                    onChange={(e) => setEditingPerson(prev => ({
                                        ...prev,
                                        fields: { ...prev.fields, Company: e.target.value }
                                    }))}
                                    className="w-full px-3 py-1.5 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500"
                                />
                            </div>

                            <div>
                                <label htmlFor="headcount" className="block text-sm font-medium text-gray-700 mb-1">
                                    Company Headcount
                                </label>
                                <input
                                    type="text"
                                    id="headcount"
                                    value={editingPerson?.fields.Headcount || ''}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*$/.test(value)) { // Ensures only digits (0-9) are allowed
                                            setEditingPerson(prev => ({
                                                ...prev,
                                                fields: {
                                                    ...prev.fields,
                                                    Headcount: ((value + '') === '0' || (value + '').trim() === '') ? null : Number(value)
                                                }
                                            }));
                                        }
                                    }}
                                    inputMode="numeric" // Optimizes mobile keyboard for numbers
                                    className="w-full px-3 py-1.5 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500"
                                />
                            </div>

                            <div>
                                <label htmlFor="position" className="block text-sm font-medium text-gray-700 mb-1">
                                    Position
                                </label>
                                <input
                                    type="text"
                                    id="position"
                                    value={editingPerson?.fields.Position || ''}
                                    onChange={(e) => setEditingPerson(prev => ({
                                        ...prev,
                                        fields: { ...prev.fields, Position: e.target.value }
                                    }))}
                                    className="w-full px-3 py-1.5 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500"
                                />
                            </div>

                            <div>
                                <label htmlFor="location" className="block text-sm font-medium text-gray-700 mb-1">
                                    Location
                                </label>
                                <input
                                    type="text"
                                    id="location"
                                    value={editingPerson?.fields.City || ''}
                                    onChange={(e) => setEditingPerson(prev => ({
                                        ...prev,
                                        fields: { ...prev.fields, City: e.target.value }
                                    }))}
                                    className="w-full px-3 py-1.5 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500"
                                />
                            </div>

                            <div>
                                <label htmlFor="website" className="block text-sm font-medium text-gray-700 mb-1">
                                    Website
                                </label>
                                <input
                                    type="text"
                                    id="website"
                                    value={editingPerson?.fields.Website || ''}
                                    onChange={(e) => setEditingPerson(prev => ({
                                        ...prev,
                                        fields: { ...prev.fields, Website: e.target.value }
                                    }))}
                                    className="w-full px-3 py-1.5 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500"
                                />
                            </div>

                            {/* Add Email field */}
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    value={editingPerson?.fields.Email || ''}
                                    onChange={(e) => setEditingPerson(prev => ({
                                        ...prev,
                                        fields: { ...prev.fields, Email: e.target.value }
                                    }))}
                                    className="w-full px-3 py-1.5 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500"
                                />
                            </div>

                            <div className="pt-4">
                                <button
                                    onClick={handleSaveEdit}
                                    className="w-full px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                >
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

MyNetwork.defaultProps = {
    admin: false,
};
