import { Fragment, useRef, useEffect, useState } from "react";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { currencyFormat, percentageFormat } from "../helpers";

// Add field type definitions
const FIELD_TYPES = {
  Name: "string",
  Domain: "string",
  Industry: "string",
  Address: "string",
  ListSize: "number",
  AnnualRevenue: "number",
  OperationalCosts: "number",
  Debt: "number",
  ConversionRate: "number", // Decimal between 0-1
  FollowerCount: "number",
  AvgDealSize: "number",
  Technologies: "string",
  VisitToLeadConversionRate: "number",
  LeadToDealConversion: "number",
  IncreasedAnnualTraffic: "number",
};

// Define the mappings at the top of the file
const FILTER_MAPPINGS = {
  customerFocus: {
    "B2B": "B2B",
    "B2C": "B2C",
  },
  industries: {
    "SaaS": "SaaS",
    "Software Dev": "Software Development",
    "Digital Agency": "Digital Agency",
    "E-commerce": "E-commerce",
    "FinTech": "FinTech",
    "Healthcare Tech": "Healthcare Tech",
    "EdTech": "EdTech",
    "Legal": "Legal",
    "Real Estate": "Real Estate",
    "Events": "Events",
    "Other": "Other"
  },
  sizes: {
    "1 - 10": "1 - 10 employees",
    "11 - 50": "11 - 50 employees",
    "51 - 200": "51 - 200 employees",
    "201 - 500": "201 - 500 employees"
  },
  stages: {
    "Validating Idea": "Validating Idea",
    "MVP": "MVP",
    "Growth": "Growth",
    "Scale": "Scale"
  }
};

const FilterButton = ({
  isOpen,
  setIsOpen,
  companyData,
  onUpdateField,
  filters,
  setFilters,
}) => {
  const [editingField, setEditingField] = useState(null);

  const handleSave = (fieldName, value) => {
    // Format value based on field type before saving
    const fieldType = FIELD_TYPES[fieldName];
    let formattedValue = value;

    if (fieldType === "number") {
      // Remove any non-numeric characters except decimal point
      const numericValue = value.replace(/[^0-9.]/g, "");
      formattedValue = numericValue === "" ? 0 : Number(numericValue);

      // Validate number
      if (isNaN(formattedValue)) {
        alert("Please enter a valid number");
        return;
      }
    }

    onUpdateField(fieldName, formattedValue);
    setEditingField(null);
  };

  const handleFilterChange = (type, displayValue, isChecked) => {
    const airtableValue = FILTER_MAPPINGS[type][displayValue];
    setFilters((prev) => ({
      ...prev,
      [type]: isChecked
        ? [...prev[type], airtableValue]
        : prev[type].filter((item) => item !== airtableValue),
    }));
  };

  const EditableField = ({ label, value, fieldName }) => {
    const [editValue, setEditValue] = useState(value?.toString() || "");
    const fieldType = FIELD_TYPES[fieldName];

    const handleInputChange = (e) => {
      let newValue = e.target.value;

      if (fieldType === "number") {
        // Allow only numbers and decimal point while typing
        newValue = newValue.replace(/[^0-9.]/g, "");
      }

      setEditValue(newValue);
    };

    if (editingField === fieldName) {
      return (
        <div>
          <div className="text-xs text-gray-500">{label}</div>
          <div className="flex gap-2">
            <input
              type="text"
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
              className="text-sm border rounded px-2 py-1 flex-1"
            />
            <button
              onClick={() => handleSave(fieldName, editValue)}
              className="text-xs text-blue-600 hover:text-blue-800"
            >
              Save
            </button>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="text-xs text-gray-500">{label}</div>
        <div className="flex justify-between items-center">
          <div className="text-sm text-gray-700">
            {value || "Not specified"}
          </div>
          <button
            onClick={() => setEditingField(fieldName)}
            className="text-xs text-gray-400 hover:text-blue-600"
          >
            Edit
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="relative z-50">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="p-2 rounded-full shadow-lg hover:bg-gray-50 border border-gray-200"
        >
          <FunnelIcon className="h-6 w-6 text-gray-600" />
        </button>
      </div>

      <div className="fixed -top-[152px] -right-[136px] mt-6 z-50">
        {/* Dropdown Content */}
        {isOpen && (
          <div className="absolute right-0 mt-2 w-[134px] bg-white rounded-md shadow-lg border border-gray-200 text-left">
            <div className="py-2">
              {/* Filter Header */}
              <div className="px-4 py-2 border-b border-gray-100">
                <h2 className="text-sm font-medium text-gray-900">
                  Best For Filters
                </h2>
                <p className="text-xs text-gray-500 mt-1">
                  Upgrades usually match multiple company types
                </p>
              </div>

              {/* Customer Focus Filter */}
              <div className="px-4 py-2">
                <h3 className="text-sm font-medium text-gray-900 mb-2">
                  Customer Focus
                </h3>
                <div className="space-y-2">
                  {Object.entries(FILTER_MAPPINGS.customerFocus).map(([display, value]) => (
                    <label key={value} className="flex items-center">
                      <input
                        type="checkbox"
                        checked={filters.customerFocus.includes(value)}
                        onChange={(e) =>
                          handleFilterChange(
                            "customerFocus",
                            display,
                            e.target.checked
                          )
                        }
                        className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      <span className="ml-1 text-xs text-gray-700">{display}</span>
                    </label>
                  ))}
                </div>
              </div>

              {/* Industry Filter */}
              <div className="px-4 py-2 border-t border-gray-100">
                <h3 className="text-sm font-medium text-gray-900 mb-2">
                  Industry
                </h3>
                <div className="space-y-2">
                  {Object.entries(FILTER_MAPPINGS.industries).map(([display, value]) => (
                    <label key={value} className="flex items-center">
                      <input
                        type="checkbox"
                        checked={filters.industries.includes(value)}
                        onChange={(e) =>
                          handleFilterChange("industries", display, e.target.checked)
                        }
                        className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      <span className="ml-1 text-xs text-gray-700">{display}</span>
                    </label>
                  ))}
                </div>
              </div>

              {/* Company Size Filter */}
              <div className="px-4 py-2 border-t border-gray-100">
                <h3 className="text-sm font-medium text-gray-900 mb-2">Company Size</h3>
                <div className="space-y-2">
                  {Object.entries(FILTER_MAPPINGS.sizes).map(([display, value]) => (
                    <label key={value} className="flex items-center">
                      <input
                        type="checkbox"
                        checked={filters.sizes.includes(value)}
                        onChange={(e) =>
                          handleFilterChange("sizes", display, e.target.checked)
                        }
                        className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      <span className="ml-1 text-xs text-gray-700">{display}</span>
                    </label>
                  ))}
                </div>
              </div>

              {/* Company Stage Filter */}
              <div className="px-4 py-2 border-t border-gray-100">
                <h3 className="text-sm font-medium text-gray-900 mb-2">Company Stage</h3>
                <div className="space-y-2">
                  {Object.entries(FILTER_MAPPINGS.stages).map(([display, value]) => (
                    <label key={value} className="flex items-center">
                      <input
                        type="checkbox"
                        checked={filters.stages.includes(value)}
                        onChange={(e) =>
                          handleFilterChange("stages", display, e.target.checked)
                        }
                        className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      <span className="ml-1 text-xs text-gray-700">{display}</span>
                    </label>
                  ))}
                </div>
              </div>

              {/* Debug Company Data */}
              <div className="px-4 py-2">
                <h3 className="text-sm font-medium text-gray-900 mb-2">
                  Company Data
                </h3>
                {/* Important Company Data Fields */}
                {/* 
            - Company Name
            - Industry
            - Company Size
            - Company Stage
            - Company Location
            */}
                <div className="space-y-3">
                  <EditableField
                    label="Company Name"
                    value={companyData?.fields?.Name}
                    fieldName="Name"
                  />
                  <EditableField
                    label="Company Website"
                    value={companyData?.fields?.Domain}
                    fieldName="Domain"
                  />
                  <EditableField
                    label="Industry"
                    value={companyData?.fields?.Industry}
                    fieldName="Industry"
                  />
                  <EditableField
                    label="Location"
                    value={companyData?.fields?.Address}
                    fieldName="Address"
                  />
                  {/* List Size */}
                  <EditableField
                    label="Email List Size"
                    value={companyData?.fields?.ListSize}
                    fieldName="ListSize"
                  />
                  {/* Revenue */}
                  <EditableField
                    label="Annual Revenue"
                    value={currencyFormat(companyData?.fields?.AnnualRevenue)}
                    fieldName="AnnualRevenue"
                  />
                  {/* Operational Costs */}
                  <EditableField
                    label="Operational Costs"
                    value={currencyFormat(
                      companyData?.fields?.OperationalCosts
                    )}
                    fieldName="OperationalCosts"
                  />
                  {/* Profit */}
                  <EditableField
                    label="Profit"
                    value={currencyFormat(
                      companyData?.fields?.AnnualRevenue -
                        companyData?.fields?.OperationalCosts
                    )}
                    fieldName="AnnualRevenue"
                  />
                  {/* Debt */}
                  <EditableField
                    label="Debt"
                    value={currencyFormat(companyData?.fields?.Debt)}
                    fieldName="Debt"
                  />
                  {/* Conversion Rate */}
                  <EditableField
                    label="Visitor to Deal Conversion Rate"
                    value={percentageFormat(
                      companyData?.fields?.ConversionRate
                    )}
                    fieldName="ConversionRate"
                  />
                  {/* FollowerCount */}
                  <EditableField
                    label="Follower Count"
                    value={companyData?.fields?.FollowerCount}
                    fieldName="FollowerCount"
                  />
                  {/* AvgDealSize */}
                  <EditableField
                    label="Avg Deal Size"
                    value={currencyFormat(companyData?.fields?.AvgDealSize)}
                    fieldName="AvgDealSize"
                  />
                  {/* Visit to Lead Conversion */}
                  <EditableField
                    label="Visit to Lead Conversion"
                    value={percentageFormat(companyData?.fields?.VisitToLeadConversionRate)}
                    fieldName="VisitToLeadConversionRate"
                  />
                  {/* Lead to Deal Conversion */}
                  <EditableField
                    label="Lead to Deal Conversion"
                    value={percentageFormat(companyData?.fields?.LeadToDealConversionRate)}
                    fieldName="LeadToDealConversionRate"
                  />
                  {/* Increased Annual Traffic */}
                  <EditableField
                    label="Increased Annual Traffic"
                    value={companyData?.fields?.IncreasedAnnualTraffic}
                    fieldName="IncreasedAnnualTraffic"
                  />
                  {/* Technologies */}
                  <EditableField
                    label="Technologies"
                    value={companyData?.fields?.Technologies}
                    fieldName="Technologies"
                  />
                </div>
                {/* Debug Company Data */}
                <div className="py-2">
                  <h3 className="text-sm font-medium text-gray-900 mb-2">
                    Debug
                  </h3>
                  <pre className="text-sm text-gray-700">
                    {JSON.stringify(companyData, null, 2)}
                  </pre>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FilterButton;
