export const automationSchema = {
  fields: {
    Title: {
      type: 'string',
      required: true,
      maxLength: 100,
      examples: [
        "Email Response Automation",
        "Invoice Processing Automation",
        "Calendar Scheduling Assistant"
      ]
    },
    Type: {
      type: 'string',
      required: true,
      examples: [
        "automation",
        "template"
      ]
    },
    Stage: {
      type: 'string',
      required: true,
      enum: ['ready', 'coming_soon', 'requested'],
      examples: ['ready']
    },
    Tools: {
      type: 'array',
      required: true,
      minItems: 1,
      examples: [
        [
          "Zapier",
          "Make.com",
          "OpenAI GPT-4",
          "Gmail API",
          "Microsoft Power Automate"
        ]
      ]
    },
    ExampleOutputs: {
      type: 'array',
      required: false,
      minItems: 3,
      maxItems: 3,
      examples: [
        [
          "https://storage.googleapis.com/automation-images/dashboard.png",
          "https://storage.googleapis.com/automation-images/workflow.png",
          "https://storage.googleapis.com/automation-images/output.png"
        ]
      ]
    },
    BestFor: {
      type: 'array',
      required: true,
      minItems: 1,
      maxItems: 4,
      examples: [
        [
          "Small business owners handling 50+ emails daily",
          "Customer service teams with repetitive inquiries",
          "Sales teams needing quick lead response"
        ]
      ]
    },
    NotGoodFor: {
      type: 'array',
      required: true,
      minItems: 1,
      maxItems: 3,
      examples: [
        [
          "Organizations requiring complex decision-making in every email",
          "Teams with minimal email volume (less than 10 per day)"
        ]
      ]
    },
    HeartedBy: {
      type: 'array',
      required: false,
      examples: [
        ["user_1", "user_2"]
      ]
    },
    StripeLink: {
      type: 'string',
      required: false,
      examples: [
        "https://buy.stripe.com/example"
      ]
    },
    TurnaroundDays: {
      type: 'number',
      required: true,
      min: 0,
      max: 30,
      examples: [5, 7, 10]
    },
    SetupPrice: {
      type: 'number',
      required: true,
      min: 0,
      examples: [999, 1499, 1999]
    },
    MonthlyPrice: {
      type: 'number',
      required: true,
      min: 0,
      examples: [99, 199, 299]
    },
    AdditionalCosts: {
      type: 'string',
      required: false,
      examples: [
        "API usage fees may apply",
        "Third-party tool subscriptions required"
      ]
    },
    Benefits: {
      type: 'array',
      required: true,
      minItems: 1,
      examples: [
        [
          "Saves 15-20 hours per week on email management",
          "Improves response time from 24 hours to 1 hour",
          "Reduces errors in communication by 90%",
          "Maintains consistent brand voice across all emails"
        ]
      ]
    },
    IncludedFeatures: {
      type: 'array',
      required: true,
      minItems: 1,
      examples: [
        [
          "Customized email response templates",
          "AI-powered response generation system",
          "Email categorization workflow",
          "Priority inbox management system"
        ]
      ]
    },
    BestPractices: {
      type: 'array',
      required: false,
      examples: [
        [
          "Review AI responses before sending",
          "Update templates monthly",
          "Monitor performance metrics weekly"
        ]
      ]
    },
    requirements: {
      type: 'array',
      required: false,
      examples: [
        [
          "Gmail or Outlook business account",
          "List of common email scenarios and responses",
          "Access to existing email templates",
          "30 minutes for weekly maintenance"
        ]
      ]
    },
    WorkflowDetails: {
      type: 'string',
      required: false,
      maxLength: 500,
      examples: [
        "1. Email received\n2. AI categorizes content\n3. Template selected\n4. Response generated\n5. Human review\n6. Send"
      ]
    },
    AutomationRecipes: {
      type: 'array',
      required: false,
      examples: [
        [
          "Email Classification Zap",
          "Response Generation Flow",
          "Follow-up Scheduler"
        ]
      ]
    },
    Deliverables: {
      type: 'array',
      required: false,
      examples: [
        [
          "Setup guide documentation",
          "Video walkthrough",
          "Custom templates",
          "Integration configs"
        ]
      ]
    },
    SupportDetails: {
      type: 'string',
      required: false,
      examples: [
        "Email support within 24 hours\nWeekly check-ins for first month\nQuarterly optimization reviews"
      ]
    },
    Integrations: {
      type: 'array',
      required: false,
      examples: [
        [
          "Gmail",
          "Outlook",
          "Slack",
          "HubSpot"
        ]
      ]
    },
    ComplianceItems: {
      type: 'array',
      required: false,
      examples: [
        [
          "GDPR compliant",
          "SOC 2 certified",
          "HIPAA ready"
        ]
      ]
    }
  }
};

// Add this constant at the top of the file
export const EXAMPLE_IMAGE_SETS = {
  default: [
    "https://miro.medium.com/v2/resize:fit:720/format:webp/1*HjQfsq7S61V17ZpHzra3VQ.png",
    "https://images.ctfassets.net/az3stxsro5h5/V5dz4Po6bHT9IzUGJvSFY/2998177da281744206492fb2105b73b2/https___later.com_blog_wp-content_uploads_2018_11_4-2-IGanalytics-postperformance-metrics-updated-1024x683.gif",
    "https://s3.us-west-2.amazonaws.com/public.notion-static.com/template/1943e958-5c25-4bf4-97cc-767b436f5d31/1719399498913/desktop.jpg"
  ],
  email: [
    "https://miro.medium.com/v2/resize:fit:720/format:webp/1*HjQfsq7S61V17ZpHzra3VQ.png",
    "https://images.ctfassets.net/az3stxsro5h5/V5dz4Po6bHT9IzUGJvSFY/2998177da281744206492fb2105b73b2/https___later.com_blog_wp-content_uploads_2018_11_4-2-IGanalytics-postperformance-metrics-updated-1024x683.gif",
    "https://s3.us-west-2.amazonaws.com/public.notion-static.com/template/1943e958-5c25-4bf4-97cc-767b436f5d31/1719399498913/desktop.jpg"
  ],
  analytics: [
    "https://miro.medium.com/v2/resize:fit:720/format:webp/1*HjQfsq7S61V17ZpHzra3VQ.png",
    "https://images.ctfassets.net/az3stxsro5h5/V5dz4Po6bHT9IzUGJvSFY/2998177da281744206492fb2105b73b2/https___later.com_blog_wp-content_uploads_2018_11_4-2-IGanalytics-postperformance-metrics-updated-1024x683.gif",
    "https://s3.us-west-2.amazonaws.com/public.notion-static.com/template/1943e958-5c25-4bf4-97cc-767b436f5d31/1719399498913/desktop.jpg"
  ],
  social: [
    "https://miro.medium.com/v2/resize:fit:720/format:webp/1*HjQfsq7S61V17ZpHzra3VQ.png",
    "https://images.ctfassets.net/az3stxsro5h5/V5dz4Po6bHT9IzUGJvSFY/2998177da281744206492fb2105b73b2/https___later.com_blog_wp-content_uploads_2018_11_4-2-IGanalytics-postperformance-metrics-updated-1024x683.gif",
    "https://s3.us-west-2.amazonaws.com/public.notion-static.com/template/1943e958-5c25-4bf4-97cc-767b436f5d31/1719399498913/desktop.jpg"
  ]
};

// Update the FIELD_SPECIFIC_PROMPTS object
const FIELD_SPECIFIC_PROMPTS = {
  BestFor: (title) => `Generate 5 specific types of users or scenarios that would benefit most from "${title}". Focus on:
- Company sizes/types
- Role-specific benefits
- Industry-specific applications
- Specific pain points solved
- Quantifiable benefits where possible`,

  NotGoodFor: (title) => `Generate 3 specific scenarios where "${title}" would not be the best solution. Consider:
- Company size limitations
- Technical constraints
- Budget considerations
- Use case mismatches`,

  NotGoodFor: (title) => `For "${title}", list 2 types of customers who should NOT use this solution. Example format:
- Small audience
- Not proven product

Keep each point under 5 words. Be direct and clear.`,

  Benefits: (title) => `List 4-6 key quantifiable benefits of "${title}" in short bullet points. 
  Including: Cost reductions, time savings, revenue increases, etc. Use specific numbers where possible. 
  Example format:
- Save 20+ hours per week on social media
- Increase engagement rates by 40%
- Improve brand awareness by 55%

Each benefit should:
- Start with an action verb
- Include a specific metric where possible
- Be under 10 words`,

  IncludedFeatures: (title) => `List what's included with "${title}" in short bullet points. Example format:
- Social media strategy document
- 30-day content calendar
- Weekly performance reports

Keep each item:
- Under 5 words
- Focused on deliverables
- Clear and concrete`,

  BestPractices: (title) => `List 3 best practices for "${title}" in short bullet points. Example format:
- Review analytics weekly
- Update content calendar monthly
- Test different post times

Keep each practice:
- Under 6 words
- Action-oriented
- Specific and practical`
};

// Update the existing generateFieldPrompt function
export const generateFieldPrompt = (fieldName, currentTitle) => {
  // Check for specific prompt first
  if (FIELD_SPECIFIC_PROMPTS[fieldName]) {
    return FIELD_SPECIFIC_PROMPTS[fieldName](currentTitle);
  }

  // Special handling for ExampleOutputs
  if (fieldName === 'ExampleOutputs') {
    return generateExampleOutputsPrompt(currentTitle);
  }

  // Fall back to default prompt generation using schema
  const field = automationSchema.fields[fieldName];
  if (!field) return '';

  // Format examples based on field type
  let examplesStr = '';
  if (field.type === 'array') {
    // For arrays, show both the structure and formatted example
    examplesStr = `Structure: ${JSON.stringify(field.examples[0])}
Format each item as:
${field.examples[0].map(item => `- ${item}`).join('\n')}`;
  } else if (field.type === 'string' && field.examples[0].includes('\n')) {
    // For multiline strings, preserve formatting
    examplesStr = `Format:\n${field.examples[0]}`;
  } else {
    // For simple types, show raw example
    examplesStr = field.examples[0];
  }

  const prompt = `
    For the automation "${currentTitle}", please generate a ${fieldName}:

    Example format:
    ${examplesStr}

    Requirements:
    - Type: ${field.type}${field.required ? ' (Required)' : ' (Optional)'}
    ${field.type === 'array' ? `- Items: ${field.minItems || 1}${field.maxItems ? ` to ${field.maxItems}` : '+ items'}` : ''}
    ${field.maxLength ? `- Maximum length: ${field.maxLength} characters` : ''}
    ${field.enum ? `- Valid values: ${field.enum.join(', ')}` : ''}
    ${field.min !== undefined ? `- Minimum value: ${field.min}` : ''}
    ${field.max !== undefined ? `- Maximum value: ${field.max}` : ''}

    Note: For Airtable compatibility, ${field.type === 'array' ? 'provide items as a bulleted list, one per line' : 'provide a single value without special formatting'}.
  `.trim();

  return prompt;
};

// Helper function to parse string to array if needed
const parseArrayField = (value) => {
  if (Array.isArray(value)) return value;
  if (typeof value === 'string') {
    // Handle JSON string arrays
    try {
      const parsed = JSON.parse(value);
      return Array.isArray(parsed) ? parsed : [value];
    } catch (e) {
      // If not JSON, split by newlines or commas
      return value
        .split(/[\n,]/)
        .map(item => item.trim())
        .filter(item => item.length > 0);
    }
  }
  return [value];
};

// Updated validation function
export const validateField = (fieldName, value) => {
  const field = automationSchema.fields[fieldName];
  if (!field) return { valid: false, error: 'Field does not exist in schema' };

  const validation = {
    valid: true,
    errors: [],
    parsedValue: value // Add this to return the properly formatted value
  };

  // Special handling for ExampleOutputs
  if (fieldName === 'ExampleOutputs') {
    return validateExampleOutputs(value);
  }

  // Handle array fields more flexibly
  if (field.type === 'array') {
    try {
      const parsedArray = parseArrayField(value);
      validation.parsedValue = parsedArray; // Store the parsed array

      // Validate array requirements
      if (field.minItems && parsedArray.length < field.minItems) {
        validation.errors.push(`Must have at least ${field.minItems} items`);
      }
      if (field.maxItems && parsedArray.length > field.maxItems) {
        validation.errors.push(`Must have no more than ${field.maxItems} items`);
      }
    } catch (error) {
      validation.errors.push('Invalid array format');
    }
  } else if (field.type === 'number') {
    // Handle number fields
    const num = Number(value);
    if (isNaN(num)) {
      validation.errors.push('Must be a number');
    } else {
      validation.parsedValue = num;
      if (field.min !== undefined && num < field.min) {
        validation.errors.push(`Must be at least ${field.min}`);
      }
      if (field.max !== undefined && num > field.max) {
        validation.errors.push(`Must be no more than ${field.max}`);
      }
    }
  } else if (field.type === 'string') {
    // Handle string fields
    const str = String(value);
    validation.parsedValue = str;
    if (field.maxLength && str.length > field.maxLength) {
      validation.errors.push(`Must be under ${field.maxLength} characters`);
    }
    if (field.enum && !field.enum.includes(str)) {
      validation.errors.push(`Must be one of: ${field.enum.join(', ')}`);
    }
  }

  validation.valid = validation.errors.length === 0;
  return validation;
};

// Helper function to format value for Airtable
export const formatForAirtable = (fieldName, value) => {
  const field = automationSchema.fields[fieldName];
  if (!field) return value;

  if (fieldName === 'ExampleOutputs') {
    const validation = validateExampleOutputs(value);
    return validation.valid ? JSON.stringify(validation.parsedValue) : '[]';
  }

  if (field.type === 'array') {
    const parsedArray = parseArrayField(value);
    return JSON.stringify(parsedArray);
  }
  
  return value;
};

// Update the generateExampleOutputsPrompt function
export const generateExampleOutputsPrompt = (currentTitle) => {
  // Determine which image set to use based on the title
  const titleLower = currentTitle.toLowerCase();
  let imageSet = EXAMPLE_IMAGE_SETS.default;
  
  if (titleLower.includes('email')) {
    imageSet = EXAMPLE_IMAGE_SETS.email;
  } else if (titleLower.includes('analytics') || titleLower.includes('report')) {
    imageSet = EXAMPLE_IMAGE_SETS.analytics;
  } else if (titleLower.includes('social') || titleLower.includes('post')) {
    imageSet = EXAMPLE_IMAGE_SETS.social;
  }

  return imageSet;
};

// Update the validateExampleOutputs function to be more lenient with Unsplash URLs
const validateExampleOutputs = (outputs) => {
  const validation = {
    valid: true,
    errors: [],
    parsedValue: outputs
  };

  try {
    const parsedOutputs = typeof outputs === 'string' ? JSON.parse(outputs) : outputs;
    
    if (!Array.isArray(parsedOutputs)) {
      validation.errors.push('Must be an array of URLs');
      validation.valid = false;
      return validation;
    }

    // Less strict URL validation to allow Unsplash URLs
    const cleanedUrls = parsedOutputs
      .filter(url => typeof url === 'string')
      .filter(url => url.startsWith('https://'))
      .slice(0, 3);

    if (cleanedUrls.length !== 3) {
      validation.errors.push('Must have exactly 3 valid image URLs');
      validation.valid = false;
    }

    validation.parsedValue = cleanedUrls;
  } catch (error) {
    validation.errors.push('Invalid format');
    validation.valid = false;
  }

  return validation;
}; 