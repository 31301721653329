import { React, useState, useEffect, useContext, useCallback } from "react";
import Airtable from "airtable";
import axios from 'axios';
import Upgrade from "./Upgrade.js";
import { Skeleton } from "@mui/material";
import Select from "react-select";
import { regionCodes } from "./helperData.js";
import { compareDates, extractDomain } from "./helpers.js";
import { useUser } from "@clerk/clerk-react";
import useBase from "./useBase.js";
import Notification from "./Notification.js";
import { setShowNotif, setContents, } from "./helpers";
import { AppContext } from "./AppContext.js";

export default function AllGrants({
	// grants,
	// setGrants,
	// grantWriters,
	// setGrantWriters,
	// industries,
	// setIndustries,
	// submitGrantNotif,
	// setSubmitGrantNotif,
	// errorGrantNotif,
	// setErrorGrantNotif
}) {
	const [grantWriters, setGrantWriters] = useState([]);
	const [industries, setIndustries] = useState({});
	const { grants, setGrants, grantLoading, setGrantLoading } = useContext(AppContext);
	useBase("deduped_grants", setGrants, grants);
	const { loading: loadingGrantWriters, error: errorGrantWriters } = useBase("grant_writers_profiles", setGrantWriters);
	const apiKey = process.env.REACT_APP_AIR_KEY;
	const baseId = process.env.REACT_APP_AIR_BASEID;
	const grantTableId = process.env.REACT_APP_AIR_GRANTS;
	const base = new Airtable({ apiKey }).base(baseId);

	// const regionOptions = [
	// 	{ value: "Canada", label: "Canada" },
	// 	{ value: "United States", label: "United States" },
	// 	{ value: "All", label: "All" },
	// ];

	const sortOptions = [
		{ value: "Alphabetically", label: "Alphabetically" },
		{ value: "By Deadline", label: "By Deadline" },
		{ value: "By Amount (Ascending)", label: "By Amount (Ascending)" },
		{ value: "By Amount (Descending)", label: "By Amount (Descending)" },
		{ value: "Sort By Newest", label: "Sort By Newest" },
		{ value: "Sort By Oldest", label: "Sort By Oldest" },
	];

	const minimumGrantsToDisplay = 50;

	// Setting Admin State
	const { isSignedIn, isLoaded: isUsersLoading, user } = useUser();
	const [admin, setAdmin] = useState(false);
	useEffect(() => {
		if (!isSignedIn || !isUsersLoading) return;
		const userDomain = extractDomain(user.primaryEmailAddress.emailAddress);

		const isAdmin = userDomain === process.env.REACT_APP_COOL_EMAIL;
		setAdmin(isAdmin);
	}, [isSignedIn, isUsersLoading, user]);

	const [currentMax, setCurrentMax] = useState(minimumGrantsToDisplay);
	// const [grants, setGrants] = useState([]);
	// const [industries, setIndustries] = useState({});
	// const [grantWriters, setGrantWriters] = useState([]);
	
	const [region, setRegion] = useState("Canada");
	const [sortChoice, setSortChoice] = useState("Alphabetically");
	const [numGrants, setNumGrants] = useState(0);
	const [showScraperInfo, setShowScraperInfo] = useState(false);
	const [showMergerInfo, setShowMergerInfo] = useState(false);
	const [showConfirmDialog, setShowConfirmDialog] = useState({ show: false, message: "" });
	const [confirmation, setConfirmation] = useState("");
	const [scraperStatus, setScraperStatus] = useState("");
	
	const [searchValue, setSearchValue] = useState("");
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [searchFilters, setSearchFilters] = useState({
	  searchIn: '',
	  region: '',
	  tag: '',
	  deadline: '',
	});
	const [filters, setFilters] = useState({
	  searchIn: ['All'],
	  region: ['All'],
	  tag: ['All'],
	  deadline: ['All'],
	});
  
	const options = {
	  searchIn: ['All', 'Name', 'Keyword'],
	  region: ['All', 'Canada', 'United States', 'Alabama', 'Alaska', 'Alberta', 'Arizona', 'Arkansas', 'British Columbia', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Manitoba', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Brunswick', 'New Hampshire', 'New Jersey', 'New Mexico', 'Newfoundland and Labrador', 'New York', 'North Carolina', 'North Dakota', 'Nova Scotia', 'Ohio', 'Oklahoma', 'Ontario', 'Oregon', 'Pennsylvania', 'Prince Edward Island', 'Quebec', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming', 'Saskatchewan'],
	  tag: ['All',`Guaranteed`, `Overdue`, `Accounting`, `Finance`, `Sales`, `Product`, `Engineering`, `Marketing`, `Technology`, `HR`, `CS`, `Operations`, `Customer Success`, `Training`, `Hiring`, `Market Expansion`, `R&D`, `Tax Credits`, `Grant`, `Other`, `Incubators & Accelerators`, `Interest-Free Loan`, `Loan`, `Social Impact`, `Niche`, `Closed`],
	  deadline: ['All', 'Continuous', 'Closed', 'Expired', 'Open'],
	};

	const [lastScraped, setLastScraped] = useState("");
	const [bbfScraperStatus, setBbfScraperStatus] = useState("");
  
	const toggleDropdown = () => {
	  setIsDropdownOpen(!isDropdownOpen);
	};
  
	const handleCheckboxChange = (e) => {
 	  setNumGrants(getGrantsToDisplay(grants).length);
	  const { name, value } = e.target;
	  const updatedFilter = filters[name].includes(value)
		? filters[name].filter((item) => item !== value)
		: [...filters[name], value];
  
	  setFilters({
		...filters,
		[name]: updatedFilter,
	  });
	};
  
	const handleSearchChange = (e) => {
	  setNumGrants(getGrantsToDisplay(grants).length);
	  const { name, value } = e.target;
	  setSearchFilters({
		...searchFilters,
		[name]: value,
	  });
	};
  
	const handleSearch = (e) => {
	  if (e.key === 'Enter') {
		setNumGrants(getGrantsToDisplay(grants).length);
	  }
	};
  
	const filteredOptions = (type) => {
	  return options[type].filter((option) =>
		option.toLowerCase().includes(searchFilters[type].toLowerCase())
	  );
	};
	useEffect(() => {
		base("industries")
			.select({ view: "Grid view" })
			.all()
			.then((data) => {
				let industriesObj = {};
				data.map(
					(rec) => (industriesObj[rec.fields.Industry] = rec.fields.SubIndustries.split(",").map((item) => item.trim().toLowerCase()))
				);
				setIndustries(industriesObj);
			})
			.catch((err) => console.log(err));
	}, []);

	const deleteGrant = (recordId) => {
		fetch("https://api.airtable.com/v0/" + baseId + "/" + grantTableId + "/" + recordId, {
			method: "DELETE",
			headers: {
				Authorization: `Bearer ${apiKey}`,
				"Content-Type": "application/json",
			},
		})
			.then((response) => response.json())
			.then((confirmation) => {
				console.log("Record Deleted Confirmation", confirmation);
			})
			.catch((e) => console.log("Error", e));

		setGrants((prev) => prev.filter((g) => g?.recordId != recordId || g?.id != recordId));
	};

	const mergeGrants = (record1, record2) => {
		if (record1?.fields?.Verified) return record1;
		if (record2?.fields?.Verified) return record2;

		// Prioriizing the fields in record1
		let newRecord = record1;

		for (const field of Object.keys(record1?.fields ? record1.fields : [])) {
			if (newRecord?.fields[field] === undefined || newRecord.fields[field] === null) {
				newRecord.fields[field] = record2.fields[field];
			}
		}

		return newRecord;
	};

	const removeDuplicates = (records) => {
		let duplicateRecordsArray = records
			.map((rec) => records.filter((rec1) => rec1.fields.Name === rec.fields.Name))
			.filter((recordsArray) => recordsArray.length > 1)
			.map((recordsArray) => recordsArray.sort((rec1, rec2) => JSON.stringify(rec2.fields).length - JSON.stringify(rec1.fields).length));

		console.log("duplicateRecordsArray", duplicateRecordsArray);

		let newRecordsArray = duplicateRecordsArray.map((duplicates) =>
			duplicates.reduce((accumulator, currentRecord) => {
				return mergeGrants(accumulator, currentRecord);
			}, duplicates[0])
		);

		// for each array in duplicateRecordsArray, remove all grants in the array other the one at the 0th index.
		// for each grant in the 0th index, update the airtable record with the new fields.

		return newRecordsArray;
	};

	// Fetching Grants
	// const { error: grantsError } = useBase("grants", setGrants);
	// if (grantsError) console.error(grantsError);

	// Fetching Grant Writers
	// const { error: grantWritersError } = useBase("grant_writers_profiles", setGrantWriters);
	// if (grantWritersError) console.error(grantWritersError);

	// Fetching Industries
	// const { error: industriesError } = useBase("industries", (data) => {
	// 	let industriesObj = {};
	// 	data.map((rec) => (industriesObj[rec.fields.Industry] = rec.fields.SubIndustries.split(",").map((item) => item.trim().toLowerCase())));

	// 	setIndustries(industriesObj);
	// });
	// if (industriesError) console.error(industriesError);

	useEffect(() => {
		if (grants.length !== 0) {
			console.log('setting loading to false');
			setGrantLoading(false);
		}
	}, [grants]);

	useEffect(() => {
		setNumGrants(getGrantsToDisplay(grants).length);
	}, [grants, region, searchValue]);

	const loadMore = () => {
		setCurrentMax((prev) => {
			if (prev + 50 >= grants.length) return numGrants;
			return prev + 50;
		});
	};

	const loadLess = () => {
		setCurrentMax((prev) => {
			if (prev - 50 <= 0) return prev;
			return prev - 50;
		});
	};

	const loadAll = () => {
		setCurrentMax(numGrants);
	};

	let eventSource;

	function startEventSource() {
		if (eventSource) {
			eventSource.close();
		}
		
		try {
			eventSource = new EventSource('https://murmuring-plateau-21252-b7994639367f.herokuapp.com/api/bbf-scraper/listener', {
				withCredentials: false
			});
			
			eventSource.onmessage = function(event) {
				try {
					setBbfScraperStatus(JSON.parse(event.data));
				} catch (e) {
					console.error('Error parsing SSE data:', e);
				}
			};

			eventSource.onerror = function(err) {
				console.log("EventSource error, closing connection");
				if (eventSource) {
					eventSource.close();
				}
				// Attempt to reconnect after 5 seconds
				setTimeout(startEventSource, 5000);
			};

			return () => {
				if (eventSource) {
					eventSource.close();
				}
			};
		} catch (error) {
			console.error('Error creating EventSource:', error);
		}
	}

	useEffect(() => {
		const cleanup = startEventSource();
		return () => {
			if (cleanup) cleanup();
			if (eventSource) {
				eventSource.close();
			}
		};
	}, []);

	const getScraperStatus = useCallback(() => {
		const {scraping, scraped, deduping, uploading} = (bbfScraperStatus);
		if (scraping || scraped || deduping || uploading) {
			return "Scraping!";
		} else {
			return "Not Scraping!";
		}
	}, [bbfScraperStatus])

	const scrapeRequest = async () => {
		console.log("Scrape Request");
		try {
			const response = await axios.post(`https://murmuring-plateau-21252-b7994639367f.herokuapp.com/api/bbf-scraper`);
			console.log(response)
			return response;
		} catch {
			return "Error in retrieving status";
		}
	}

	const mergeRequest = async () => {
		console.log("Merge Request");
		try {
			const response = await axios.put(`https://murmuring-plateau-21252-b7994639367f.herokuapp.com/api/bbf-scraper`);
			console.log(response)
			return response;
		} catch {
			return "Error in retrieving status";
		}
	}

	if (showScraperInfo || showMergerInfo){
		(async () => {
			const oldestRecord = await base('deduped_grants')
				.select({
					sort: [
						{field: 'CreatedDate', direction: 'asc'}
					], maxRecords: 1
				})
				.firstPage();
			setLastScraped(oldestRecord[0]?.fields.CreatedDate);
		})()
	}		

	//Grant Scraper implementation

	//  TODO - Create Grants Function + Inputs
	// 0. Translate script from Python to Node (JavaScript) - Heroku node server --> lots of new endpoints to post 
	// 1. Create the input field for --> 1 grant URL 
	// 2. Run dedupe at top of method
	// 3. Connect the input field to firecrawl 
	// 4. Refresh the list of grants on completed run of firecrawl
	// 5. Sort list by most recently created at the top
	// 6. Find Prayaags helper method to create a notification when grant url is submitted 
	// 7. Find Prayaags helper method to create a notification when grant url is completed - surface an error or success message to user

	const [url, setUrl] = useState('');

	const handleKeyDown = async (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			const link = url;
			setUrl('');
			
			// Close EventSource temporarily to free up resources
			if (eventSource) {
				eventSource.close();
			}
			
			try {
				console.log(`Submitting URL: ${link}`);
				const response = await axios({
					method: 'post',
					url: 'https://murmuring-plateau-21252-b7994639367f.herokuapp.com/api/scraper',
					data: { link },
					timeout: 30000,
					headers: {
						'Content-Type': 'application/json'
					}
				});
				
				console.log('Scraper response:', response.data);
				
			} catch(error) {
				console.error("Scraper API Error:", {
					message: error.message,
					status: error.response?.status,
					data: error.response?.data
				});
			} finally {
				// Restart EventSource after request completes
				setTimeout(startEventSource, 1000);
			}
		}
	};
	

	const getGrantsToDisplay = (grantsList) => {
		return grantsList.filter(wordFilter).filter(regionFilter).filter(tagFilter).filter(deadlineFilter).sort(sortGrants);
	};

	const regionFilter = (grant) => {
		if (!grant?.fields?.Eligible_Region) return false;
		if (filters.region.includes("All")) return true;
		else if (filters.region.includes("Canada")) {
			return (
				grant.fields.Eligible_Region.toLowerCase() === "canada" ||
				grant.fields.Eligible_Region.split(",").some((province) => Object.values(regionCodes.CA).includes(province.toLowerCase().trim()))
			);
		} else if (filters.region.includes("United States")) {
			return (
				grant.fields.Eligible_Region.toLowerCase() === "united states" ||
				grant.fields.Eligible_Region.split(",").some((state) => Object.values(regionCodes.US).includes(state.toLowerCase().trim()))
			);
		} else {
			for (const region of filters.region) {
				if (grant.fields.Eligible_Region.toLowerCase().includes(region.toLowerCase())) return true;
				else continue;
			} 
			return false;
		}
	};

	const wordFilter = (grant) => {
		if (filters.searchIn.includes('Name') && !filters.searchIn.includes('Keyword')) return grant?.fields?.Name?.toLowerCase().includes(searchValue.toLowerCase());
		else if (!filters.searchIn.includes('Name') && filters.searchIn.includes('Keyword')) return grant?.fields?.Description_2?.toLowerCase().includes(searchValue.toLowerCase());
		else if ((filters.searchIn.includes('Name') && filters.searchIn.includes('Keyword')) || filters.searchIn.includes('All')) return grant?.fields?.Name?.toLowerCase().includes(searchValue.toLowerCase()) || grant?.fields?.Description_2?.toLowerCase().includes(searchValue.toLowerCase());
	};

	const tagFilter = (grant) => {
		// if (!grant?.fields?.Category) return false;
		if (filters.tag.includes("All")) return true;
		else {
			for (const tag of filters.tag) {
				if (grant.fields.Category?.toLowerCase().includes(tag.toLowerCase())) return true;
				if (grant.fields.GrantOrLoan?.toLowerCase().includes(tag.toLowerCase())) return true;
				else continue;
			} 
			return false;
		}
	}

	const deadlineFilter = (grant) => {
		if (filters.deadline.includes("All")) return true;
		else if (filters.deadline.includes("Continuous") && grant.fields.Deadline === "Continuous") return true;
		else if (filters.deadline.includes("Closed") && grant.fields.Deadline === "Closed") return true;
		else {
			if (filters.deadline.includes("Open")) {
				const today = new Date();
    			today.setHours(0, 0, 0, 0);
				const inputDate = new Date(grant.fields.Deadline);
				if (inputDate >= today) return true;
				else return false;
			}

			if (filters.deadline.includes("Closed")) {
				const today = new Date();
    			today.setHours(0, 0, 0, 0);
				const inputDate = new Date(grant.fields.Deadline);
				if (inputDate < today) return true;
				else return false;
			}
		}
	}


	const sortGrants = (grant1, grant2) => {
		if (sortChoice === "Alphabetically") {
			if (grant1.fields.Name === grant2.fields.Name) return 0;
			else if (grant1.fields.Name < grant2.fields.Name) return -1;
			else return 1;
		} 

		else if (sortChoice === "Sort By Newest") {
			return compareDates(grant2.fields.CreatedDate, grant1.fields.CreatedDate);
		}
		
		else if (sortChoice === "Sort By Oldest") {
			return compareDates(grant1.fields.CreatedDate, grant2.fields.CreatedDate);
		}

		else if (sortChoice === "By Deadline") {
			return compareDates(grant1.fields.Deadline, grant2.fields.Deadline);
		}

		 else if (sortChoice === "By Amount (Ascending)") {
			let difference = grant1?.fields?.Amount - grant2?.fields?.Amount;
			if (isNaN(difference)) return 0;

			return difference;
		} 

		else if (sortChoice === "By Amount (Descending)") {
			let difference = grant2?.fields?.Amount - grant1?.fields?.Amount;
			if (isNaN(difference)) return 0;

			return difference;
		}

	};

	const getGrantProp = (grant) => {
		return {
			...grant,
			fields: {
				...grant.fields,
				AnnualMargin: grant.fields.Amount,
				Function: grant?.fields?.GrantOrLoan ? grant.fields.GrantOrLoan : "No info",
			},
			recordId: grant.id,
		};
	};

	useEffect(() => {


		
		const interval = setInterval(async () => {
			setNumGrants(getGrantsToDisplay(grants).length);
			setRegion(filters.region.map(item => item.toString()).join(", "))
			setScraperStatus(await getScraperStatus())
		}, 500);
		return () => clearInterval(interval);
	}, [filters.region, getGrantsToDisplay, getScraperStatus, grants]);

	return (
		<div className="bg-white px-6 sm:py-10 lg:px-8 overflow-hidden">
			{showConfirmDialog.show &&
			<div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
				<div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
					<h2 className="text-lg font-semibold mb-4">CONFIRMATION: {showConfirmDialog.message.toUpperCase()}</h2>
					<p className="mb-6">Are you sure you want to continue with this {showConfirmDialog.message} action?</p>
					<div className="flex justify-end space-x-4">
					<button
						className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
						onClick={() => setShowConfirmDialog({ show: false, message: "" })}
					>
						Cancel
					</button>
					<button
						className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
						onClick={() => {
							if (showConfirmDialog.message === "rescrape") {
								scrapeRequest();
							} else if (showConfirmDialog.message === "merge") {
								mergeRequest();
							} else {
								console.log("Error in confirmation message");
							}
							setShowConfirmDialog({ show: false, message: "" });
						}}
					>
						Confirm
					</button>
					</div>
				</div>
			</div>
			}

			<div className="">
				<span className="inline-flex">
					<h2 className="inline-flex my-2 pr-4 mr-3 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Grants</h2>
					{/* <div className="inline-flex mt-6 ml-5 col-span-1">
						<Select
							className="w-full max-w-md"
							classNamePrefix="w-full"
							options={regionOptions}
							defaultValue={regionOptions[0]}
							onChange={(data) => {
								setRegion(data.value);
							}}
						/>
					</div> */}
						
					</span>
					<div className="grid grid-cols-3 gap-1">
						<div className="col-span-2">
							<p className="inline-flex mt-6 text-lg leading-8 text-gray-600">
								{region} Grants ({numGrants}) — Showing {Math.min(numGrants, currentMax)}, Sorted {sortChoice}{" "}
							</p>
						</div>

						<div className="col-start-3 inline-flex justify-end text-right mt-5 h-10 w-full max-w-lg space-x-2">
							<div className="relative w-72" style={{ userSelect: 'none' }}>
								<input
									type="text"
									placeholder="Search..."
									className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
									onKeyDown={handleSearch}
									onChange={(event) => setSearchValue(event.target.value)}
								/>
								<div
									className="absolute top-0 right-0 h-full flex items-center px-3 cursor-pointer"
									onClick={toggleDropdown}
								>
								{!isDropdownOpen ? "🔽" : "🔼"} {/* Filter icon */}
							</div>


						{isDropdownOpen && (
						<div className="fixed flex space-x-4 right-0 top-1/3 z-10 w-2/3 h-1/2 bg-white shadow-lg rounded-md p-4">
							<div className="mb-4 flex-1">
							<span className="block font-semibold mb-2 text-left">Search In:</span>
							{/* <span className="block font-semibold mb-2">Search In:</span> */}
							<input
								type="text"
								name="searchIn"
								placeholder="Search options..."
								value={searchFilters.searchIn}
								onChange={handleSearchChange}
								className="w-full mb-2 p-2 border border-gray-300 rounded-md"
							/>
							<div className="max-h-72 overflow-y-auto">
								{filteredOptions('searchIn').map((option) => (
								<label key={option} className="flex items-start text-left mb-1">
									<input
									type="checkbox"
									name="searchIn"
									value={option}
									onChange={handleCheckboxChange}
									className="mr-2 ml-2 mt-1"
									checked={filters.searchIn.includes(option)}
									/>
									{option.charAt(0).toUpperCase() + option.slice(1)}
								</label>
								))}
							</div>
							</div>

							<div className="mb-4 flex-1">
							<span className="block font-semibold mb-2 text-left">Region:</span>
							<input
								type="text"
								name="region"
								placeholder="Search regions..."
								value={searchFilters.region}
								onChange={handleSearchChange}
								className="w-full mb-2 p-2 border border-gray-300 rounded-md"
							/>
							<div className="max-h-72 overflow-y-auto">
								{filteredOptions('region').map((option) => (
								<label key={option} className="flex items-start text-left mb-1">
									<input
									type="checkbox"
									name="region"
									value={option}
									onChange={handleCheckboxChange}
									className="mr-2 ml-2 mt-1"
									checked={filters.region.includes(option)}
									/>
									{option.charAt(0).toUpperCase() + option.slice(1).replace('-', ' ')}
								</label>
								))}
							</div>
							</div>

							<div className="mb-4 flex-1">
							<span className="block font-semibold mb-2 flex-1 text-left">Tag:</span>
							<input
								type="text"
								name="tag"
								placeholder="Search tags..."
								value={searchFilters.tag}
								onChange={handleSearchChange}
								className="w-full mb-2 p-2 border border-gray-300 rounded-md"
							/>
							<div className="max-h-72 overflow-y-auto">
								{filteredOptions('tag').map((option) => (
								<label key={option} className="flex items-start text-left mb-1">
									<input
									type="checkbox"
									name="tag"
									value={option}
									onChange={handleCheckboxChange}
									className="mr-2 ml-2 mt-1"
									checked={filters.tag.includes(option)}
									/>
									{option.charAt(0).toUpperCase() + option.slice(1)}
								</label>
								))}
							</div>
							</div>

							<div className="mb-4 flex-1">
							<span className="block font-semibold mb-2 flex-1 text-left">Deadline:</span>
							<input
								type="text"
								name="deadline"
								placeholder="Search Deadlines..."
								value={searchFilters.deadline}
								onChange={handleSearchChange}
								className="w-full mb-2 p-2 border border-gray-300 rounded-md"
							/>
							<div className="max-h-72 overflow-y-auto">
								{filteredOptions('deadline').map((option) => (
								<label key={option} className="flex items-start text-left mb-1">
									<input
									type="checkbox"
									name="deadline"
									value={option}
									onChange={handleCheckboxChange}
									className="mr-2 ml-2 mt-1"
									checked={filters.deadline.includes(option)}
									/>
									{option.charAt(0).toUpperCase() + option.slice(1)}
								</label>
								))}
							</div>
							</div>
						</div>
						)}
						</div>

						<div className="inline-flex ml-6 pl-2 min-w-44 col-span-1">
							<Select
								className="w-full max-w-lg"
								classNamePrefix="w-full"
								options={sortOptions}
								defaultValue={sortOptions[0]}
								onChange={(data) => {
									setSortChoice(data.value);
								}}
								isSearchable={false}
								readonly={true}
							/>
						</div>
					</div>
				</div>
				<div className="flex ">
					{!grantLoading && currentMax > minimumGrantsToDisplay && (
						<button
							onClick={loadLess}
							className="mt-4 mr-1 relative w-lg text-center max-w-sm inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
						>
							Show Less
						</button>
					)}
					{!grantLoading && currentMax < numGrants && (
						<button
							onClick={loadMore}
							className="mt-4 ml-1 relative w-lg text-center max-w-sm inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
						>
							Show More
						</button>
					)}
					{!grantLoading && currentMax < numGrants && (
						<button
							onClick={loadAll}
							className="mt-4 ml-1 relative w-lg text-center max-w-sm inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
						>
							Show All
						</button>
					)}
					{admin && <input 
						type="url"
						className="ml-auto mt-4 rounded-md w-lg max-w-md px-4 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 text-left"
						placeholder="Add Grant Url"
						value={url}
						onChange={(event) => setUrl(event.target.value)}
						onKeyDown={(event) => handleKeyDown(event)}   // Handle event where user hits enter
					></input>}
					{admin && <button
						className="mt-4 ml-4 justify-center text-center w-[168px] inline-flex items-center rounded-md px-3 py-2 text-font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
						onMouseEnter={() => setShowScraperInfo(true)}
						onMouseLeave={() => setShowScraperInfo(false)}
						onClick={() => setShowConfirmDialog({ show: true, message: "rescrape" })}
					>
						Rescrape Grants
						{showScraperInfo && (
							<div className="absolute transform -translate-x-1/2 -translate-y-full mt-1 w-56 rounded-lg bg-white p-2 text-sm shadow-lg ring-1 ring-black ring-opacity-5">
								{
									"Scraper Status: " + scraperStatus ?? "Unknown"
								}
								<br/>
								{
									"Last Scraped: " + lastScraped.toString()
								}
							</div>
						)}
					</button>}
					{admin && <button
						className="mt-4 ml-4 justify-center text-center w-[168px] inline-flex items-center rounded-md px-3 py-2 text-font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
						onMouseEnter={() => setShowMergerInfo(true)}
						onMouseLeave={() => setShowMergerInfo(false)}
						onClick={() => setShowConfirmDialog({ show: true, message: "merge" })}
					>
						Merge Grants
						{showMergerInfo && (
							<div className="absolute transform -translate-x-1/2 -translate-y-full mt-1 w-56 rounded-lg bg-white p-2 text-sm shadow-lg ring-1 ring-black ring-opacity-5">
								{
									"Scraper Status: " + scraperStatus
								}
								<br/>
								{
									"Last Scraped: " + lastScraped.toString()
								}
							</div>
						)}
					</button>}

				</div>
				<div className="flex mx-auto items-center justify-center max-w-screen w-full mt-3 ">
					<div>
						{grantLoading
							? [1, 2, 3].map((id) => <Skeleton key={id} count={1} height={175} width={700} />)
							: getGrantsToDisplay(grants).map((grant, index) => {
									if (index >= currentMax) return;

									let grantProp = getGrantProp(grant);

									return (
										<div key={grant.id}>
											<Upgrade
												showDeadline={true}
												setGrants={setGrants}
												upgradeProp={grantProp}
												secret_1={true}
												validEmail={"correctEmail"}
												grantWriters={grantWriters}
												isGrant={true}
												industries={industries}
												unblur={true}
												admin={admin}
											/>
										</div>
									);
							  })}
					</div>
				</div>
			</div>
			<div className="items-center text-center mx-auto">
				{!grantLoading && currentMax > minimumGrantsToDisplay && (
					<button
						onClick={loadLess}
						className="mt-4 mr-1 relative w-lg text-center max-w-sm inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
					>
						Show Less 
					</button>
				)}
				{!grantLoading && currentMax < numGrants && (
					<button
						onClick={loadMore}
						className="mt-4 ml-1 relative w-lg text-center max-w-sm inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
					>
						Show More
					</button>
				)}
				{!grantLoading && currentMax < numGrants && (
					<button
						onClick={loadAll}
						className="mt-4 ml-1 relative w-lg text-center max-w-sm inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
					>
						Show All
					</button>
				)}
				
				
				{/* <Notification
					showNotif={submitGrantNotif.showNotif}
					setShowNotif={(val) => setSubmitGrantNotif(val, setSubmitGrantNotif)}
					contents={submitGrantNotif.contents}
									/>
				<Notification
					showNotif={errorGrantNotif.showNotif}
					setShowNotif={(val) => setErrorGrantNotif(val, setErrorGrantNotif)}
					contents={errorGrantNotif.contents}
									/> */}
			</div>
		</div>
	);
}
