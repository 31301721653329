import React, { useState, useRef, useContext } from 'react';
import { getQuestionsToDisplay, handleAutodraftGrant } from "./Autodraft.js";
import { currencyFormat, setContents, setShowNotif, checkEmail, removeQueryParameters } from "./helpers";
import Notification from "./Notification.js";
import { AppContext } from './AppContext.js';

const SendAutoDraftModal = ({
    isOpen,
    closeModal,
    defaultEmail,
    company,
    userEmail,
    upgrade,
    currencyFormat,
    answers,
    questions,
    isGrant,
    setShowReferralNotification
}) => {
    const emailInputRef = useRef(null);
    const { pastAnswersContext } = useContext(AppContext);
    if (!isOpen) return null;

    // TODO - FIX / QA RUN AND SEND AUTODRAFT
    const handleSubmit = async () => {
        const email = emailInputRef.current.value;
        console.log(email)
        try {
            // Close Modal
            closeModal()

            // Set Notification
            // SEE EDIT STUFF NOTIFICATION

            // Send POST request to Zapier
            if (isGrant) {
                fetch('https://hooks.zapier.com/hooks/catch/13965335/2ota6y3/', {
                    method: "POST",
                    body: JSON.stringify({
                        CompanyName: company?.fields?.Name,
                        ClientEmail: email,
                        RequesterEmail: userEmail,
                        PartnerEmail: '',
                        Link: `${window.location.origin}/grants-form/${company.id}/${upgrade.recordId}`,
                        UpgradeName: upgrade?.fields?.Name,
                        Amount: currencyFormat(upgrade?.fields?.AnnualMargin),
                        ClientName: company?.fields?.Name,
                        Date: new Date().toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }),
                        UpgradeType: "Grant",
                        Deadline: upgrade?.fields?.Deadline,
                        Password: "",
                        SlackNotification: true,
                    })
                })

                console.log("This is where we're sending the autodraft!", email)

                const processedQuestions = await getQuestionsToDisplay(answers, `${upgrade?.fields?.Name}-${removeQueryParameters(upgrade?.fields?.GrantUrl)}`, company?.id, questions);

                console.log("upgrade", upgrade)
                console.log("company", company)
                console.log("processedQuestions", processedQuestions)
                console.log("email", email)
                console.log("pastAnswersContext", pastAnswersContext)

                if (processedQuestions) {
                    // AUTODRAFT TEMP OFF
                    handleAutodraftGrant(upgrade, company, processedQuestions, email, pastAnswersContext);

                }

            }

            // AirtablePost to All Grants & Savings Table
            // fetch(
            //     `https://api.airtable.com/v0/${baseId}/ALLGRANTS&SAVINGS(Automated-AccountsReceivable)`,
            //     {
            //         method: "POST",
            //         headers: {
            //             Authorization: `Bearer ${apiKey}`,
            //             "Content-Type": "application/json",
            //         },
            //         body: JSON.stringify({
            //             fields: {
            //                 CompanyName: company?.fields?.Name,
            //                 ClientEmail: email,
            //                 RequesterEmail: userEmail,
            //                 PartnerEmail: "",
            //                 UpgradeName: upgrade?.fields?.Name,
            //                 Amount: currencyFormat(upgrade?.fields?.AnnualMargin),
            //                 ClientName: fullName,
            //                 Date: new Date().toLocaleDateString("en-US", {
            //                     year: "numeric",
            //                     month: "2-digit",
            //                     day: "2-digit",
            //                 }),
            //                 InvoiceReminderDate: new Date(
            //                     Date.now() + 11 * 24 * 60 * 60 * 1000
            //                 ).toLocaleDateString("en-US", {
            //                     year: "numeric",
            //                     month: "2-digit",
            //                     day: "2-digit",
            //                 }),
            //                 UpgradeType: `Tech (${msg})`,
            //                 Deadline: "",
            //                 Password: password,
            //                 MonthlySpending: monthlySpending,
            //                 Status: status,
            //                 Link: !builtfirst ? upgrade?.fields?.href : "",
            //                 AdditionalInfo:
            //                     showAdditional && !builtfirst
            //                         ? `${showAdditional}: ${additionalInfo}`
            //                         : "n/a",
            //                 HumanInstructions: !builtfirst
            //                     ? "https://docs.google.com/document/d/1PsxDaAGUH47zf0UtKwajp-OL7gqObllDo-Fpn4jb7S8/edit?pli=1#heading=h.fd89fbawk5ii"
            //                     : "",
            //             },
            //         }),
            //     }
            // )
            //     .then((response) => response.json())
            //     .then((data) => console.log("Record added:", data))
            //     .catch((error) => console.error("Error:", error));

            setContents(
                {
                    type: "success",
                    heading: "Sent!",
                    message: `Draft was sent to this email. Check your inbox :)`,
                },
                setShowReferralNotification
            );
            setShowNotif(true, setShowReferralNotification);

            var response = {}
            response.status = 200

            if (response.status === 200) {
                console.log("Email sent successfully to Zapier");
            } else {
                console.error("Error sending email to Zapier");
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
            <div className="sm:max-w-lg w-full rounded-2xl bg-white p-8 ring-1 ring-zinc-950/10 shadow-lg dark:bg-zinc-900 dark:ring-white/10">
                <h2 className="text-lg font-semibold text-zinc-950 dark:text-white">
                    Send Your PerfectDraft
                </h2>
                <p className="mt-2 text-sm text-zinc-500 dark:text-zinc-400">
                    We'll send a copy of your draft to the specified email address. You can review and make changes anytime.
                </p>
                
                <div className="mt-6">
                    <label 
                        className="text-sm font-medium text-zinc-950 dark:text-white"
                        htmlFor="email-input"
                    >
                        Email address
                    </label>
                    <textarea
                        id="email-input"
                        defaultValue={defaultEmail}
                        ref={emailInputRef}
                        rows={3}
                        placeholder="Enter email addresses, comma separated"
                        className="mt-2 block w-full rounded-lg border border-zinc-950/10 px-3 py-2 text-zinc-950 shadow-sm placeholder:text-zinc-500 focus:border-zinc-950/20 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:border-white/10 dark:bg-white/5 dark:text-white dark:placeholder:text-zinc-400 resize-y min-h-[80px]"
                    />
                </div>

                <div className="mt-8 flex flex-col-reverse gap-3 sm:flex-row sm:justify-end">
                    <button
                        onClick={closeModal}
                        className="inline-flex justify-center rounded-lg text-sm font-semibold py-2.5 px-4 bg-white text-zinc-900 ring-1 ring-inset ring-zinc-300 hover:bg-zinc-50 dark:bg-white/10 dark:text-white dark:ring-white/20 dark:hover:bg-white/20"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSubmit}
                        className="inline-flex shadow-lg justify-center rounded-lg text-sm font-semibold py-2.5 px-4 bg-zinc-900 text-white hover:bg-zinc-700 dark:bg-zinc-600 dark:hover:bg-zinc-700 transition-colors duration-150"
                    >
                        Send Draft
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SendAutoDraftModal;
