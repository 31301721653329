import React, { useState } from 'react';
import DataUploader from "./DataUploader.js";

const DataImportModal = ({ isOpen, onClose, user, copyClipboardNotif, setCopyClipboardNotif, setNotifications, handleUploadComplete }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-8 rounded-xl shadow-lg w-11/12 md:w-2/3 lg:w-1/2 max-w-2xl">
                <div className="text-center">
                    <h2 className="text-2xl font-bold mb-6">Import Your LinkedIn Connections</h2>
                    <div className="border-2 border-dashed border-gray-900 rounded-lg p-8 mb-6 bg-gray-50">
                        <DataUploader
                            user={user}
                            onUploadComplete={handleUploadComplete}
                            copyClipboardNotif={copyClipboardNotif}
                            setCopyClipboardNotif={setCopyClipboardNotif}
                            setNotifications={setNotifications}
                        />
                    </div>
                    <div className="flex justify-center">
                        <button
                            onClick={onClose}
                            className="bg-gray-900 text-white px-6 py-2 rounded-full hover:bg-gray-700 transition-colors duration-200"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DataImportModal;
