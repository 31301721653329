import html2canvas from "html2canvas";
import { extensions, provinces } from "./helperData.js";

function getQueryVariable(variable) {
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split("=");
		if (pair[0] == variable) {
			return pair[1];
		}
	}
	return false;
}

// getFillerText( textLength : int ) -> string returns
// the text "Lorem Ipsum" repeated enough times to fill up
// (textLength) amount of characters.
//   -- Used to generate filler text behind blur.
// O(n), where n = textLength
const getFillerText = (textLength) => {
	if (typeof textLength != "number") return "";

	const words =
		"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.".split(
			" "
		);
	let filler = "";

	while (filler.length < textLength) {
		for (let i = 0; i < words.length; i++) {
			filler += words[i] + " ";
			if (filler.length >= textLength) {
				return filler;
			}
		}
	}

	return filler;
};

const handleDownloadPDF = () => {
	setTimeout(() => {
		html2canvas(document.body).then(function (canvas) {
			const link = document.createElement("a");
			link.download = "screenshot.png";
			link.href = canvas.toDataURL("image/pdf");
			link.click();
		});
	}, 2500); // Delay of 1 second (1000 milliseconds)
};

function scrollToSection(reference) {
	const section = document.querySelector(reference);
	if (section) {
		section.scrollIntoView({ behavior: "smooth" }); // Smooth scrolling
	}
}

// isAlphanumeric( text : string ) takes in a string and returns a boolean
// indicating whether the string is alphanumeric or not.
const isAlphanumeric = (text) => {
	return /[a-zA-Z0-9]+$/.test(text);
};

const checkEmail = (email) => {
	var emailRegEx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/;
	return emailRegEx.test(email) && checkValidDomainName(email.split("@")[email.split("@").length - 1]).result === "Success";
};

// getWebURL( string : String ) -> [false | String] takes in a string and determines whether if it is
// a valid URL. If it is a valid URL, it will return the appropriate domain name.
// Eg. getWebURL("https://app.asana.com/0/1204594948756080/1205140109800445") --> "asana.com";
// Eg. getWebURL("https://someUselessExtension.app.asana.ca/0/1204594948756080/1205140109800445") --> "asana.ca";
// If the string is not a valid URL, then the function will return false.
// Eg. getWebURL("google.com") --> false
// HOWEVER, getWebURL("https://google.com") --> "google.com"
const getWebURL = (string) => {
	let url = "";
	let domain = "";

	try {
		url = new URL(string);

		if (url.hostname === "") return false;
	} catch (_) {
		return false;
	}

	if (url.protocol === "https:" || url.protocol === "http:") {
		// only allowing for http: and https:
		let URLlist = url.hostname.split(".");

		if (URLlist.length === 1) return false; // this means that there is no extension (no .ca, .com, etc)
		if (!extensions.includes(URLlist[URLlist.length - 1])) return false; // this means that the extension is invalid.

		if (URLlist.length === 2) {
			domain = url.hostname;
		} else if (URLlist.length > 2) {
			domain = URLlist.splice(URLlist.length - 2).join(".");
		}
	}

	if (domain === "") return false;
	return domain;
};

const extractDomain = (email) => {
	if (!email) return null;
	return email.split("@")[1];
};

const checkValidPassword = (password) => {
	return typeof password === "string" && password.length > 3;
};

// returns an object of the following form:
/*      {
            result:    "Success"   ||      "Failure"
            message:   "<some string>"
        }
*/
const checkValidDomainName = (domain) => {
	let output = { result: "Error", message: "" };

	const possibleDomain = getWebURL(domain);
	if (possibleDomain !== false) {
		domain = possibleDomain;
	}

	if (
		typeof domain !== "string" ||
		domain == "" ||
		domain.includes(" ") ||
		!isAlphanumeric(domain) ||
		isNumeric(domain) ||
		domain.length > 63 ||
		!domain.includes(".")
	) {
		output.message = "Invalid Website Name.";
		return output;
	}

	let domainArray = domain.split(".");
	if (domainArray.length > 3) {
		output.message = "Too many extensions. Please enter a valid domain.";
		return output;
	}

	let websiteName = domainArray[0].toLowerCase();
	let ext = domainArray[domainArray.length - 1].toLowerCase();

	if (!extensions.includes(ext)) {
		output.message = "Unrecognized domain extension. Please enter a valid domain.";
		return output;
	}

	if (websiteName.length < 2) {
		output.message = "Too short. Please enter a valid website name.";
		return output;
	}

	if (websiteName[0] == "-" || websiteName[websiteName.length - 1] == "-") {
		output.message = "Please remove trailing hypens.";
		return output;
	}

	output.result = "Success";
	output.message = `Fetching for ${domain}! Please wait`;
	return output;
};

// isNumeric( text : string ) returns whether or not the string text
// is numerical or not.
const isNumeric = (text) => {
	return !isNaN(text);
};

// For formatting dollar amounts
function currencyFormat(num) {
	if (typeof num !== "number") return;
	return "$" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

// For turning dollar amounts as strings into numbers
function currencyToNumber(dollarAmount) {
	if (dollarAmount == undefined || !dollarAmount) return;
	if (typeof dollarAmount === "number") return dollarAmount;
	return parseFloat(dollarAmount?.trim().replaceAll("$", "").replaceAll(",", ""));
}

// isCommonElement( a : Array, b : Array) --> Bool
// returns whether or not there is a common element between both arrays.
// Time Complexity: O(n), where n = min(a.length, b.length)
function containsCommonElement(a, b) {
	let smaller = [];
	let bigger = [];
	if (a.length <= b.length) {
		smaller = a;
		bigger = b;
	} else {
		smaller = b;
		bigger = a;
	}

	for (let i = 0; i < smaller.length; i++) {
		if (bigger.includes(smaller[i])) {
			return true;
		}
	}

	return false;
}

function chunkArrayInGroups(arr, size) {
	let resArr = [];

	for (let i = 0; i < arr.length; i++) {
		resArr.push(arr.slice(0, size + i));
	}

	return resArr;
}

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

function splitAndAddLineBreaks(input) {
	// Use a regular expression to split the string on numbers followed by a dot.
	// The \d+\. matches one or more digits followed by a dot.
	let parts = input.split(/\d+\./);

	// Add a line break after each part, and remove any leading or trailing whitespace.
	for (let i = 1; i < parts.length; i++) {
		parts[i] = i + ". " + parts[i].trim() + "<br />";
	}

	// Join the parts back together into a single string and return it.
	// const parser = new DOMParser();
	// return parser.parseFromString(parts.join(''), 'text/html').body;

	return parts.join("");
}

// converts string in format of "year-mo-da" into milliseconds since epoch
//  if string is not in correct format, function returns null.
function dateToMS(date) {
	if (!checkDateFormat(date)) {
		return null;
	}

	let d = date.split("-").map((entry) => parseInt(entry));
	let ms = new Date(d[0], d[1] - 1, d[2]).getTime();

	return ms;
}

// returning boolean depending on whether date is in format "YEAR-MO-DA"
function checkDateFormat(date) {
	if (typeof date != "string" || !date.includes("-")) {
		return false;
	}

	let dateArray = date.split("-");
	if (dateArray.length != 3 || dateArray[0].length != 4 || dateArray[1].length != 2 || dateArray[2].length != 2) return false;
	dateArray = dateArray.map((entry) => parseInt(entry));
	if (dateArray[1] < 1 || dateArray[1] > 12 || dateArray[2] < 1 || dateArray[2] > 31) return false;

	return true;
}

// compareDates( date1 : string, date2 : string) returns a negative number
//  if date1 precedes date2, a positive number if date1 is after date2, and
//  returns 0 if date1 and date2 are equivalent.
// NOTE: both parameters are strings passed in with the following format
// date = "2023-04-09" That is, "YEAR-MO-DA"
function compareDates(d1, d2) {
	if (!checkDateFormat(d1) && !checkDateFormat(d2)) {
		return 0;
	} else if (!checkDateFormat(d1)) {
		return 1;
	} else if (!checkDateFormat(d2)) {
		return -1;
	}

	d1 = d1.split("-").map((entry) => parseInt(entry));
	d2 = d2.split("-").map((entry) => parseInt(entry));
	// date = [year, month, day]

	const date1 = new Date(d1[0], d1[1], d1[2]);

	const date2 = new Date(d2[0], d2[1], d2[2]);

	return date1.getTime() - date2.getTime();
}

// getDateColor( date : string ) returns Tailwind Color Class for color-coding deadline.
// NOTE: The date string should be in the format "year-mo-da", otherwise getDateColor will
//       return gray by default.

// No dealine              ->   Gray
// Deadline passed         ->   Gray
// Deadline in < 1 month   ->   Red
// Deadline in > 1 Month   ->   Violet
function getDateColor(date) {
	const passed_color = "text-gray-400";
	const in_a_month_color = "text-rose-600";
	const coming_up_color = "text-pastel-600";

	if (!checkDateFormat(date)) {
		return passed_color;
	}

	const ms = dateToMS(date);
	const now_ms = Date.now();
	const ms_in_month = 2629743833;

	if (now_ms <= ms) {
		if (ms - now_ms <= ms_in_month) {
			return in_a_month_color;
		} else {
			return coming_up_color;
		}
	} else {
		return passed_color;
	}
}

// filterByDeadline( dates : string ) takes in a string, where
// the string represents a date in the format "YYYY-MO-DA". The function
// also takes in a positive integer timeLimitInDays representing a value in days.
// The function returns a boolean value representing whether or not the date
// is within the timeLimitInDays days of the current day.
function filterByDeadline(date, timeLimitInDays) {
	if (!checkDateFormat(date)) return false;

	const msInADay = 86400000;

	const ms = dateToMS(date);
	const nowMs = Date.now();
	const timeLimitMS = timeLimitInDays * msInADay;

	return ms - timeLimitMS <= nowMs && ms >= nowMs;
}

// dateToString( date : string ) takes in a string 'date' in the format
// "year-mo-da" and returns a new string representing the same information
// in a more readable format.
// Example: dateToString("2023-06-05") returns "Mon Jun 06 2023"
function dateToString(date) {
	if (!checkDateFormat(date)) {
		return date;
	}
	date = date.split("-").map((entry) => parseInt(entry));
	const d = new Date(date[0], date[1] === 0 ? 0 : date[1] - 1, date[2]);

	const date_string = d.toDateString();
	return date_string;
}

// checkPhoneNumber( phNumber : String )
// returns an object of the following form:
/*      {
            result:    "Success"   ||      "Failure"
            message:   "<some string>"
        }
*/
function checkPhoneNumber(phNumber) {
	let output = { result: "", message: "" };
	if (typeof phNumber !== "string" || (!isNumeric(phNumber) && (phNumber.match(/-/g) || []).length !== 2)) {
		output.result = "Failed";
		output.message = "Please enter a phone number.";
		return output;
	}

	if ((phNumber.match(/-/g) || []).length === 2) {
		phNumber = phNumber.split("-");
		if (phNumber[0].length !== 3 || phNumber[1].length !== 3 || phNumber[2].length !== 4) {
			output.result = "Failed";
			output.message = "Phone numbers need to have 10 digits, please try again.";
			return output;
		}
		phNumber = phNumber.join("");
	}

	if (phNumber.length != 10) {
		output.result = "Failed";
		output.message = "Phone numbers need to have 10 digits, please try again.";
		return output;
	}

	const areaCode = phNumber.substring(0, 3);
	const middle = phNumber.substring(3, 6);
	const end = phNumber.substring(6, 10);

	// const validAreaCodes = ['416', '289', '647', '201', '202', '203', '437', '942', '387'];
	// if (!validAreaCodes.includes(areaCode)) {
	//     output.result = "Failed";
	//     output.message = `${areaCode} is not a valid area code, please try again.`;
	//     return output;
	// }

	output.result = "Success";
	output.message = "Valid phone number";
	return output;
}

// heacountToEmployee( headcount : string, employees : int ) takes in a string representing the
//              headcount of a company and an integer representing the number of employees of a company.
//              The function returns a new string representing an adjusted headcount/
// For ex. adjustHeadcount("10-50 employees", 51) ----> "10-51 employees"
const adjustHeadcount = (headcount, employees) => {
	if (headcount && employees) {
		let headcountInfo = headcount.split(" ")[0].replaceAll(",", "").replaceAll("+", "");
		headcountInfo = headcountInfo.split("-").map((item) => parseInt(item));
		let maxEmployees = headcountInfo.length === 1 ? headcountInfo[0] : headcountInfo.length === 2 ? headcountInfo[1] : 0;

		if (maxEmployees >= employees || maxEmployees === 10001) {
			return headcount;
		}

		return `${headcountInfo[0].toLocaleString("en-US")}-${employees.toLocaleString("en-US")} employees`;
	}
};

function wait(seconds) {
	return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
}

const getRandomInteger = (min = 0, max = 10) => {
	return Math.floor(Math.random() * (max - min) + min);
};

const shortenText = (text, maxLength) => {
	if (!text) return "";

	if (text.length > maxLength) {
		return text.substring(0, maxLength) + "...";
	} else return text;
};

// Example usage: setShowNotif(true, setReqSavingsNotif) --> This will change the showNotif attribute of reqSavingsNotif to true.
// setNotif is a reference to the state function corresponding to the notification.
const setShowNotif = (val, setNotif) => {
	setNotif((prev) => {
		return { ...prev, showNotif: val };
	});
};

// Example usage: setContents({type : "error", heading : "Heading Text", message : "Body Text"}, setReqSavingsNotif)
// ----> This will update the savings request notification to be an error notification with the given header and body text.
const setContents = (newContents, setNotif) => {
	setNotif((prev) => {
		return { ...prev, contents: newContents };
	});
};

const isBadAmount = (value) => {
	if (!value) return true;
	if (isNaN(parseInt(value))) return true;
	if (parseInt(value) === 0) return true;
	return false;
};

const getHiddenSummary = (summary, name, upgradeFunction) => {
	if (!name || !summary) return "";

	let grantOrLoan = upgradeFunction ? capitalizeWords(upgradeFunction) : "Grant";

	let importantWords = name.split(" ");

	// Phrases, Grant Names, other words that we also want redacted / make sure are redacted.
	importantWords = [
		"Business Development Bank of Canada",
		"Canada Small Business Financing Program",
		"PacifiCan",
		"CSBFP",
		"BDC",
		"(BSP)",
		"CIIP",
		"SR&ED",
		"IRAP",
		"National Cancer Institute (NCI)",
		"NIST",
		"National Institute of Standards and Technology",
		"SBIR",
		"Army Research Office",
		"DRRP",
		"STTR",
		...importantWords,
	];
	importantWords = importantWords.filter((word) => word.length > 1);
	importantWords = importantWords.filter((word) => (word.includes("$") ? false : true));

	let allowedWords = [...provinces, "grant", "loan", "the", "small", "canada", "a", "and", "or", "on"];

	importantWords = importantWords.filter((word) => allowedWords.includes(word.toLowerCase().trim()) === false);
	let fillerText = `[${grantOrLoan}]`;

	let hiddenSummary = summary;

	//																		 //
	// Replacing everything in importantWords with fillerText (like [Grant]) //
	//																		 //
	for (let word of importantWords) {
		if (hiddenSummary.includes(word)) {
			hiddenSummary = hiddenSummary.replaceAll(word, fillerText);
		}
	}

	//																				//
	// Making sure {fillerText} does not appear successively in the new summary     //
	//   (so we don't get something like "[Grant] [Grant] [Grant]")					//
	//																				//
	let newSummary = hiddenSummary.split(" ");
	for (let i = 1; i < newSummary.length - 1; ++i) {
		if (newSummary[i] === fillerText && newSummary[i - 1] === fillerText) {
			newSummary[i - 1] = "";
		}
	}
	if (newSummary[newSummary.length - 1] === fillerText && newSummary[newSummary.length - 2] === fillerText) {
		newSummary[newSummary.length - 2] = "";
	}
	hiddenSummary = newSummary.filter((word) => word.length > 0).join(" ");

	return hiddenSummary;
};

function capitalizeWords(sentence) {
	return sentence
		.split(" ")
		.map(function (word) {
			return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
		})
		.join(" ");
}

const highlightErrorOnElement = (element, dashed, timeInSeconds) => {
	element.classList.add("border-2", "border-red-500");
	if (dashed) element.classList.add("border-dashed");

	element.scrollIntoView({ behavior: "smooth", block: "start" });
	setTimeout(() => {
		element.classList.remove("border", "border-red-500");
		if (dashed) element.classList.remove("border-dashed");
	}, 1000 * timeInSeconds)
}

// Returns whether or not the given text is following a numbering system.
// For example, with the default delimiter, "4.3.2 Question1 Text" returns true
//   but "Question 1 Text" returns false.
// Also, "3. Question Text" returns true, but ".3 Question Text" returns false.
const isNumbered = (text, delimiter = '.') => {
	if (!text || typeof text != "string") return false;

	let textArray = text.split(' ');
	let ordering = textArray[0].split(delimiter);
	
	for (let i = 0; i < ordering.length; ++i) {

		if (ordering[i] === '' && i === ordering.length - 1) {
			continue
		};

		if (isNaN(parseInt(ordering[i]))) {
			return false;
		}
	}

	return true;
}

// Returns a negative number if numbering1 preceeds numbering2, returns 0
// if both numberings are equivalent, and returns a positive number if
// numbering2 preceeds numbering1.
// Requires: isNumbered(numbering1) && isNumbered(numbering2) === true;
//			 (returns undefined if requirement not met)
const numberingCompare = (text1, text2, delimiter = '.') => {
	if (isNumbered(text1) && isNumbered(text2) === false) return;

	let numbering1 = text1.split(' ')[0];
	let numbering2 = text2.split(' ')[0];

	let ordering1 = numbering1.split(delimiter);
	if (ordering1[ordering1.length - 1] === '') ordering1.pop();

	let ordering2 = numbering2.split(delimiter);
	if (ordering2[ordering2.length - 1] === '') ordering2.pop();

	for (let i = 0; i < ordering1.length; ++i) {

		if (i >= ordering2.length) return 1;

		if (ordering1[i] != ordering2[i]) {
			return parseInt(ordering1[i]) - parseInt(ordering2[i]);
		}
	}

	if (ordering2.length > ordering1.length) return -1;

	return 0;
}

// isAnswer : String ---> Bool takes in an answer record / object and returns whether
//			the object represents a legitmate answer. 
//			NOTE: answer objects with empty answerTexts are not considered valid answers.
const isAnswer = (ans) => {
	if (!ans) return false;
	else if (!ans?.fields?.AnswerText) return false;
	else if (ans?.fields?.AnswerText === "") return false;
	return true;
}

// getLastEditedTime : String -> String
// Takes in a string representing a date or time, and then returns another string representing
// how long it has been since the inital time.
// TYPICAL USAGE : lastModified is the airtable field
const getLastEditedTime = (lastModified) => {
	let oldTime = new Date(lastModified).getTime();
	let currentTime = Date.now();

	let minutesSince = (currentTime - oldTime) / 1000 / 60;
	let hoursSince = minutesSince / 60;
	let daysSince = hoursSince / 24;

	return daysSince < 1
		? hoursSince < 1
			? `${parseInt(minutesSince)} minutes ago`
			: `${parseInt(hoursSince)} hours ago`
		: `${parseInt(daysSince)} days ago`;
};

// revise : (AnswerObject || null) -> Boolean
// Returns whether an answer object needs revision based on whether the answer object
// is non-null, represents a valid answer text, and has valid answer feedback associated with it.
const revise = (answer) => {
	return isAnswer(answer) && Boolean(answer.fields.Feedback);
}

const includesSpecialCharacters = (specialChars, text) => {
	let result = false;
	text.split('').forEach(char => {
		if (specialChars.includes(char)) result = true;	
	})

	return result;
}

const containsNumber = (text) => {
	let result = false;
	text.split('').forEach(char => {
		if (!isNaN(parseInt(char))) result = true;
	})

	return result;
}

function removeQueryParameters(urlString) {
  try {
    // Check if the URL string is valid
    if (!urlString) {
      return '';
    }

    // Trim the URL string
    let trimmedUrl = urlString.trim();

    // Use the URL object to parse the URL
    let url = new URL(trimmedUrl);

    // Remove the query parameters by setting search to an empty string
    url.search = "";

    // Return the updated URL without query parameters
    return url.toString();
  } catch (error) {
    console.error("Invalid URL:", error);
    return '';
  }
}


// REQUIRES : question is a valid question object
//			  The fields of question can be blank, other than question.fields.QuestionID which should be a valid ID.
const hasSampleAnswer = (question) => {
	if (!Boolean(question)) return false;

	return Boolean(question?.fields?.SampleAnswer);
}

export const percentageFormat = (decimal) => {
	if (!decimal || isNaN(decimal)) return "Not specified";
	return `${(decimal * 100).toFixed(1)}%`;
};

// Formats decimal numbers in formula strings to percentages
export const formatFormulaPercentages = (formula) => {
	if (!formula || typeof formula !== 'string') return formula;
	
	// Regular expression to match decimal numbers between 0 and 1
	// that are likely percentages (e.g., 0.008, 0.3, 0.01)
	return formula.replace(
		/(\d*\.)?\d+/g,
		(match) => {
			const num = parseFloat(match);
			// Only convert numbers between 0 and 1
			if (num > 0 && num < 1) {
				return `${(num * 100).toFixed(1)}%`;
			}
			return match;
		}
	);
};

export {
	getFillerText,
	currencyFormat,
	splitAndAddLineBreaks,
	isAlphanumeric,
	isNumeric,
	checkValidDomainName,
	compareDates,
	dateToMS,
	getDateColor,
	checkDateFormat,
	dateToString,
	checkPhoneNumber,
	scrollToSection,
	checkEmail,
	extractDomain,
	handleDownloadPDF,
	adjustHeadcount,
	containsCommonElement,
	classNames,
	currencyToNumber,
	checkValidPassword,
	filterByDeadline,
	getWebURL,
	wait,
	getRandomInteger,
	shortenText,
	setShowNotif,
	setContents,
	isBadAmount,
	getHiddenSummary,
	capitalizeWords,
	highlightErrorOnElement,
	isNumbered,
	numberingCompare,
	isAnswer,
	getLastEditedTime,
	revise,
	includesSpecialCharacters,
	containsNumber,
	getQueryVariable,
	hasSampleAnswer,
	removeQueryParameters,
};
