import { useEffect } from 'react';

export default function ChatbaseWidget() {
  useEffect(() => {
    // Initialize Chatbase
    const initScript = document.createElement('script');
    initScript.textContent = `
      (function(){
        if(!window.chatbase||window.chatbase("getState")!=="initialized"){
          window.chatbase=(...arguments)=>{
            if(!window.chatbase.q){window.chatbase.q=[]}
            window.chatbase.q.push(arguments)
          };
          window.chatbase=new Proxy(window.chatbase,{
            get(target,prop){
              if(prop==="q"){return target.q}
              return(...args)=>target(prop,...args)
            }
          })
        }
        const onLoad=function(){
          const script=document.createElement("script");
          script.src="https://www.chatbase.co/embed.min.js";
          script.id="GdWwJ6ENF2rqXtX_8PUWv";
          script.domain="www.chatbase.co";
          document.body.appendChild(script)
        };
        if(document.readyState==="complete"){
          onLoad()
        }else{
          window.addEventListener("load",onLoad)
        }
      })();
    `;
    document.body.appendChild(initScript);

    // Cleanup
    return () => {
      const embedScript = document.getElementById('GdWwJ6ENF2rqXtX_8PUWv');
      if (embedScript) {
        embedScript.remove();
      }
      initScript.remove();
    };
  }, []);

  return null;
} 