import React, { useState, useEffect, useMemo, lazy, Suspense } from "react";
import { currencyFormat } from "./helpers.js";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Airtable from "airtable";
import {
  FaRocket,
  FaGamepad,
  FaSun,
  FaChair,
  FaDice,
  FaLaptop,
  FaBuilding,
  FaHeart,
  FaBrain,
  FaRegCompass,
  FaUsers,
  FaInfoCircle,
} from "react-icons/fa";

// Create a mapping of companies to specific icons for consistency
const companyIcons = {
  "Early Bird Enterprises": FaRocket,
  "Rocking Chair Revolution": FaChair,
  "Golden Years Gaming": FaGamepad,
  "Sunset Solutions LLC": FaSun,
  "Leisure Life Labs": FaHeart,
  "Bingo Tech Ventures": FaDice,
  "Silver Surfers Software": FaLaptop,
  "Retirement Rebels": FaUsers,
  "Wisdom Web Works": FaBrain,
  "Senior Social Systems": FaRegCompass,
  "Pension Peak Products": FaBuilding,
};

const MedalIcon = ({ position }) => {
  const medals = {
    1: "🥇",
    2: "🥈",
    3: "🥉",
  };

  return (
    <div className="w-12 text-center">
      {position <= 3 ? (
        <span className="text-3xl">{medals[position]}</span>
      ) : (
        <span className="text-md text-emerald-500 font-bold">{position}</span>
      )}
    </div>
  );
};

// Add a default avatar component for fallback
const DefaultAvatar = ({ name }) => {
  const initials =
    name
      ?.split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase() || "?";
  return (
    <div className="h-6 w-6 rounded-full bg-gray-700 flex items-center justify-center text-white text-xs font-medium">
      {initials}
    </div>
  );
};

const LeaderboardEntry = ({ entry, position }) => {
  const CompanyIcon = companyIcons[entry.name] || FaBuilding;
  // Use the pre-calculated potential revenue
  const futureRevenue = entry.potentialRevenue || 0;
 const activities = entry.activities || 0;

  return (
    <div className="flex items-center w-full py-3.5 px-2.5 hover:bg-gray-800/50 rounded-lg">
      {/* Rank */}
      <div className="w-[85px] flex justify-start">
        <MedalIcon position={position} />
      </div>

      {/* Company Icon and Name */}
      <div className="w-[220px] flex items-center justify-start pl-4">
        <div className="w-8 h-8 flex items-center justify-center mr-3">
          <img
            src={entry.logo}
            alt={entry.name}
            className="w-8 h-8 object-contain rounded-xl"
            onError={(e) => {
              // Fallback to a default image or initial letter
              e.target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(
                entry.name
              )}&background=1a1a1a&color=fff&size=32`;
            }}
          />
        </div>
        <div className="max-w-[130px] overflow-hidden whitespace-nowrap text-ellipsis">
          <span className="text-white text-base link link-hover font-bold truncate block">
            {entry.name}
          </span>
        </div>
      </div>

      {/* Maker */}
      <div className="w-[210px] pl-12 flex items-center">
        <div className="max-w-[140px] overflow-hidden whitespace-nowrap text-ellipsis">
          <span className="text-gray-300 text-base font-bold truncate block">
            {entry.maker.name}
          </span>
        </div>
      </div>

      {/* Activities */}
      <div className="w-[150px] flex items-center justify-center">
        <span className="text-purple-400 text-base font-bold font-mono">
          {activities}
        </span>
      </div>

      {/* Total Sign Ups */}
      <div className="w-[150px] flex items-center justify-center">
        <span className="text-blue-400 text-base font-bold font-mono">
          {entry.totalSignUps}
        </span>
      </div>

      {/* Paid Sign Ups */}
      <div className="w-[150px] flex items-center justify-center pr-1">
        <span className="text-green-400 text-base font-bold font-mono">
          {entry.referralCount}
        </span>
      </div>

      {/* Potential Revenue */}
      <div className="w-[220px] flex items-center justify-center pr-3">
        <div className="max-w-[180px] overflow-hidden">
          <span className="text-blue-400 font-mono text-base font-bold">
            ${futureRevenue.toLocaleString()}
          </span>
        </div>
      </div>

      {/* Revenue */}
      <div className="w-[220px] flex items-center justify-center pr-5">
        <div className="max-w-[180px] overflow-hidden">
          <span className="text-[#ffbe1a] font-mono text-base font-bold">
            ${entry.revenue.toLocaleString()}
          </span>
        </div>
      </div>
    </div>
  );
};

// Tooltip component
const Tooltip = ({ children, text }) => {
  const [isVisible, setIsVisible] = useState(false);
  
  return (
    <div className="relative inline-block">
      <div
        className="inline-flex items-center cursor-help"
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>
      {isVisible && (
        <div className="absolute z-50 w-64 p-2 text-xs text-white bg-gray-900 rounded-md shadow-lg -left-28 bottom-full mb-2">
          {text}
        </div>
      )}
    </div>
  );
};

const TableHeader = () => (
  <div className="flex items-center w-full px-4 pt-8 pb-4 border-b border-[#D2B48C]/20 my-1">
    <div className="w-[80px] text-base text-gray-100/80 font-bold flex items-center">
      Rank
    </div>
    <div className="w-[220px] pl-4 text-base text-gray-100/80 font-bold flex items-center justify-start">
      Startup
    </div>
    <div className="w-[220px] text-base text-gray-100/80 font-bold flex items-center pl-12">
      User
    </div>
    <div className="w-[150px] text-base text-gray-100/80 font-bold flex items-center justify-center">
      Activities
      <Tooltip text="The Amount of PerfectDrafts and Referrals Sent">
        <FaInfoCircle className="ml-1 text-gray-500 text-sm" />
      </Tooltip>
    </div>
    <div className="w-[150px] text-base text-gray-100/80 font-bold flex items-center justify-center">
      Sign-Ups
      <Tooltip text="Total number of users who signed up using your Referral Link">
        <FaInfoCircle className="ml-1 text-gray-500 text-sm" />
      </Tooltip>
    </div>
    <div className="w-[150px] text-base text-gray-100/80 font-bold flex items-center justify-center">
      Paid Sign-Ups
      <Tooltip text="Number of new users who signed up and paid using your Referral Link">
        <FaInfoCircle className="ml-1 text-gray-500 text-sm" />
      </Tooltip>
    </div>
    <div className="w-[220px] text-base text-gray-100/80 font-bold flex items-center justify-center">
      Personal Payout
      <Tooltip text="Your projected earnings based on referrals and activities (Cannot be filtered by month)">
        <FaInfoCircle className="ml-1 text-gray-500 text-sm" />
      </Tooltip>
    </div>
    <div className="w-[220px] text-base text-gray-100/80 font-bold flex items-center justify-center">
      Company Payout
      <Tooltip text="Revenue received by your company from Upgraded (Cannot be filtered by month)">
        <FaInfoCircle className="ml-1 text-gray-500 text-sm" />
      </Tooltip>
    </div>
  </div>
);

// Add GrowSurf API configuration near the top of the file
const GROWSURF_API_KEY = process.env.REACT_APP_GROWSURF_APIKEY;
const GROWSURF_CAMPAIGN_ID = process.env.REACT_APP_GROWSURF_CAMPAIGN_ID;

// Add these utility functions for rate limiting
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const fetchWithRetry = async (url, options, maxRetries = 3, baseDelay = 1000) => {
  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    try {
      const response = await fetch(url, options);
      
      console.log(`Attempt ${attempt} - Status:`, response.status);
      console.log('URL:', url);
      
      if (response.status === 403) {
        console.error('Authentication failed. Please check your API credentials.');
        throw new Error('Authentication failed');
      }
      
      if (response.status === 429) {
        const retryAfter = response.headers.get('retry-after') || Math.pow(2, attempt) * baseDelay;
        console.log(`Rate limited. Waiting ${retryAfter}ms before retry ${attempt}/${maxRetries}`);
        await delay(retryAfter);
        continue;
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error(`Error in attempt ${attempt}:`, error);
      if (attempt === maxRetries) {
        throw error;
      }
      
      const waitTime = Math.pow(2, attempt) * baseDelay;
      console.log(`Attempt ${attempt} failed. Retrying in ${waitTime}ms...`);
      await delay(waitTime);
    }
  }
};

// Find which user's want their email to be hidden
const findHiddenUsers = async () => {
  const apiKey = process.env.REACT_APP_AIR_KEY;
  const baseId = process.env.REACT_APP_AIR_BASEID;
  const base = new Airtable({ apiKey }).base(baseId);

  return new Promise((resolve, reject) => {
    const allEmails = [];

    base("users")
      .select({
        fields: ["Email"], // Fetch only the "Email" column
        filterByFormula: "{IsAnonPublicLeaderboard} = TRUE()", // Filter for true values
      })
      .eachPage((records, fetchNextPage) => {
        allEmails.push(...records.map((record) => record.fields.Email));
        fetchNextPage();
      }, (err) => {
        if (err) {
          reject("Error with finding hidden users", err); // Reject if there's an error
        } else {
          resolve(allEmails); // Resolve with the emails once data is fetched
          console.log("im finding users. the users are", allEmails);
        }
      });
  });
};

// Define the missing processParticipants function
const processParticipants = (participants) => {
  // Create maps for both paid and total referrals
  const paidSignUpsMap = {};
  const totalSignUpsMap = {};
  
  // Create maps for monthly data
  const monthlyPaidSignUpsMap = {};
  const monthlyTotalSignUpsMap = {};
  
  // Get current month and year for filtering
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth(); // 0-11
  const currentYear = currentDate.getFullYear();
  
  // First, create a map of all participants by email for easy lookup
  const participantsByEmail = {};
  
  participants.forEach(participant => {
    if (participant.email) {
      participantsByEmail[participant.email.toLowerCase()] = participant;
    }
  });
  
  // Initialize counts for all participants
  Object.keys(participantsByEmail).forEach(email => {
    paidSignUpsMap[email] = 0;
    totalSignUpsMap[email] = 0;
    monthlyPaidSignUpsMap[email] = 0;
    monthlyTotalSignUpsMap[email] = 0;
  });
  
  // Set paid sign-ups based on referralCount and monthlyReferralCount
  Object.entries(participantsByEmail).forEach(([email, participant]) => {
    paidSignUpsMap[email] = participant.referralCount || 0;
    monthlyPaidSignUpsMap[email] = participant.monthlyReferralCount || 0;
  });
  
  // Process total sign-ups: check each participant object and look for the referrer
  participants.forEach(participant => {
    // Skip if no referrer
    if (!participant.referrer || !participant.referrer.email) return;
    
    const referrerEmail = participant.referrer.email.toLowerCase();
    
    // Make sure the referrer exists in our maps
    if (!totalSignUpsMap[referrerEmail]) {
      totalSignUpsMap[referrerEmail] = 0;
      monthlyTotalSignUpsMap[referrerEmail] = 0;
    }
    
    // Credit the referrer with a referral for all-time
    totalSignUpsMap[referrerEmail]++;
    
    // Check if created this month for monthly metrics
    if (participant.createdAt) {
      const createdDate = new Date(parseInt(participant.createdAt));
      if (createdDate.getMonth() === currentMonth && 
          createdDate.getFullYear() === currentYear) {
        monthlyTotalSignUpsMap[referrerEmail]++;
      }
    }
  });
  
  // Combine all data into a single object for each email
  const combinedData = {};
  
  // Get all unique emails from all maps
  const allEmails = new Set([
    ...Object.keys(paidSignUpsMap),
    ...Object.keys(totalSignUpsMap),
    ...Object.keys(monthlyPaidSignUpsMap),
    ...Object.keys(monthlyTotalSignUpsMap)
  ]);
  
  // Merge all the maps
  allEmails.forEach(email => {
    combinedData[email] = {
      paidSignUps: paidSignUpsMap[email] || 0,
      totalSignUps: totalSignUpsMap[email] || 0,
      monthlyPaidSignUps: monthlyPaidSignUpsMap[email] || 0,
      monthlyTotalSignUps: monthlyTotalSignUpsMap[email] || 0
    };
  });
  
  return combinedData;
};



// Memoize the LeaderboardEntry component
const MemoizedLeaderboardEntry = React.memo(LeaderboardEntry);

export default function PublicLeaderboard() {
  // Get current month name for display
  const getCurrentMonthName = () => {
    const months = [
      "January", "February", "March", "April", "May", "June", 
      "July", "August", "September", "October", "November", "December"
    ];
    const currentDate = new Date();
    return months[currentDate.getMonth()];
  };
  
  const currentMonthName = getCurrentMonthName();
  
  const [activeView, setActiveView] = useState("currentMonth"); // Changed from "december" to "currentMonth"
  const [loading, setLoading] = useState(true);
  const [monthlyData, setMonthlyData] = useState([]);
  const [allTimeData, setAllTimeData] = useState([]);
  const [sortBy, setSortBy] = useState("revenue");
  const [sortOrder, setSortOrder] = useState("desc");
  const [visibleEntries, setVisibleEntries] = useState(10);
  const [hasMore, setHasMore] = useState(true);
  const [participantData, setParticipantData] = useState({});
  const [hiddenUserEmails, setHiddenUserEmails] = useState();

  // Function to extract domain from email
  const extractDomain = (email) => {
    if (!email) return null;
    return email.split('@')[1];
  };

  // Update the fetchGrowSurfData function
  const fetchGrowSurfData = async () => {
    try {
      console.log('GrowSurf API Key exists:', !!GROWSURF_API_KEY);
      console.log('GrowSurf Campaign ID exists:', !!GROWSURF_CAMPAIGN_ID);

      if (!GROWSURF_API_KEY || !GROWSURF_CAMPAIGN_ID) {
        throw new Error('Missing GrowSurf credentials');
      }

      // Array to store all participants
      let allParticipants = [];
      // Variable to track the next page ID
      let nextId = null;
      // Page counter for logging
      let pageCount = 0;
      // Flag to continue pagination
      let hasMorePages = true;

      // Fetch participants with pagination
      while (hasMorePages) {
        pageCount++;
        // Build URL with pagination parameters
        let url = `https://api.growsurf.com/v2/campaign/${GROWSURF_CAMPAIGN_ID}/participants?limit=100`;
        
        // Add the nextId parameter if we have one
        if (nextId) {
          url += `&nextId=${nextId}`;
        }
        
        console.log(`Fetching page ${pageCount} of participants. URL: ${url}`);
        
        const pageData = await fetchWithRetry(
          url,
          {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${GROWSURF_API_KEY.trim()}`,
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          }
        );

        // Check if we got valid data
        if (pageData && pageData.participants && pageData.participants.length > 0) {
          console.log(`Received ${pageData.participants.length} participants on page ${pageCount}`);
          
          // Add participants from this page to our collection
          allParticipants = [...allParticipants, ...pageData.participants];
          
          // If this is the first page, update UI with initial data
          if (pageCount === 1) {
            const initialProcessedData = processParticipants(allParticipants);
            setParticipantData(initialProcessedData);
          }
          
          // Check if there's a nextId in the response
          if (pageData.nextId) {
            nextId = pageData.nextId;
            console.log(`Next page ID: ${nextId}`);
          } else {
            // No nextId means we've reached the last page
            hasMorePages = false;
            console.log('No nextId in response. Reached the last page.');
          }
        } else {
          // No more participants or error
          hasMorePages = false;
          console.log('No participants in response or error occurred');
        }
        
        // Safety check to prevent infinite loops
        if (pageCount > 100) {
          console.warn('Reached maximum page count (100). Breaking pagination loop as a safety measure.');
          hasMorePages = false;
        }
      }

      console.log(`Total participants fetched across all pages: ${allParticipants.length}`);
      
      // Process all the data
      const finalProcessedData = processParticipants(allParticipants);
      
      // Update state with complete data
      setParticipantData(finalProcessedData);
      
      return finalProcessedData;
    } catch (error) {
      console.error('Error fetching GrowSurf data:', error);
      return {};
    }
  };

  // Function to fetch users and generate leaderboard data
  const fetchUsersAndGenerateLeaderboard = async (referralData) => {
    try {
      const apiKey = process.env.REACT_APP_AIR_KEY;
      const baseId = process.env.REACT_APP_AIR_BASEID;
      const usersTableId = process.env.REACT_APP_AIR_USERS;
      const companiesTableId = process.env.REACT_APP_AIR_COMPANIES;
      const referralsTableId = process.env.REACT_APP_AIR_REFERRALS;
      const requesterTableId = process.env.REACT_APP_AIR_REQUESTER_EMAIL;
      const base = new Airtable({ apiKey }).base(baseId);
      
      // Get current month and year for filtering
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth(); // 0-11
      const currentYear = currentDate.getFullYear();
      
      // Fetch all tables concurrently instead of sequentially
      const [companyRecords, userRecords, referralRecords, requesterRecords] = await Promise.all([
        base(companiesTableId).select({}).all(),
        base(usersTableId).select({}).all(),
        base(referralsTableId).select({}).all(),
        base(requesterTableId).select({
          filterByFormula: "{UpgradeType} = 'Grant'"
        }).all()
      ]);
      
      // Create a map of domain to company data for quick lookup
      const companyMap = {};
      companyRecords.forEach(company => {
        if (company.fields.Domain) {
          // Parse the CompanyPayout field, handling different formats
          let companyPayout = 0;
          if (company.fields.CompanyPayout) {
            if (typeof company.fields.CompanyPayout === 'string') {
              companyPayout = parseFloat(company.fields.CompanyPayout.replace(/[$,]/g, '')) || 0;
            } else {
              companyPayout = parseFloat(company.fields.CompanyPayout) || 0;
            }
          }
          
          companyMap[company.fields.Domain.toLowerCase()] = {
            name: company.fields.Name || '',
            logo: company.fields.LogoUrl || '',
            companyPayout: companyPayout
          };
        }
      });
      
      // Define generic email domains
      const genericDomains = ['gmail.com', 'hotmail.com', 'yahoo.com', 'outlook.com'];
      const defaultLogo = 'https://media.istockphoto.com/id/1495088043/vector/user-profile-icon-avatar-or-person-icon-profile-picture-portrait-symbol-default-portrait.jpg?s=612x612&w=0&k=20&c=dhV2p1JwmloBTOaGAtaA3AW1KSnjsdMt7-U_3EZElZ0=';
      
      // Fetch users
      const topUserRecords = await base(usersTableId)
        .select({})
        .all();
      
      // Create maps for hardcoded values from Airtable
      const referralPayoutMap = {};
      const activitiesMap = {};
      const totalSignUpsMap = {};
      const paidSignUpsMap = {};
      // Add maps for monthly hardcoded values
      const monthlyActivitiesMap = {};
      const monthlyTotalSignUpsMap = {};
      const monthlyPaidSignUpsMap = {};
      
      topUserRecords.forEach(user => {
        if (user.fields.Email) {
          const email = user.fields.Email.toLowerCase();
          
          // Handle referralPayout
          if (user.fields.referralPayout) {
            let payout = 0;
            if (typeof user.fields.referralPayout === 'string') {
              payout = parseFloat(user.fields.referralPayout.replace(/[$,]/g, '')) || 0;
            } else {
              payout = parseFloat(user.fields.referralPayout) || 0;
            }
            referralPayoutMap[email] = payout;
          }
          
          // Handle Activities (All-time)
          if (user.fields.Activities !== undefined) {
            let activities = 0;
            if (typeof user.fields.Activities === 'string') {
              activities = parseInt(user.fields.Activities.replace(/,/g, '')) || 0;
            } else {
              activities = parseInt(user.fields.Activities) || 0;
            }
            activitiesMap[email] = activities;
          }
          
          // Handle TotalSignUps (All-time)
          if (user.fields.TotalSignUps !== undefined) {
            let totalSignUps = 0;
            if (typeof user.fields.TotalSignUps === 'string') {
              totalSignUps = parseInt(user.fields.TotalSignUps.replace(/,/g, '')) || 0;
            } else {
              totalSignUps = parseInt(user.fields.TotalSignUps) || 0;
            }
            totalSignUpsMap[email] = totalSignUps;
          }
          
          // Handle PaidSignUps (All-time)
          if (user.fields.PaidSignUps !== undefined) {
            let paidSignUps = 0;
            if (typeof user.fields.PaidSignUps === 'string') {
              paidSignUps = parseInt(user.fields.PaidSignUps.replace(/,/g, '')) || 0;
            } else {
              paidSignUps = parseInt(user.fields.PaidSignUps) || 0;
            }
            paidSignUpsMap[email] = paidSignUps;
          }
          
          // Handle MonthlyActivities
          if (user.fields.MonthlyActivities !== undefined) {
            let monthlyActivities = 0;
            if (typeof user.fields.MonthlyActivities === 'string') {
              monthlyActivities = parseInt(user.fields.MonthlyActivities.replace(/,/g, '')) || 0;
            } else {
              monthlyActivities = parseInt(user.fields.MonthlyActivities) || 0;
            }
            monthlyActivitiesMap[email] = monthlyActivities;
          }
          
          // Handle MonthlyTotalSignUps
          if (user.fields.MonthlyTotalSignUps !== undefined) {
            let monthlyTotalSignUps = 0;
            if (typeof user.fields.MonthlyTotalSignUps === 'string') {
              monthlyTotalSignUps = parseInt(user.fields.MonthlyTotalSignUps.replace(/,/g, '')) || 0;
            } else {
              monthlyTotalSignUps = parseInt(user.fields.MonthlyTotalSignUps) || 0;
            }
            monthlyTotalSignUpsMap[email] = monthlyTotalSignUps;
          }
          
          // Handle MonthlyPaidSignUps
          if (user.fields.MonthlyPaidSignUps !== undefined) {
            let monthlyPaidSignUps = 0;
            if (typeof user.fields.MonthlyPaidSignUps === 'string') {
              monthlyPaidSignUps = parseInt(user.fields.MonthlyPaidSignUps.replace(/,/g, '')) || 0;
            } else {
              monthlyPaidSignUps = parseInt(user.fields.MonthlyPaidSignUps) || 0;
            }
            monthlyPaidSignUpsMap[email] = monthlyPaidSignUps;
          }
        }
      });
      
      // Log the hardcoded values we found
      console.log(`Found ${Object.keys(referralPayoutMap).length} users with hardcoded personal payouts`);
      console.log(`Found ${Object.keys(activitiesMap).length} users with hardcoded activities (all-time)`);
      console.log(`Found ${Object.keys(totalSignUpsMap).length} users with hardcoded total sign-ups (all-time)`);
      console.log(`Found ${Object.keys(paidSignUpsMap).length} users with hardcoded paid sign-ups (all-time)`);
      console.log(`Found ${Object.keys(monthlyActivitiesMap).length} users with hardcoded monthly activities`);
      console.log(`Found ${Object.keys(monthlyTotalSignUpsMap).length} users with hardcoded monthly total sign-ups`);
      console.log(`Found ${Object.keys(monthlyPaidSignUpsMap).length} users with hardcoded monthly paid sign-ups`);
      
      // Let's log some examples of hardcoded values if available
      if (Object.keys(monthlyActivitiesMap).length > 0) {
        console.log('Sample MonthlyActivities from Airtable:', Object.entries(monthlyActivitiesMap).slice(0, 3));
      }
      if (Object.keys(monthlyTotalSignUpsMap).length > 0) {
        console.log('Sample MonthlyTotalSignUps from Airtable:', Object.entries(monthlyTotalSignUpsMap).slice(0, 3));
      }
      if (Object.keys(monthlyPaidSignUpsMap).length > 0) {
        console.log('Sample MonthlyPaidSignUps from Airtable:', Object.entries(monthlyPaidSignUpsMap).slice(0, 3));
      }
      
      
      // Initialize email counts objects for each activity type - ALL TIME
      let emailCountsReferralsAllTime = {};
      let emailCountsRequesterAllTime = {};
      let emailCountsCombinedAllTime = {};
      
      // Initialize email counts objects for each activity type - CURRENT MONTH
      let emailCountsReferralsMonth = {};
      let emailCountsRequesterMonth = {};
      let emailCountsCombinedMonth = {};
      
      // Initialize email to grant amount mapping - ALL TIME
      let emailToGrantAmountsAllTime = {};
      
      // Initialize email to grant amount mapping - CURRENT MONTH
      let emailToGrantAmountsMonth = {};
      
      // Process grants records to calculate total grant amounts by email
      for (let record of requesterRecords) {
        // Only process records where UpgradeType is "Grant"
        if (record.fields["UpgradeType"] === "Grant") {
          let email = record.fields["RequesterEmail"]; // Email field
          let amount = 0;
          
          // Extract amount from the record, handle different formats
          if (record.fields["Amount"]) {
            // Remove currency symbols, commas, etc. and convert to number
            let amountStr = record.fields["Amount"].toString().replace(/[$,]/g, '');
            amount = parseFloat(amountStr) || 0;
          }
          
          // Only count if email exists and amount is valid
          if (email && amount > 0) {
            // Add to all-time totals
            emailToGrantAmountsAllTime[email] = (emailToGrantAmountsAllTime[email] || 0) + amount;
            
            // Check if the record is from the current month
            if (record.fields["LastModified"]) {
              const lastModifiedDate = new Date(record.fields["LastModified"]);
              const lastModifiedMonth = lastModifiedDate.getMonth();
              const lastModifiedYear = lastModifiedDate.getFullYear();
              
              if (lastModifiedMonth === currentMonth && lastModifiedYear === currentYear) {
                // Add to current month totals
                emailToGrantAmountsMonth[email] = (emailToGrantAmountsMonth[email] || 0) + amount;
              }
            }
          }
        }
      }
      
      // Count occurrences in "UploadedBy" field from referrals table - ALL TIME
      let referralCountAllTime = 0;
      // Count occurrences in "UploadedBy" field from referrals table - CURRENT MONTH
      let referralCountMonth = 0;
      
      for (let record of referralRecords) {
        let email = record.fields["UploadedBy"];
        
        // Only count if email exists
        if (email) {
          // Add to all-time counts
          emailCountsReferralsAllTime[email] = (emailCountsReferralsAllTime[email] || 0) + 1;
          emailCountsCombinedAllTime[email] = (emailCountsCombinedAllTime[email] || 0) + 1;
          referralCountAllTime++;
          
          // Check if the record is from the current month
          if (record.fields["ReferralSentDate"]) {
            const sentDate = new Date(record.fields["ReferralSentDate"]);
            const sentMonth = sentDate.getMonth();
            const sentYear = sentDate.getFullYear();
            
            if (sentMonth === currentMonth && sentYear === currentYear) {
              // Add to current month counts
              emailCountsReferralsMonth[email] = (emailCountsReferralsMonth[email] || 0) + 1;
              emailCountsCombinedMonth[email] = (emailCountsCombinedMonth[email] || 0) + 1;
              referralCountMonth++;
            }
          }
        }
      }
      
      // Count occurrences in "RequesterEmail" field from requester table - ALL TIME
      let requesterCountAllTime = 0;
      // Count occurrences in "RequesterEmail" field from requester table - CURRENT MONTH
      let requesterCountMonth = 0;
      
      for (let record of requesterRecords) {
        if (record.fields["UpgradeType"] === "Grant") {
          let email = record.fields["RequesterEmail"];
          
          // Only count if email exists AND UpgradeType is "Grant"
          if (email) {
            // Add to all-time counts
            emailCountsRequesterAllTime[email] = (emailCountsRequesterAllTime[email] || 0) + 1;
            emailCountsCombinedAllTime[email] = (emailCountsCombinedAllTime[email] || 0) + 1;
            requesterCountAllTime++;
            
            // Check if the record is from the current month
            if (record.fields["LastModified"]) {
              const lastModifiedDate = new Date(record.fields["LastModified"]);
              const lastModifiedMonth = lastModifiedDate.getMonth();
              const lastModifiedYear = lastModifiedDate.getFullYear();
              
              if (lastModifiedMonth === currentMonth && lastModifiedYear === currentYear) {
                // Add to current month counts
                emailCountsRequesterMonth[email] = (emailCountsRequesterMonth[email] || 0) + 1;
                emailCountsCombinedMonth[email] = (emailCountsCombinedMonth[email] || 0) + 1;
                requesterCountMonth++;
              }
            }
          }
        }
      }
      
      // Generate leaderboard data for both all-time and monthly
      const allTimeData = [];
      const monthlyData = [];
      
      for (const record of topUserRecords) {
        if (!record.fields.Email) continue;
        
        const email = record.fields.Email.toLowerCase();
        const domain = extractDomain(email);
        const fullName = `${record.fields["First Name"] || ""} ${record.fields["Last Name"] || ""}`.trim();
        
        if (!domain) continue;

        // Get company data from the map using domain
        const companyData = companyMap[domain] || {};
        
        // Set company name and logo based on domain type
        let companyName = companyData.name;
        let companyLogo = companyData.logo;
        
        // Handle generic email domains
        if (genericDomains.includes(domain)) {
          companyName = "Unknown";
          companyLogo = defaultLogo;
          companyData.companyPayout = 0; // Set company payout to zero for generic domains
        } else if (!companyName || !companyLogo) {
          // For non-generic domains without company data
          companyName = companyName || domain.split('.')[0];
          companyLogo = companyLogo || `https://logo.clearbit.com/${domain}`;
          companyData.companyPayout = companyData.companyPayout || 0; // Ensure companyPayout exists
        }
        
        // ALL TIME DATA
        
        // 1. Check for hardcoded Activities or use calculated value
        let userActivitiesAllTime;
        if (email in activitiesMap) {
          userActivitiesAllTime = activitiesMap[email];
          console.log(`Using hardcoded Activities for ${email}: ${userActivitiesAllTime}`);
        } else {
          userActivitiesAllTime = parseInt(emailCountsCombinedAllTime[email] || 0, 10);
        }
        
        // 2. Check for hardcoded TotalSignUps or use calculated value
        let totalSignUpsAllTime;
        if (email in totalSignUpsMap) {
          totalSignUpsAllTime = totalSignUpsMap[email];
          console.log(`Using hardcoded TotalSignUps for ${email}: ${totalSignUpsAllTime}`);
        } else {
          totalSignUpsAllTime = referralData[email]?.totalSignUps || 0;
        }
        
        // 3. Check for hardcoded PaidSignUps or use calculated value
        let referralCountAllTime;
        if (email in paidSignUpsMap) {
          referralCountAllTime = paidSignUpsMap[email];
          console.log(`Using hardcoded PaidSignUps for ${email}: ${referralCountAllTime}`);
        } else {
          referralCountAllTime = referralData[email]?.paidSignUps || 0;
        }
        
        const totalGrantAmountAllTime = emailToGrantAmountsAllTime[email] || 0;
        
        // Get the referral payout from the map for all-time
        const personalPayoutAllTime = referralPayoutMap[email] || 0;
        
        // MONTHLY DATA
        
        // 1. Check for hardcoded MonthlyActivities or use calculated value
        let userActivitiesMonth;
        if (email in monthlyActivitiesMap) {
          userActivitiesMonth = monthlyActivitiesMap[email];
          console.log(`Using hardcoded MonthlyActivities for ${email}: ${userActivitiesMonth}`);
        } else {
          userActivitiesMonth = parseInt(emailCountsCombinedMonth[email] || 0, 10);
        }
        
        // 2. Check for hardcoded MonthlyTotalSignUps or use calculated value
        let totalSignUpsMonth;
        if (email in monthlyTotalSignUpsMap) {
          totalSignUpsMonth = monthlyTotalSignUpsMap[email];
          console.log(`Using hardcoded MonthlyTotalSignUps for ${email}: ${totalSignUpsMonth}`);
        } else {
          totalSignUpsMonth = (referralData[email] && referralData[email].monthlyTotalSignUps) || 0;
        }
        
        // 3. Check for hardcoded MonthlyPaidSignUps or use calculated value
        let referralCountMonth;
        if (email in monthlyPaidSignUpsMap) {
          referralCountMonth = monthlyPaidSignUpsMap[email];
          console.log(`Using hardcoded MonthlyPaidSignUps for ${email}: ${referralCountMonth}`);
        } else {
          referralCountMonth = (referralData[email] && referralData[email].monthlyPaidSignUps) || 0;
        }
        
        const totalGrantAmountMonth = emailToGrantAmountsMonth[email] || 0;
        
        // Set monthly personal payout to zero as requested
        const personalPayoutMonth = 0;
        
        // Check if the email/name should be hidden
        let displayName = fullName || email;
        if (displayName === email && hiddenUserEmails?.includes(email)) {
          const emailParts = email.split('@');
          const localPart = emailParts[0];

          displayName = `${localPart[0]}*****@${emailParts[1]}`;
        } else if (displayName === fullName && hiddenUserEmails?.includes(email)) {
          displayName = `${fullName[0]}*****`;
        }

        // Create base entry object with common properties
        const baseEntry = {
          id: record.id,
          name: companyName.charAt(0).toUpperCase() + companyName.slice(1),
          logo: companyLogo,
          email: email,
          maker: {
            name: displayName,
            avatar: `https://ui-avatars.com/api/?name=${encodeURIComponent(
              email.split('@')[0]
            )}&background=1a1a1a&color=fff&size=64`
          }
        };
        
        // Add to all-time data
        allTimeData.push({
          ...baseEntry,
          revenue: companyData.companyPayout || 0,
          potentialRevenue: personalPayoutAllTime,
          activities: userActivitiesAllTime,
          referralCount: referralCountAllTime,
          totalSignUps: totalSignUpsAllTime
        });
        
        // Add to monthly data
        monthlyData.push({
          ...baseEntry,
          revenue: 0, // Zero for monthly view
          potentialRevenue: personalPayoutMonth,
          activities: userActivitiesMonth,
          referralCount: referralCountMonth,
          totalSignUps: totalSignUpsMonth
        });
      }
      
      // Sort data by revenue (can be changed by user later)
      const sortedAllTimeData = allTimeData.sort((a, b) => b.revenue - a.revenue);
      const sortedMonthlyData = monthlyData.sort((a, b) => b.referralCount - a.referralCount); // Sort by referrals for monthly since revenue is 0
      
      setHasMore(sortedAllTimeData.length > visibleEntries || sortedMonthlyData.length > visibleEntries);
      
      return {
        allTimeData: sortedAllTimeData,
        monthlyData: sortedMonthlyData
      };
    } catch (error) {
      console.error('Error generating leaderboard data:', error);
      return {
        allTimeData: [],
        monthlyData: []
      };
    }
  };

  // Function to load more entries
  const loadMoreEntries = () => {
    setVisibleEntries(prev => prev + 15); // Load 10 more entries
    
    // Update hasMore flag
    const currentData = activeView === "currentMonth" ? monthlyData : allTimeData;
    setHasMore(currentData.length > visibleEntries + 10);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      
      // Immediately show a skeleton loading state
      const loadingPlaceholder = Array(10).fill(null).map((_, i) => ({
        id: `placeholder-${i}`,
        name: "Loading...",
        maker: { name: "Loading..." },
        logo: "",
        revenue: 0,
        potentialRevenue: 0,
        activities: 0,
        referralCount: 0,
        totalSignUps: 0
      }));
      
      setMonthlyData(loadingPlaceholder);
      setAllTimeData(loadingPlaceholder);
      
      try {
        // Check for cached data
        const cachedData = localStorage.getItem('leaderboardData');
        const cachedTimestamp = localStorage.getItem('leaderboardTimestamp');
        const cacheAge = Date.now() - (cachedTimestamp || 0);
        
        // Use cache if it exists and is less than 15 minutes old
        if (cachedData && cacheAge < 900000) {
          const { allTimeData, monthlyData } = JSON.parse(cachedData);
          setMonthlyData(monthlyData);
          setAllTimeData(allTimeData);
          setLoading(false);
          
          // Refresh cache in background if older than 5 minutes
          if (cacheAge > 300000) {
            setTimeout(() => refreshDataInBackground(), 2000);
          }
          return;
        }
        
        // Start loading the minimal data needed for initial display
        fetchMinimalData().then(() => {
          // Then load the full data set in the background
          fetchRemainingData();
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    
    const fetchMinimalData = async () => {
      try {
        // Fetch only top participants (e.g., first 25)
        const partialReferralData = await fetchTopParticipants(25);
        
        // Fetch which users need to be hidden
        const hiddenUserEmails = await findHiddenUsers();
        setHiddenUserEmails(hiddenUserEmails);

        // Generate leaderboard with just these top participants
        const { allTimeData, monthlyData } = await fetchTopLeaderboardData(partialReferralData);
        
        // Update UI with this partial data
        setMonthlyData(monthlyData);
        setAllTimeData(allTimeData);
        setLoading(false);
        
        return partialReferralData;
      } catch (error) {
        console.error("Error fetching minimal data:", error);
        setLoading(false);
      }
    };

    const fetchRemainingData = async () => {
      try {
        // Fetch all remaining data in the background
        const fullReferralData = await fetchGrowSurfData();
        const { allTimeData, monthlyData } = await fetchUsersAndGenerateLeaderboard(fullReferralData);

        // Update cache
        localStorage.setItem('leaderboardData', JSON.stringify({ allTimeData, monthlyData }));
        localStorage.setItem('leaderboardTimestamp', Date.now().toString());
        
        // Update UI with complete data
        setMonthlyData(monthlyData);
        setAllTimeData(allTimeData);
      } catch (error) {
        console.error("Error fetching remaining data:", error);
      }
    };

    // Only fetch top N participants initially
    const fetchTopParticipants = async (limit = 25) => {
      try {
        if (!GROWSURF_API_KEY || !GROWSURF_CAMPAIGN_ID) {
          throw new Error('Missing GrowSurf credentials');
        }
        
        const url = `https://api.growsurf.com/v2/campaign/${GROWSURF_CAMPAIGN_ID}/participants?limit=${limit}`;
        
        const pageData = await fetchWithRetry(
          url,
          {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${GROWSURF_API_KEY.trim()}`,
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          }
        );
        
        if (pageData && pageData.participants) {
          return processParticipants(pageData.participants);
        }
        
        return {};
      } catch (error) {
        console.error('Error fetching top participants:', error);
        return {};
      }
    };

    // Generate leaderboard with only top users
    const fetchTopLeaderboardData = async (referralData) => {
      try {
        const apiKey = process.env.REACT_APP_AIR_KEY;
        const baseId = process.env.REACT_APP_AIR_BASEID;
        const usersTableId = process.env.REACT_APP_AIR_USERS;
        const companiesTableId = process.env.REACT_APP_AIR_COMPANIES;
        
        // Get current month and year for filtering
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth(); // 0-11
        const currentYear = currentDate.getFullYear();
        
        const base = new Airtable({ apiKey }).base(baseId);
        
        // Only fetch top 25 users and companies by using a more targeted query
        const [topCompanyRecords, topUserRecords] = await Promise.all([
          base(companiesTableId).select({ maxRecords: 50 }).all(),
          base(usersTableId).select({ maxRecords: 50 }).all()
        ]);
        
        // Process this limited data similarly to your full function
        // This is a simplified version that processes faster
        
        // Create a map of domain to company data for quick lookup
        const companyMap = {};
        topCompanyRecords.forEach(company => {
          if (company.fields.Domain) {
            companyMap[company.fields.Domain.toLowerCase()] = {
              name: company.fields.Name || '',
              logo: company.fields.LogoUrl || '',
              companyPayout: parseFloat(company.fields.CompanyPayout || 0)
            };
          }
        });
        
        // Generate simplified leaderboard data for both all-time and monthly
        const allTimeData = [];
        const monthlyData = [];
        
        // Generate only entries for users we have data for
        for (const record of topUserRecords) {
          if (!record.fields.Email) continue;
          
          const email = record.fields.Email.toLowerCase();
          // Skip users that don't have referral data
          if (!referralData[email]) continue;
          
          // Process user data and add to leaderboard arrays
          // Simplified version of your full processing logic
          
          // For example:
          const domain = extractDomain(email);
          if (!domain) continue;
          
          const companyData = companyMap[domain] || {};
          
          // Check for anon users
          const fullName = `${record.fields["First Name"] || ""} ${record.fields["Last Name"] || ""}`.trim();
          let displayName = fullName || email;
          if (displayName === email && hiddenUserEmails?.includes(email)) {
            const emailParts = email.split('@');
            const localPart = emailParts[0];
            displayName = `${localPart[0]}*****@${emailParts[1]}`;
          } else if (displayName === fullName && hiddenUserEmails?.includes(email)) {
            displayName = `${fullName[0]}*****`;
          }

          const baseEntry = {
            id: record.id,
            name: (companyData.name || domain.split('.')[0]).charAt(0).toUpperCase() + (companyData.name || domain.split('.')[0]).slice(1),
            logo: companyData.logo || `https://logo.clearbit.com/${domain}`,
            email: email,
            maker: {
              name: displayName,
              avatar: `https://ui-avatars.com/api/?name=${encodeURIComponent(email.split('@')[0])}&background=1a1a1a&color=fff&size=64`
            }
          };
          
          // Add to all-time and monthly data with referral metrics
          allTimeData.push({
            ...baseEntry,
            revenue: companyData.companyPayout || 0,
            potentialRevenue: parseFloat(record.fields.referralPayout || 0),
            activities: parseInt(record.fields.Activities || 0),
            referralCount: referralData[email]?.paidSignUps || 0,
            totalSignUps: referralData[email]?.totalSignUps || 0
          });
          
          monthlyData.push({
            ...baseEntry,
            revenue: 0,
            potentialRevenue: 0,
            activities: parseInt(record.fields.MonthlyActivities || 0),
            referralCount: referralData[email]?.monthlyPaidSignUps || 0,
            totalSignUps: referralData[email]?.monthlyTotalSignUps || 0
          });
        }
        
        // Sort and return the data
        return {
          allTimeData: allTimeData.sort((a, b) => b.revenue - a.revenue),
          monthlyData: monthlyData.sort((a, b) => b.referralCount - a.referralCount)
        };
      } catch (error) {
        console.error('Error generating top leaderboard data:', error);
        return { allTimeData: [], monthlyData: [] };
      }
    };

    // Define the refreshDataInBackground function inside useEffect
    const refreshDataInBackground = async () => {
      try {
        // Fetch all remaining data in the background
        const fullReferralData = await fetchGrowSurfData();
        const { allTimeData, monthlyData } = await fetchUsersAndGenerateLeaderboard(fullReferralData);
        
        // Update cache
        localStorage.setItem('leaderboardData', JSON.stringify({ allTimeData, monthlyData }));
        localStorage.setItem('leaderboardTimestamp', Date.now().toString());
        
        // Update UI with complete data
        setMonthlyData(monthlyData);
        setAllTimeData(allTimeData);
      } catch (error) {
        console.error("Error refreshing data in background:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Performance monitoring
    performance.mark('component-mount-start');
    
    return () => {
      performance.mark('component-mount-end');
      performance.measure(
        'component-mount-duration',
        'component-mount-start',
        'component-mount-end'
      );
      
      // Log results
      const measurements = performance.getEntriesByType('measure');
      console.log('Performance measurements:', measurements);
    };
  }, []);

  // Sort data based on current sortBy and sortOrder
  const getSortedData = (data) => {
    if (!data.length) return [];
    
    return [...data].sort((a, b) => {
      let aValue, bValue;
      
      switch (sortBy) {
        case "revenue":
          aValue = a.revenue;
          bValue = b.revenue;
          break;
        case "potential":
          aValue = a.potentialRevenue 
          bValue = b.potentialRevenue 
          break;
        case "signups":
          aValue = a.referralCount;
          bValue = b.referralCount;
          // Secondary sort by revenue when signups are equal
          if (aValue === bValue) {
            aValue = a.revenue;
            bValue = b.revenue;
          }
          break;
        case "totalSignups":
          aValue = a.totalSignUps;
          bValue = b.totalSignUps;
          // Secondary sort by revenue when total signups are equal
          if (aValue === bValue) {
            aValue = a.revenue;
            bValue = b.revenue;
          }
          break;
        case "activities":
          aValue = a.activities || 0;
          bValue = b.activities || 0;
          // Secondary sort by revenue when activities are equal
          if (aValue === bValue) {
            aValue = a.revenue;
            bValue = b.revenue;
          }
          break;
        default:
          aValue = a.revenue;
          bValue = b.revenue;
      }
      
      return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
    });
  };

  // Sorting widget component
  const SortingWidget = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <div className="fixed left-0 top-1/4 z-10">
        <div
          className={`flex transform transition-all duration-300 ease-in-out ${
            isOpen ? 'translate-x-0' : '-translate-x-[calc(100%-2rem)]'
          }`}
        >
          {/* Sorting options panel */}
          <div className="bg-[#1a1a1a] border border-[#D2B48C]/20 p-4">
            <div className="text-white text-sm font-bold mb-2">Rank by:</div>
            <div className="flex flex-col gap-1 min-w-[160px]">
              <SortButton label="Company Payout" value="revenue" />
              <SortButton label="Personal Payout" value="potential" />
              <SortButton label="Total Sign-Ups" value="totalSignups" />
              <SortButton label="Paid Sign-Ups" value="signups" />
              <SortButton label="Activities" value="activities" />
            </div>
          </div>

          {/* Arrow button */}
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="bg-[#1a1a1a] border border-[#D2B48C]/20 border-l-0 rounded-r-lg p-2 hover:bg-gray-800/50 transition-colors"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`text-white transform transition-transform duration-300 ${
                isOpen ? 'rotate-180' : ''
              }`}
            >
              <path
                d="M9 18L15 12L9 6"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    );
  };

  // Individual sort button 
  const SortButton = ({ label, value }) => (
    <button
      onClick={() => {
        if (sortBy === value) {
          // Toggle sort order if already sorting by this column
          setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
          // Set new sort column and default to descending
          setSortBy(value);
          setSortOrder("desc");
        }
      }}
      className={`flex items-center justify-between px-3 py-1.5 rounded text-xs ${
        sortBy === value
          ? "bg-[#D2B48C]/20 text-white"
          : "text-gray-400 hover:text-white hover:bg-gray-800/50"
      }`}
    >
      <span>{label}</span>
      {sortBy === value && (
        <span className="ml-2">
          {sortOrder === "desc" ? "↓" : "↑"}
        </span>
      )}
    </button>
  );

  // Toggle component for switching between views
  const ViewToggle = () => (
    <div className="flex items-center justify-center bg-[#1a1a1a] rounded-lg p-1 w-fit border border-[#D2B48C]/20">
      <button
        onClick={() => setActiveView("currentMonth")}
        className={`px-6 py-2.5 rounded-md text-base font-medium transition-all duration-200 flex items-center ${
          activeView === "currentMonth"
            ? "bg-[#D2B48C]/20 text-white"
            : "text-gray-400 hover:text-white"
        }`}
      >
        <span>This Month</span>
        {activeView === "currentMonth" && (
          <span className="relative flex ml-2 h-2">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-emerald-500 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-2 w-2 bg-emerald-500"></span>
          </span>
        )}
      </button>
      <button
        onClick={() => setActiveView("alltime")}
        className={`px-6 py-2.5 rounded-md text-base font-medium transition-all duration-200 ${
          activeView === "alltime"
            ? "bg-[#D2B48C]/20 text-white"
            : "text-gray-400 hover:text-white"
        }`}
      >
        All Time
      </button>
    </div>
  );

  const renderLeaderboard = (data) => {
    if (loading && !data.length) {
      return [1, 2, 3, 4, 5].map((id) => (
        <div key={id} className="w-[1260px] mx-auto">
          <Skeleton height={60} />
        </div>
      ));
    }

    const sortedData = getSortedData(data);
    const visibleData = sortedData.slice(0, visibleEntries);

    // Only render what's visible in the viewport initially
    return (
      <>
        <div className="w-[1260px] mx-auto flex justify-between items-center mb-4">
          <h2 className="text-2xl text-gray-200 font-bold">
            {activeView === "currentMonth" ? currentMonthName : "All-time"}
          </h2>
          <ViewToggle />
        </div>
        <div className="w-[1260px] mx-auto space-y-1 bg-[#1a1a1a] rounded-xl border border-[#D2B48C]/20">
          <TableHeader />
          {/* More efficient rendering with controlled updates */}
          {visibleData.map((entry, index) => (
            <MemoizedLeaderboardEntry 
              key={entry.id || index} 
              entry={entry} 
              position={index + 1} 
            />
          ))}
          
          {hasMore && (
            <div className="flex justify-center py-4">
              <button 
                onClick={loadMoreEntries}
                className="px-6 py-2 bg-[#D2B48C]/20 text-white rounded-lg hover:bg-[#D2B48C]/30 transition-colors flex items-center gap-2"
              >
                <span>Load More</span>
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="16" 
                  height="16" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                >
                  <path d="M12 5v14M5 12h14"></path>
                </svg>
              </button>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="bg-[#0F0F0F]">
      <main className="min-h-screen max-lg:pb-16 max-w-7xl mx-auto max-lg:p-6 md:py-12 space-y-4 flex flex-col px-8 relative">
        <React.Suspense fallback={<div>Loading...</div>}>
          <Header />
        </React.Suspense>
        
        {/* Sorting Widget */}
        <React.Suspense fallback={<div>Loading...</div>}>
          <SortingWidget />
        </React.Suspense>
        
        {/* Leaderboard */}
        <div className="w-full">
          {renderLeaderboard(activeView === "currentMonth" ? monthlyData : allTimeData)}
        </div>
      </main>
    </div>
  );
}

// 1. First, add the Header component that's missing
const Header = () => (
  <header className="gap-4 flex max-lg:flex-col justify-between p-2">
    <div className="space-y-4">
      <h1 className="text-3xl md:text-4xl font-extrabold text-white">
        Make +$150,000/yr easy?
      </h1>
      <div className="text-gray-300 leading-relaxed max-w-xl font-semibold">
        Your network is your net worth.
        <br /><br />
        Upgrade it systematically with Upgraded & freedom will follow.
        <br /><br />
        Compete with other upgraders and climb the leaderboard 🏆 🚀 🧗‍♂️ 🥇
      </div>
    </div>

    <div className="flex flex-row gap-4">
      {/* Navigation Icons */}
      <div className="flex">
        <a
          href="/automate-my-work"
          className="mt-4 bg-[#1c1c1c] flex items-center justify-center w-10 h-10 rounded-lg border border-gray-800 transition-all duration-150 ease-in-out hover:bg-gray-800 group"
          title="Automate My Work"
        >
          <span className="text-xl group-hover:scale-110 duration-150">🤖</span>
        </a>
      </div>

      {/* Existing Auth Buttons */}
      <div className="space-y-0.5">
        <div className="text-xs text-gray-500">New here?</div>
        <a
          className="bg-[#1c1c1c] flex items-center group justify-start gap-2 rounded-lg border border-gray-800 w-42 px-3 py-2 transition-all duration-150 ease-in-out hover:bg-gray-800"
          href="/sign-up"
        >
          <div className="flex items-center space-x-2">
            <svg
              className="w-[20px] h-[20px] fill-yellow-400 group-hover:scale-125 group-hover:rotate-3 duration-150 ease-in-out"
              viewBox="0 0 375 509"
            >
              <path d="M249.685 14.125C249.685 11.5046 248.913 8.94218 247.465 6.75675C246.017 4.57133 243.957 2.85951 241.542 1.83453C239.126 0.809546 236.463 0.516683 233.882 0.992419C231.301 1.46815 228.917 2.69147 227.028 4.50999L179.466 50.1812C108.664 118.158 48.8369 196.677 2.11373 282.944C0.964078 284.975 0.367442 287.272 0.38324 289.605C0.399039 291.938 1.02672 294.226 2.20377 296.241C3.38082 298.257 5.06616 299.929 7.09195 301.092C9.11775 302.255 11.4133 302.867 13.75 302.869H129.042V494.875C129.039 497.466 129.791 500.001 131.205 502.173C132.62 504.345 134.637 506.059 137.01 507.106C139.383 508.153 142.01 508.489 144.571 508.072C147.131 507.655 149.516 506.503 151.432 504.757L172.698 485.394C247.19 417.643 310.406 338.487 359.975 250.894L373.136 227.658C374.292 225.626 374.894 223.327 374.882 220.99C374.87 218.653 374.243 216.361 373.065 214.341C371.887 212.322 370.199 210.646 368.17 209.482C366.141 208.318 363.841 207.706 361.5 207.707H249.685V14.125Z" />
            </svg>
            <span className="font-medium text-white text-sm">Get Upgraded</span>
          </div>
        </a>
      </div>

      <div className="space-y-0.5">
        <div className="text-xs text-gray-500">Already member?</div>
        <a
          className="bg-[#1c1c1c] flex items-center group justify-start gap-2 rounded-lg border border-gray-800 w-42 px-3 py-2 transition-all duration-150 ease-in-out hover:bg-gray-800"
          href="/sign-in"
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="text-white w-[20px] h-[20px] group-hover:scale-125 group-hover:-rotate-3 duration-150 ease-in-out"
          >
            <circle cx="12" cy="8" r="5" />
            <path d="M20 21a8 8 0 0 0-16 0" />
          </svg>
          <span className="font-medium text-white text-sm">My Profile</span>
        </a>
      </div>
    </div>
  </header>
);
