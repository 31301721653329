import { useState, useEffect, use } from "react";
import { ExclamationTriangleIcon, ClipboardDocumentListIcon, InformationCircleIcon, PencilIcon } from "@heroicons/react/24/outline";
import '../InviteForm.css';

const zapierUrl = process.env.REACT_APP_ZAPIER_WEBHOOK_URL;
const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;

export default function InviteModal({
  initSubject,
  initMessage,
  onClose,
  onSend,
  onChangePlainText,
  firstName,
  lastName,
  replyTo,
  person,
  userData,
  plainOrHtml = 'plain',
  setPlainOrHtml,
  useAlternateIdentity = false,
  setUseAlternateIdentity
}) {

  const [isPlainText, setIsPlainText] = useState(true);
  const [sendInvite, setSendInvite] = useState(false);

  // Debug information
  const [debugInfo, setDebugInfo] = useState("");

  // Get values from the person object's fields property
  const personEmail = person?.fields?.Email || "";
  const personFirstName = person?.fields?.FirstName || "";
  const personLastName = person?.fields?.LastName || "";
  const personCompany = person?.fields?.Company || "";
  const personCity = person?.fields?.City || "";
  const personLinkedIn = person?.fields?.PersonalLinkedIn || "";
  const personProfilePic = person?.fields?.ProfilePic || "";
  const personStatus = person?.fields?.Status || "";
  const personCreated = person?.fields?.Created || "";
  const personUploadedBy = person?.fields?.UploadedBy || "";
  const personPosition = person?.fields?.Position || "";
  const personDomain = person?.fields?.Website || "";
  const personCountry = person?.fields?.Country || "";
  const personHeadcount = person?.fields?.Headcount || "";
  const personEnriched = person?.fields?.Enriched || "";
  const personLastModified = person?.fields?.["Last Modified"] || "";
  const personUploadedByFirstName = person?.fields?.UploadedByFirstName || "";
  const personUploadedByLastName = person?.fields?.UploadedByLastName || "";
  const personUploadedByFullName = person?.fields?.UploadedByFullName || "";
  const personReferralSentDate = person?.fields?.ReferralSentDate || "";
  const personReferralCode = person?.fields?.ReferralCode || "";

  const placeholders = {
    "FirstName": personFirstName,
    "LastName": personLastName,
    "Company": personCompany,
    "City": personCity,
    "Email": personEmail,
    "PersonalLinkedIn": personLinkedIn,
    "ProfilePic": personProfilePic,
    "Status": personStatus,
    "Created": personCreated,
    "UploadedBy": personUploadedBy,
    "Position": personPosition,
    "Website": personDomain,
    "Country": personCountry,
    "Headcount": personHeadcount,
    "Enriched": personEnriched,
    "Last Modified": personLastModified,
    "UploadedByFirstName": personUploadedByFirstName,
    "UploadedByLastName": personUploadedByLastName,
    "UploadedByFullName": personUploadedByFullName,
    "ReferralSentDate": personReferralSentDate,
    "ReferralLink":  ("https://www.getupgraded.ca/"+ userData?.ReferralParam) || "",
    "ReferralCode": person?.fields?.ReferralCode || "",
    "YourName": (useAlternateIdentity ? "Amy" : userData["First Name"] && userData["Last Name"] ? userData["First Name"] + " " + userData["Last Name"] : userData["First Name"] || userData["Last Name"] || ""),
  };

  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [showPreview, setShowPreview] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  // Add local state if the setter isn't provided
  const [localUseAlternateIdentity, setLocalUseAlternateIdentity] = useState(useAlternateIdentity);
  
  // Use the prop if setter is provided, otherwise use local state
  const effectiveUseAlternateIdentity = setUseAlternateIdentity ? useAlternateIdentity : localUseAlternateIdentity;
  
  // Function to handle identity toggle
  const handleIdentityToggle = () => {
    if (setUseAlternateIdentity) {
      setUseAlternateIdentity(!useAlternateIdentity);
    } else {
      setLocalUseAlternateIdentity(!localUseAlternateIdentity);
    }
  };

  useEffect(() => {
    if (sendInvite) {
      sendEmailInvite();
      setSendInvite(false);
    }
  }, [sendInvite]);

  useEffect(() => {
    if (initSubject && initMessage) {
      setSubject(initSubject);
      setMessage(initMessage);
    }
    if(personEmail) {
      setEmail(personEmail);
    }
  }, [initSubject, initMessage]);

  function insertVars() {
    // Debug: Create a string representation of the person.fields object
    if (person && person.fields) {
      const fieldsKeys = Object.keys(person.fields);
      const debugText = `Person fields keys: ${fieldsKeys.join(', ')}\n\n` +
        `Person fields values:\n` +
        fieldsKeys.map(key => `${key}: ${person.fields[key]}`).join('\n');
      setDebugInfo(debugText);
    } else {
      setDebugInfo("Person object or person.fields is undefined or null");
    }

    // Set email from person's Email field
    setEmail(email);

    // Create personalized versions of subject and message
    let personalizedSubject = subject || "";
    let personalizedMessage = message || "";

    personalizedSubject = replacePlaceholders(personalizedSubject);
    personalizedMessage = replacePlaceholders(personalizedMessage);

    setSubject(personalizedSubject);
    setMessage(personalizedMessage);

    setSendInvite(true);
  }

  function replacePlaceholders(text) {
    if (!text || typeof text !== "string") return text; // Ensure text is valid
    
    Object.entries(placeholders).forEach(([key, value]) => {
      if (value) {
        const regex = new RegExp(`\\{\\{${key}\\}\\}`, "gi"); // Strictly match {{Key}}
        text = text.replace(regex, value);
      }
    });
    
    return text;
  }

  function handleClose() {
    setErrorMessage("");
    onClose();
  }

  const sendEmailInvite = async () => {
    try {
      // Use the CORS proxy to get around CORS restrictions
      const zapierWebhookUrl = "https://mycorsproxy-tuto-35940f676099.herokuapp.com/https://hooks.zapier.com/hooks/catch/21564298/2gi0fez/";

      // Determine sender information based on effectiveUseAlternateIdentity
      const senderFirstName = effectiveUseAlternateIdentity ? "Amy" : firstName;
      const senderLastName = effectiveUseAlternateIdentity ? "Johanneson" : lastName;
      const senderEmail = effectiveUseAlternateIdentity ? "amy@getupgraded.ca" : replyTo;
      const replyEmail = effectiveUseAlternateIdentity ? "" : replyTo;

      // Format message with double line breaks after each sentence
      let formattedMessage = message.trim().replace(/([.!?])\s+/g, '$1\n\n').replace(/\n{3,}/g, '\n\n');
      
      // If sending as HTML, convert newlines to <br> tags
      formattedMessage = formattedMessage.replace(/\n/g, '<br>');

      // Match the payload structure from the Airtable automation script
      const zapierPayload = {
        firstname: senderFirstName,
        lastname: senderLastName,
        fromemail: senderEmail,
        replytoemail: replyEmail,
        bccemail: "",
        email: email.trim(),
        subject: subject.trim(),
        message: formattedMessage,
        plainOrHtml: plainOrHtml
      };

      console.log("Sending email via Zapier with payload:", zapierPayload);

      // Call the Zapier webhook to send the email
      /*const response = await fetch(zapierWebhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(zapierPayload),
      });

      if (!response.ok) {
        throw new Error(`Failed to send email: ${response.statusText}`);
      }*/

      // Update the Referrals record in Airtable with required fields
      if (person && person.id) {
        const currentDate = new Date().toISOString();

        // Get the current user information (assuming it's available in context or props)
        const currentUserEmail = localStorage.getItem('userEmail') || replyTo;

        // Prepare the update payload
        const updatePayload = {
          fields: {
            Email: email.trim(),
            ReferralSentDate: currentDate,
            UploadedBy: localStorage.getItem('userId') || '', // Use stored userId if available
          }
        };

        // Update the Airtable record
        const updateUrl = `https://api.airtable.com/v0/${baseId}/Referrals/${person.id}`;
        const headers = {
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json'
        };

        const updateResponse = await fetch(updateUrl, {
          method: 'PATCH',
          headers: headers,
          body: JSON.stringify(updatePayload)
        });

        if (!updateResponse.ok) {
          console.warn("Failed to update referral record, but email was sent");
        } else {
          console.log("Referral record updated!!!");
        }
      }

      // Call the onSend callback if provided
      if (onSend) {
        onSend(email.trim(), subject.trim(), message.trim());
      }

      // Close the modal
      handleClose();
      setEmail("");
    } catch (error) {
      console.error("Error sending email:", error);
      setErrorMessage(`Failed to send email: ${error.message}`);
    }
  };

  const togglePreview = () => {
    setShowPreview(!showPreview);
  };

  useEffect(() => {
    onChangePlainText(isPlainText);
  }, [isPlainText])

  return (
    <div className="modal-content" style={{
      width: '900px',
      height: '600px',
      maxWidth: '90vw',
      display: 'flex',
      minHeight: '660px'
    }}>
      {/* Available Variables Panel */}
      <div style={{
        backgroundColor: 'white',
        padding: '15px',
        marginRight: '15px',
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        fontSize: '13px',
        whiteSpace: 'pre-wrap',
        overflowY: 'auto',
        width: '300px',
        height: 'auto',
        flexShrink: 0,
        alignSelf: 'stretch',
        boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
      }}>
        <h3 className="font-bold text-lg border-b-1 border-gray-200 pb-2 mb-1">
          Available Variables
        </h3>
        <p style={{ fontSize: '12px', color: '#666', marginBottom: '10px' }}>
          Use these variables in your message by typing the variable name with double curly braces. For example: <code style={{ backgroundColor: '#f8f8f8', padding: '2px 4px', borderRadius: '3px' }}>{'{{FirstName}}'}</code>
        </p>

        {person && person.fields && (
          <div>
            {Object.entries(person.fields).map(([key, value]) => (
              <div key={key} style={{ marginBottom: '8px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span style={{
                    fontWeight: 'bold',
                    color: '#333',
                  }}>
                    {key}:
                  </span>
                  <span style={{
                    color: '#6b7280',
                    fontSize: '11px',
                    fontStyle: 'italic',
                    marginLeft: '8px'
                  }}>
                    <code style={{ backgroundColor: '#f8f8f8', padding: '1px 3px', borderRadius: '3px' }}>{'{{'}{key}{'}}'}</code>
                  </span>
                </div>
                <span style={{
                  color: '#4b5563',
                  wordBreak: 'break-word',
                  display: 'block',
                  paddingLeft: '8px'
                }}>
                  {String(value)}
                </span>
              </div>
            ))}
          </div>
        )}

        {/* User Variables Section */}
        <div style={{ marginTop: '20px', borderTop: '1px solid #e0e0e0', paddingTop: '15px' }}>
          <h4 style={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '10px' }}>Your Information</h4>
          
          {(useAlternateIdentity || userData["First Name"] || userData["Last Name"]) && <div style={{ marginBottom: '8px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span style={{
                      fontWeight: 'bold',
                      color: '#333',
                  }}>
                      Your Name:
                  </span>
                  <span style={{
                      color: '#6b7280',
                      fontSize: '11px',
                      fontStyle: 'italic',
                      marginLeft: '8px'
                  }}>
                      <code style={{ backgroundColor: '#f8f8f8', padding: '1px 3px', borderRadius: '3px' }}>{'{{YourName}}'}</code>
                  </span>
              </div>
              <span style={{
                  color: '#4b5563',
                  wordBreak: 'break-word',
                  display: 'block',
                  paddingLeft: '8px'
              }}>
                  {useAlternateIdentity ? "Amy" : userData["First Name"] && userData["Last Name"] ? userData["First Name"] + " " + userData["Last Name"] : userData["First Name"] || userData["Last Name"] || ""}
              </span>
          </div>}

          <div style={{ marginBottom: '8px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span style={{
                      fontWeight: 'bold',
                      color: '#333',
                  }}>
                      Referral Link:
                  </span>
                  <span style={{
                      color: '#6b7280',
                      fontSize: '11px',
                      fontStyle: 'italic',
                      marginLeft: '8px'
                  }}>
                      <code style={{ backgroundColor: '#f8f8f8', padding: '1px 3px', borderRadius: '3px' }}>{'{{ReferralLink}}'}</code>
                  </span>
              </div>
              <span style={{
                  color: '#4b5563',
                  wordBreak: 'break-word',
                  display: 'block',
                  paddingLeft: '8px'
              }}>
                  {`https://www.getupgraded.ca/${userData?.ReferralParam}` || ""}
              </span>
          </div>
        </div>
      </div>

      {/* Main content on the right */}
      <div style={{ flex: 1 }}>
        <button
          className="close-modal-button"
          onClick={handleClose}
          style={{ position: 'absolute', top: '10px', right: '10px' }}
        >
          &times;
        </button>

        <h2 className="modal-heading font-bold text-xl border-b-1 border-gray-200 pb-1 mb-1">Help Friends, Family & Colleagues Find Free Money</h2>

        <p className="text-sm text-gray-400 mb-2 mt-3"><i>Your invite message will be emailed to them.</i></p>
        <div className="form-group">
          <input
            type="email"
            placeholder="Type or paste your friends' email addresses"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-input"
            style={{
              border: '1px solid #e0e0e0',
              borderRadius: '4px',
              padding: '8px 12px'
            }}
          />
        </div>

        <div className="form-group">
          <label>Email Subject</label>
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className="form-input"
            style={{
              border: '1px solid #e0e0e0',
              borderRadius: '4px',
              padding: '8px 12px'
            }}
          />
        </div>

        <div className="form-group">
          <label>Email Body</label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="form-textarea"
            rows={16}
            style={{
              border: '1px solid #e0e0e0',
              borderRadius: '4px',
              padding: '8px 12px',
              maxHeight: '230px',
              resize: 'none',
            }}
          />
          {/* Character Counter */}
          <div 
            style={{
                marginTop: '4px',
                fontSize: '14px',
                fontWeight: '500',
                color: message.length > 600 ? 'red' : '#6b7280'
            }}
          >
            {message.length} / 600
          </div>
        </div>

        {/* Debug Preview Section */}
        {/* <div 
          style={{ 
            backgroundColor: '#f8f9fa',
            border: '1px solid #e2e8f0',
            borderRadius: '4px',
            padding: '12px',
            marginBottom: '16px',
            fontSize: '12px',
            fontFamily: 'monospace'
          }}
        >
          <div style={{ marginBottom: '8px', fontWeight: 'bold' }}>Debug Preview:</div>
          <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
            {JSON.stringify({
              firstname: effectiveUseAlternateIdentity ? "Amy" : firstName,
              lastname: effectiveUseAlternateIdentity ? "Johanneson" : lastName,
              fromemail: effectiveUseAlternateIdentity ? "amy@getupgraded.ca" : replyTo,
              replytoemail: effectiveUseAlternateIdentity ? "amy@getupgraded.ca" : replyTo,
              bccemail: "",
              email: email.trim(),
              subject: subject.trim(),
              message: message.trim(),
              plainOrHtml: plainOrHtml
            }, null, 2)}
          </pre>
        </div> */}

        <div style={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            marginTop: '15px',
            gap: '10px',
            marginBottom: '10px'
        }}>
          {/* Plain Text Mode Toggle */}
          <div className="flex items-center gap-2">
              <span className="relative group">
                  <InformationCircleIcon className="w-4 h-4 text-gray-500 group-hover:text-gray-700 cursor-pointer" />
                  {/* Tooltip */}
                  <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-1.5 bg-gray-800 text-white text-xs rounded shadow-lg z-10 max-w-[200px] w-max hidden group-hover:block break-words whitespace-normal">
                    Send email as plain text. If toggled off, it will send as HTML.
                    {/* Tooltip arrow */}
                    <div className="absolute top-full left-1/2 transform -translate-x-1/2 border-4 border-transparent border-t-gray-800"></div>
                  </div>
              </span>
              <label className="text-sm font-medium text-gray-900">Plain Text Mode</label>
              <button
                  type="button"
                  className={`${isPlainText ? 'bg-indigo-600' : 'bg-gray-200'} 
                      relative ml-auto inline-flex h-4 w-8 flex-shrink-0 cursor-pointer rounded-full 
                      border-2 border-transparent transition-colors duration-200 ease-in-out 
                      focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                  role="switch"
                  onClick={() => setIsPlainText(prev => (prev ? null : "true"))}
              >
                  <span
                      className={`${isPlainText ? 'translate-x-4' : 'translate-x-0'}
                      pointer-events-none inline-block h-3 w-3 transform rounded-full 
                      bg-white shadow ring-0 transition duration-200 ease-in-out`}
                  />
              </button>
          </div>
        </div>

        {errorMessage && <div className="error-message" style={{ color: 'red', paddingBottom: '10px' }}>{errorMessage}</div>}

        <button onClick={() => {
            if (!email.trim() || !email.includes("@")) {
              setErrorMessage("Please enter a valid email address.");
              return;
            } else if (!subject.trim()) {
              setErrorMessage("Please enter a valid subject.");
              return;
            } else if (!message.trim()) {
              setErrorMessage("Please enter a valid message.");
              return;
            } else if (message.length > 600) {
              setErrorMessage('Your message is too long.');
              return;
            }
            setErrorMessage('');
            insertVars();
          }} 
          className="save-button">
          Send Invite {`(as ${
            effectiveUseAlternateIdentity
              ? "Amy"
              : (replyTo ? (firstName || lastName || (replyTo.split("@")[0] + "@getupgraded.ca")) : "Amy")
          })`}
        </button>
      </div>
    </div>
  );
}