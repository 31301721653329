import { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";

const GROWSURF_BASE_URL = 'https://api.growsurf.com/v2';
const GROWSURF_CAMPAIGN_ID = process.env.REACT_APP_GROWSURF_CAMPAIGN_ID;
const GROWSURF_API_KEY = process.env.REACT_APP_GROWSURF_APIKEY;

async function findStatus(person, userIdData) {
  const response = await fetch(
    `https://mycorsproxy-tuto-35940f676099.herokuapp.com/${GROWSURF_BASE_URL}/campaign/${GROWSURF_CAMPAIGN_ID}/participant/${person.fields.Email}`,
    {
      method: 'GET',

      headers: {
        Authorization: `Bearer ${GROWSURF_API_KEY}`,
        'Content-Type': 'application/json',
      }
    }
  ).catch(error => {
    console.log("ERROR", error);
  });

  if(response !== undefined) {
    try {
      const data = await response.json();

      if (!person?.fields?.Status) {
        return 'Uploading user data...';
      } else if (data?.referralStatus === undefined || !data?.referralStatus) { 
        return 'Not invited'; 
      } else if (data?.referrer?.email && data?.referrer?.email !== userIdData) { 
        return 'Can\'t invite';
      } else if (data?.referralStatus === 'CREDIT_PENDING') {
        return 'Pending referral';
      } else if (data?.referralStatus === 'CREDIT_AWARDED') {
        return 'Referred';
      } else if (data?.referralStatus === 'CREDIT_EXPIRED') {
        return 'Invite expired';
      }
    } catch (error) {
      console.log("JSON ERROR ", error);
    }
  }
  
  return findStatus(person, userIdData);
}

function StatusComponent({ person, userData }) {
  const [status, setStatus] = useState("Loading...");
  const [info, setInfo] = useState("Fetching status...");

  useEffect(() => {
    async function fetchStatus() {
      if (person?.fields?.Email && userData?.Email || person?.fields?.Status !== 'Completed') {
        const result = await findStatus(person, userData.Email);
        setStatus(result);

        if (result === 'Can\'t invite') {
          setInfo(`They are already participating in another user's referral program.`);
        } else if (result === 'Pending referral') {
          setInfo(`They've been added to your referral program. Once both of you sign up for the managed service, they'll be marked as ‘referred' and you'll start earning rewards.`);
        } else if (result === 'Referred') {
          setInfo(`They have been successfully referred! You can now start earning rewards through them.`);
        } else if (result === 'Invite expired') {
          setInfo(`Their invitation has expired. Please send them a new invite to continue.`);
        } else if (result === 'Not invited') {
          setInfo(`Invite them to see their referral status!`);
        } else if (person?.fields?.Status !== 'Completed') {
          setInfo('We\'re working on uploading your friend to our system. Please wait ~2 minutes to start referring them.');
        }
      } else {
        setStatus("Not invited [action needed]");
        setInfo("We couldn't find their contact information. To invite them to your referral program, click their 'Invite' button under 'Refer & Earn'.");
      }
    }
    fetchStatus();
  }, [person]);

  return (
    <div className="text-gray-500 text-sm flex items-center">
      {'Status: '} <span className="ml-1">{status}</span>

      {/* Info Icon with Tooltip */}
      <div className="relative group ml-2 flex items-center">
        <button className="text-gray-400 hover:text-gray-500">
          <InfoIcon fontSize="small" />
        </button>

        {/* Tooltip */}
        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-1.5 bg-gray-800 text-white text-xs rounded shadow-lg z-10 max-w-[300px] w-max hidden group-hover:block break-words whitespace-normal">
          {info || "No info available"}
          {/* Tooltip arrow */}
          <div className="absolute top-full left-1/2 transform -translate-x-1/2 border-4 border-transparent border-t-gray-800"></div>
        </div>
      </div>
    </div>
  );
}

export default StatusComponent;