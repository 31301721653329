import React, { lazy, Suspense, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import {
  SignedIn,
  SignedOut,
  AuthenticateWithRedirectCallback,
  useClerk,
} from '@clerk/clerk-react';

import App from './App.js';
import Layout from './Layout.js';
import SignUpPage from './SignUpPage.jsx';
import SignInPage from './SignInPage.jsx';
import ForgotPasswordPage from './ForgotPasswordPage.jsx';
import UnsubPage from './UnsubPage.jsx';
import InterestedPage from './InterestedPage.jsx';
import NotFoundPage from './NotFoundPage.jsx';
import Reroute from './Reroute.jsx';
import PublicLeaderboard from './PublicLeaderboardPage.jsx';
import { AppProvider } from './AppContext.js';
import Automate from './automate-my-work/automate';
import PublicLeaderboardStaging from './PublicLeaderboardPage_Staging.jsx';

// Ensure all lazy-loaded components are properly wrapped with error handling
const safeImport = (importFn) => {
  return () => importFn().catch(error => {
    console.error("Error loading component:", error);
    return import('./NotFoundPage.jsx'); // Fallback component
  });
};

// Update lazy loading with error handling
const CompaniesPage = lazy(safeImport(() => import('./CompaniesPage.jsx')));
const Leaderboard = lazy(safeImport(() => import('./LeaderboardPage.jsx')));
const ProspectLeaderboard = lazy(safeImport(() => import('./ProspectLeaderboardPage.jsx')));
const NewAppPage = lazy(safeImport(() => import('./NewAppPage.jsx')));
const FeedBackPage = lazy(safeImport(() => import('./FeedbackPage.jsx')));
const AllGrants = lazy(safeImport(() => import('./AllGrants.jsx')));
const GrantAnswersPage = lazy(safeImport(() => import('./GrantAnswersPage.jsx')));
const CompanyInfoPage = lazy(safeImport(() => import('./CompanyInfoPage.jsx')));
const CheckOAuthGoogle = lazy(safeImport(() => import('./CheckOAuthGoogle.jsx')));
const MyNetwork = lazy(safeImport(() => import('./MyNetwork.js')));
const GrantWriterNetwork = lazy(safeImport(() => import('./GrantWriterNetwork.js')));
const Perks = lazy(safeImport(() => import('./Perks.js')));
const Settings = lazy(safeImport(() => import('./Settings.js')));
const PlansPage = lazy(safeImport(() => import('./PlansPage.jsx')));
const FinancingRequests = lazy(safeImport(() => import('./FinancingRequests.jsx')));
const AutomationRequests = lazy(safeImport(() => import('./AutomationRequests.jsx')));
const AutomationSuccess = lazy(safeImport(() => import('./AutomationSuccess.jsx')));

function SSOCallback() {
  const { handleRedirectCallback } = useClerk();

  const navigate = useNavigate();

  useEffect(() => {
    const processCallback = async () => {
      try {
        await handleRedirectCallback({
          redirectUrl: '/check_oauth_google',
        });
      } catch (error) {
        console.error('Error handling SSO callback:', error);
        navigate('/check_oauth_google'); // Redirect to an error page or handle the error appropriately
      }
    };

    processCallback();
  }, []);

  return <div>sso loading...</div>;
}

// Loading fallback component - lightweight CSS spinner instead of heavy component
const LoadingFallback = () => (
  <div className="loading-spinner-container">
    <div className="loading-spinner"></div>
  </div>
);

const Router = () => {
  useEffect(() => {
    //"fetch everything from the router and pass as props to child components"//
  }, []);

  return (
    <BrowserRouter>
      <AppProvider>
        <SignedOut>
          <Routes>
            <Route path="/public-leaderboard" element={<PublicLeaderboard />} />
            <Route path="/public-leaderboard-staging" element={<PublicLeaderboardStaging />} />
            <Route path="/automate-my-work" element={<Automate />} />
            <Route path="/sign-up" element={<SignUpPage />} />
            <Route path="/sign-in" element={<SignInPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/unsubscribe" element={<UnsubPage />} />
            <Route path="/api/partner-interested" element={<InterestedPage />} />
            <Route path="/sso-callback" element={<SSOCallback />} />

            <Route path="/" element={<Layout />}>
              <Route path="?" element={<App />} />
            </Route>

            <Route path="/*" element={<Reroute />} />
          </Routes>
        </SignedOut>
        <SignedIn>
          <Suspense fallback={<LoadingFallback />}>
            <Routes>
              <Route path="/public-leaderboard-staging" element={<PublicLeaderboardStaging />} />
              <Route path="/public-leaderboard" element={<PublicLeaderboard />} />
              <Route path="/automate-my-work" element={<Automate />} />
              <Route path="/report-pending" element={<FeedBackPage />} />
              <Route path="/company-info/*" element={<CompanyInfoPage />} />
              <Route path="/unsubscribe" element={<UnsubPage />} />
              <Route
                path="/api/partner-interested"
                element={<InterestedPage />}
              />
              <Route path="/grants-form/*" element={<GrantAnswersPage />} />
              <Route path="/sign-in" element={<Reroute />} />
              <Route path="/sign-up" element={<Reroute />} />
              <Route path="/forgot-password" element={<Reroute />} />
              <Route path="/check_oauth_google" element={<CheckOAuthGoogle />} />

              <Route path="/" element={<Layout />}>
                <Route path="/edit-grant-questions" element={<NewAppPage />} />
                <Route path="/automation-success" element={<AutomationSuccess />} />
                <Route path="?" element={<App />} />
                <Route path="all-grants" element={<AllGrants />} />
                <Route path="companies" element={<CompaniesPage />} />
                <Route path="leaderboard" element={<Leaderboard />} />
                <Route path="prospect-leaderboard" element={<ProspectLeaderboard />} />
                <Route path="my-network" element={<MyNetwork />} />
                <Route path="successful-grant-writers" element={<GrantWriterNetwork />} />
                <Route path="perks" element={<Perks />} />
                <Route path="pricing" element={<PlansPage />} />
                <Route path="/financing-requests" element={<FinancingRequests />} />
                <Route path="/automation-requests" element={<AutomationRequests />} />
                <Route path="/settings" element={<Settings />} />
              </Route>

              <Route path="/*" element={<NotFoundPage />} />
            </Routes>
          </Suspense>
        </SignedIn>
      </AppProvider>
    </BrowserRouter>
  );
};

export default Router;
