import React, { useState } from 'react';
import Airtable from 'airtable';
import Papa from 'papaparse';
import { setShowNotif, setContents } from "./helpers";
import Notification from "./Notification.js";

// const airtableBase = new Airtable({ apiKey: 'YOUR_AIRTABLE_API_KEY' }).base('YOUR_BASE_ID');

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const airtableBase = new Airtable({ apiKey }).base(baseId);

// const requiredHeaders = ['First Name', 'Last Name', 'URL','Company','Email Address', 'Position'];

const requiredHeaders = ['FirstName', 'LastName', 'PersonalLinkedIn', 'Email', 'Position', 'Company'];

const DataUploader = ({ user, copyClipboardNotif,setCopyClipboardNotif, setNotifications, onUploadComplete }) => {
  const [csvFile, setCsvFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const uploadHeaders = ['First Name', 'Last Name', 'URL', 'Company', 'Email Address', 'Position'];
  const databaseHeaders = ['FirstName', 'LastName', 'PersonalLinkedIn', 'Company', 'Email', 'Position'];

  const handleFileChange = (e) => {
    setCsvFile(e.target.files[0]);
  };

  const validateHeaders = (headers) => {
    return requiredHeaders.every((header) => headers.includes(header));
  };

  const checkDuplicate = async (PersonalLinkedIn) => {
    const records = await airtableBase('Referrals')
      .select({
        filterByFormula: `AND({PersonalLinkedIn} = '${PersonalLinkedIn}', {UploadedBy} = '${user.Email}')`,
      })
      .firstPage();

    return records.length > 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!csvFile) {
      setMessage('Please upload a CSV file.');
      return;
    }

    setLoading(true);

    // complete: async (results) => {
    //   const headers = results.meta.fields;
    //   if (!validateHeaders(headers)) {
    //     setMessage(`CSV file must contain the following headers: ${requiredHeaders.join(', ')}`);
    //     setLoading(false);
    //     return;
    //   }

    Papa.parse(csvFile, {
      header: true,
      complete: async (results) => {
        const data = results.data.map(row => {
          const transformedRow = {};
          uploadHeaders.forEach((header, index) => {
            transformedRow[databaseHeaders[index]] = row[header];
          });
          return transformedRow;
        });

        // const data = results.data;
        // console.log(user)

        const uploadedRecords = [];

        try {
          for (let i = 0; i < data.length; i++) {
            console.log(data[i], "data")
            const isDuplicate = await checkDuplicate(data[i].PersonalLinkedIn);
            const name = data[i].FirstName || data[i].LastName 
              ? `${data[i].FirstName || ""} ${data[i].LastName || ""}`.trim() 
              : data[i].Email || data[i].PersonalLinkedIn;
            if (!isDuplicate) {
              await airtableBase('Referrals').create([
                {
                  fields: {
                    ...data[i],
                    UploadedBy: user.Email,
                    Status: "Completed"
                  },
                },
              ]);

              console.log("Connection Uploaded!")
              uploadedRecords.push(data[i]);
              setShowNotif(true, setCopyClipboardNotif);
              const contents = { type: "success", heading: "Connection Uploaded!", message: `${name} was created` }
              console.log(contents)
              setContents(contents, setCopyClipboardNotif);
            } else {
              setMessage(`Duplicate found! ${name}`);

              console.log("Duplicate connection") 
              setShowNotif(true, setCopyClipboardNotif);
              const contents = { type: "error", heading: "Duplicate found!", message: `${name} already exits` }
              console.log(contents)
              setContents(contents, setCopyClipboardNotif);
            }
          }
          setMessage('Data uploaded successfully!');
          onUploadComplete(uploadedRecords); // Trigger the refresh callback
        } catch (error) {
          setMessage(`Error: ${error.message}`);
        } finally {
          setLoading(false);
          setCsvFile(null);
        }
      },
      error: (error) => {
        setMessage(`Error parsing CSV file: ${error.message}`);
        setLoading(false);
      },
    });
  };

  const testNotification = (val, setNotif) => {
    console.log("Duplicate connection") 
    setShowNotif(true, setCopyClipboardNotif);
    const contents = { type: "success", heading: "Duplicate found!", message: `already exits` }
    console.log(contents)
    setContents(contents, setCopyClipboardNotif);
    addNotification(contents);
  };

  const testNotification2 = (val, setNotif) => {
    console.log("Duplicate connection") 
    setShowNotif(true, setCopyClipboardNotif);
    const contents = { type: "success", heading: "Duplicate found!", message: `already exits or does it?` }
    console.log(contents)
    setContents(contents, setCopyClipboardNotif);
    addNotification(contents);
  };

  const addNotification = (contents) => {
    const newNotification = {
      id: Date.now(),
      showNotif: true,
      contents,
    };
    setNotifications((prev) => [...prev, newNotification]);
  };

  return (
    <div className="w-full">
      <div className="flex flex-col items-center justify-center">
        <div className="mb-8 text-center">
          <div className="mb-4">
            <svg className="w-16 h-16 mx-auto text-gray-900" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 4V20M17 4V20M3 8H21M3 16H21M4 4H20C20.5523 4 21 4.44772 21 5V19C21 19.5523 20.5523 20 20 20H4C3.44772 20 3 19.5523 3 19V5C3 4.44772 3.44772 4 4 4Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
            </svg>
          </div>
          <h3 className="text-lg font-semibold text-gray-700 mb-2">Drag & drop your CSV file</h3>
          <p className="text-sm text-gray-500 mb-2">or browse files on your computer</p>
          <p className="text-xs text-gray-400">
            Default LinkedIn headers: 'First Name', 'Last Name', 'URL', 'Company', 'Email Address', 'Position'
          </p>
        </div>

        <form onSubmit={handleSubmit} className="w-full max-w-md">
          <div className="relative">
            <input
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              className="block w-full text-sm text-gray-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-gray-50 file:text-gray-900
                hover:file:bg-gray-100
                cursor-pointer
                focus:outline-none"
              required
            />
          </div>
          
          <div className="mt-6 text-center">
            <button
              type="submit"
              className={`
                w-full max-w-xs px-6 py-2.5 rounded-full
                transition-all duration-200 ease-in-out
                font-medium text-white
                ${loading 
                  ? 'bg-gray-700 cursor-not-allowed' 
                  : 'bg-gray-900 hover:bg-gray-800 hover:shadow-lg'
                }
              `}
              disabled={loading}
            >
              {loading ? (
                <span className="flex items-center justify-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Uploading...
                </span>
              ) : (
                'Upload CSV'
              )}
            </button>
          </div>
        </form>

        {message && (
          <div className={`mt-4 px-4 py-2 rounded-lg text-sm ${
            message.includes('Error') || message.includes('Duplicate')
              ? 'bg-red-50 text-red-600'
              : 'bg-green-50 text-green-600'
          }`}>
            {message}
          </div>
        )}
      </div>
    </div>
  );
};

export default DataUploader;